import { Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { subscribeToSdkAuthenticationFailures } from '@braze/web-sdk';
import { FailedMessagingServerTestError } from 'opentok-network-test-js/dist/NetworkTest/testConnectivity/errors';
import { InputComponent } from '../forms/input/input.component';
import { NgIconsModule } from '@ng-icons/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrl: './search-bar.component.scss',
  standalone: true,
  imports: [InputComponent, NgIconsModule, FormsModule]
})
export class SearchBarComponent {
@Input() set search(value: string) {
  if(value !== this.searchTerm()) this.searchTerm.set(value);
}  
@Input() placeholder: string = '';
@Input() filterButton: boolean = false;
searchTerm = signal('');

@Output() searchChange = new EventEmitter<string>();
@Output() filterToggle = new EventEmitter<void>();

onBtnClick(){
  this.searchChange.emit(this.searchTerm());
}

onFilterBtnClick() {
  this.filterToggle.emit();
}
}
