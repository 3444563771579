import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { StaffProfileDataService } from 'src/pms/services/staffprofiles.data.service/staffprofiles.data.service';

@Component({
  selector: 'app-staffonboarding',
  templateUrl: './staffonboarding.component.html'
})

export class StaffOnboardingComponent {
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private staffProfileDataService: StaffProfileDataService) { }

  form = this.fb.group({
    name: new FormControl('', [Validators.required]),
    description: new FormControl('', [Validators.required]),
  });

  loading!: boolean;
  error!: string;

  onSubmit(form: FormGroup) {
    if (!form.valid) return;
    if (this.loading) return;
    this.error = "";
    this.loading = true;
    this.staffProfileDataService.CreateMyStaffProfile(form.value)
    .subscribe({
      next: () => this.next(),
      error: error => this.handlerError(error.message),
      complete: () => this.resetForm()
    });
  }


  next() {
    this.router.navigate(['/dashboard']);
  }

  handlerError(error: string) {
    this.error = error;
    this.loading = false;
  }

  clearError() {
    this.error = "";
  }

  resetForm() {
    this.loading = false;
  }

}
