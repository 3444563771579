import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginGuard } from 'src/shared/guards/login.guard';

const routes: Routes = [
  {
    path: 'account',
    loadComponent: () => import('./pages/account/account.component').then(m => m.AccountComponent),
    canActivate: [LoginGuard],
    title: 'Joii PMS - Account',
    children: [
      {
      path:'',
      redirectTo: 'staffprofile',
      pathMatch: 'full'
      },
      {
        path: 'logout',
        loadComponent: () => import('./pages/logoutpage/logoutpage.component').then(m => m.UserLogoutComponent),
        title: 'Joii PMS - Logout'
      },
      {
        path: 'staffprofile',
        loadComponent: () => import('./pages/staffprofileupdate/staffprofileupdate.component').then(m => m.StaffProfileUpdateComponent),
        title: 'Joii PMS - Staff Profile'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule { }
