import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StackedListConfig, StackedListItem, StackedListItemType } from './stackedlist.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { LucideAngularModule } from 'lucide-angular';

@Component({
  selector: 'app-stackedlist',
  templateUrl: './stackedlist.component.html',
  standalone: true, 
  imports: [NgFor, NgIf, NgClass, NgStyle, LucideAngularModule]
})

export class StackedListComponent {

  actionClasses = 'text-xs text-zinc-800 hover:text-zinc-400'

  constructor(private router: Router, private activatedRoute: ActivatedRoute) { }

  @Input() config: StackedListConfig = {
    items: [],
  }

  @Output() rowclick = new EventEmitter<StackedListItem>();

  rowclicked(menuItem: StackedListItem, event: any) {
    if (event) {
      event.stopPropagation();
    }
    if (menuItem.type === StackedListItemType.Callback) {
      this.rowclick.emit(menuItem);
    } else if (menuItem.type === StackedListItemType.Path) {
      const currentQueryParams = this.activatedRoute.snapshot.queryParams;
      this.router.navigate([menuItem.id], {state: {previousQueryParams: currentQueryParams}});
    }
  }
}
