import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-videochecks',
  templateUrl: './videochecks.component.html'
})

export class VideoChecksComponent {
  constructor(
    private router: Router,) { }

 
  dashboard() {
    this.router.navigate(['/dashboard']);
  }

}
