<app-title title="Appointments" description="View and manage your appointments below" linktext="Book"
    (linkclick)="book()"></app-title>
<ng-container *ngIf="appointments$ | async as appointments; else loading">
    <app-error *ngIf="appointments | isClientErrorMessage; else loaded" [error]="appointments"
        (retry)="load()"></app-error>
    <ng-template #loaded>
        <div class="mt-2">
            <app-horizontalpills [config]="getPillsConfig()" (itemclick)="pillClicked($event)"></app-horizontalpills>
        </div>

        <app-stackedlist [config]="(config$ | async) || {items: []}"></app-stackedlist>

    </ng-template>
</ng-container>
<ng-template #loading>
    <app-loader></app-loader>
</ng-template>