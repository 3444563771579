import { ChangeDetectionStrategy, Component, inject, input, Input, OnDestroy, OnInit } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { filter, Subscription, switchAll, switchMap } from 'rxjs';
import { GooglePlacesService } from 'src/shared/services/google-places.service/google-places.service';
import { PartnerPracticeService } from 'src/shared/services/partner-practices.service/partner-practice.service';

@Component({
  selector: "app-prescribing-eligibility-pill",
  standalone: true,
  imports: [],
  templateUrl: './prescribing-eligibility-pill.component.html',
  styleUrl: './prescribing-eligibility-pill.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrescribingEligibilityPillComponent implements OnInit, OnDestroy {
  ngOnDestroy(): void {
    this.partnerPracticeSubscription?.unsubscribe();
  }
  ngOnInit(): void {}

  address = input<string>("")

  @Input()
  tinyMode: boolean = false;

  googlePlacesService = inject(GooglePlacesService)
  partnerPracticeService = inject(PartnerPracticeService)
  partnerPractice = toSignal(toObservable<string>(this.address).pipe(
    filter(address => address != ""),
    switchMap(address => {
      return this.googlePlacesService.getPartnerPracticeWithin25Miles(address)
    }),
  ));
  partnerPracticeSubscription?: Subscription
}
