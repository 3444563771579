<div class="relative w-full aspect-1 border-white bg-black p-4 rounded-lg mt-2 mb-2">
  <div class="absolute bg-sky-600 rounded-lg overflow-hidden"
    [ngClass]="publisherMainView ? 'w-full h-full top-0 left-0 z-10' : publisherPlayerSize + ' top-4 right-4 z-20'">
    <div #publisherMe></div>
  </div>
  <div class="relative rounded-lg bg-slate-200 overflow-hidden"
    [ngClass]="publisherMainView ? publisherPlayerSize + ' top-4 right-4 z-20' : 'w-full h-full top-0 left-0 z-10'">
    <div #subscriberThem></div>
    <div *ngIf="!clientJoined" class="w-full h-full text-center content-center">
      <p class="text-gray-500">Waiting for <span *ngIf="isClinician">owner</span><span
          *ngIf="!isClinician">clinician</span> to join...</p>
    </div>
  </div>
  <div class="publisher-video-menu absolute z-20 bottom-6 w-full text-center">
    <button title="Launch PIP" class="w-10 h-10 rounded-full bg-stone-500 mr-4" (click)="launchPictureInPicture()">
      <lucide-icon name="picture-in-picture-2" class="text-white mx-auto my-auto"></lucide-icon>
    </button>
    <button *ngIf="publisherAudioOn" title="Mute" class="w-10 h-10 rounded-full bg-green-500 mr-4"
      (click)="togglePublisherAudio()">
      <lucide-icon name="mic" class="text-white mx-auto my-auto"></lucide-icon>
    </button>
    <button *ngIf="!publisherAudioOn" title="Un Mute" class="w-10 h-10 rounded-full bg-red-600 mr-4"
      (click)="togglePublisherAudio()">
      <lucide-icon name="mic-off" class="text-white mx-auto my-auto"></lucide-icon>
    </button>
    <button *ngIf="publisherVideoOn" title="Turn off camera" class="w-10 h-10 rounded-full bg-green-500 mr-4"
      (click)="togglePublisherVideo()">
      <lucide-icon name="video" class="text-white mx-auto my-auto"></lucide-icon>
    </button>
    <button *ngIf="!publisherVideoOn" title="Turn on camera" class="w-10 h-10 rounded-full bg-red-600 mr-4"
      (click)="togglePublisherVideo()">
      <lucide-icon name="video-off" class="text-white mx-auto my-auto"></lucide-icon>
    </button>
    <button title="Share Screen" class="w-10 h-10 rounded-full mr-4"
      [ngClass]="sharingScreen ? 'bg-red-600' : 'bg-stone-500'" [disabled]="sharingScreen"
      (click)="sharePublisherScreen()">
      <lucide-icon name="screen-share" class="text-white mx-auto my-auto"></lucide-icon>
    </button>
    <div *ngIf="showMenuOptions" class="absolute w-56 -top-48 z-30 p-2"
      [ngClass]="{'right-1/3' : largeMode, 'left-1/3' : !largeMode}">
      <div class="bg-slate-950 p-2 rounded text-left">
        <button class="text-white text-sm text-left w-full border-b-1 border-white p-2 hover:bg-slate-700"
          title="Blur Video" (click)="toggleBlurPublisherVideo()">
          <span *ngIf="publisherVideoBlurred">Un </span>Blur Video
        </button>
        <button class="text-white text-sm text-left w-full border-b-1 border-white p-2 hover:bg-slate-700"
          title="Use logo as background" (click)="togglePublisherVideoBackground()">
          <span *ngIf="publisherVideoBackgroundOn">No</span>
          <span *ngIf="!publisherVideoBackgroundOn">Use virtual</span>&nbsp;background
        </button>
        <button class="text-white text-sm text-left w-full border-b-1 border-white p-2 hover:bg-slate-700"
          title="Reload Video Call" (click)="reload()">
          Reload Video Call
        </button>
        <button class="text-white text-sm text-left w-full border-b-1 border-white p-2 hover:bg-slate-700"
          title="Change Audio Input" (click)="toggleDisplayPublisherAudioDevices()">
          Change Audio Input
        </button>
        <div *ngIf="displayPublisherAudioDevices"
          class="z-10 w-52 absolute bottom-5 -right-52 bg-slate-950 divide-y divide-gray-100 rounded-lg shadow">
          <ul class="p-2 text-sm text-white">
            <li *ngFor="let audioDevice of publisherAudioDevices" class="block p-2 cursor-pointer hover:bg-slate-700"
              (click)="setPublisherAudioDevice(audioDevice.deviceId)">
              {{audioDevice.label}}
            </li>
          </ul>
        </div>
        <button class="text-white text-sm text-left w-full p-2 hover:bg-slate-700" title="Change Video Input"
          (click)="toggleDisplayPublisherVideoDevices()">
          Change Video Input
        </button>
        <div *ngIf="displayPublisherVideoDevices"
          class="z-10 w-52 absolute bottom-3 -right-52 bg-slate-950 divide-y divide-gray-100 rounded-lg shadow">
          <ul class="p-2 text-sm text-white">
            <li *ngFor="let videoDevice of publisherVideoDevices" class="block p-2 cursor-pointer hover:bg-slate-700"
              (click)="setPublisherVideoDevice(videoDevice.deviceId)">
              {{videoDevice.label}}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="fixed top-0 left-0 w-full h-full z-10" *ngIf="showMenuOptions" (click)="toggleDisplayMenuOptions()">
    </div>
    <button title="Toggle Options" class="w-10 h-10 rounded-full mr-4 border-b-1 border-white"
      [ngClass]="showMenuOptions ? 'bg-red-600' : 'bg-stone-500'" (click)="toggleDisplayMenuOptions()">
      <lucide-icon name="settings" class="text-white mx-auto my-auto"></lucide-icon>
    </button>
    <button title="End Call" class="w-10 h-10 rounded-full bg-red-600 mr-4 border-b-1 border-white"
      (click)="emitCallEnd()">
      <lucide-icon name="phone-off" class="text-white mx-auto my-auto -rotate-90"></lucide-icon>
    </button>
  </div>
</div>
