<div class="relative inline-block text-left p-2">
    <button (click)="open()" type="button" class="w-80 h-16 bg-white rounded-lg border border-neutral-300 inline-flex items-center justify-between p-6 w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 focus:outline-none focus:border-blue-300 focus:ring focus:ring-blue-200 relative z-10">
      <div class="flex items-center">
        <img *ngIf="icon" [src]="icon" alt="Dropdown Icon" class="mr-2 w-5 h-5" />
        <span>{{ title }}</span>
      </div>
      <div class="flex items-center">
        <ng-icon *ngIf="!isOpen" class="h-10 w-10 flex-none text-[#207EA7] bg-neutral-100 rounded-full" name="ionChevronDown" size="1.5rem"></ng-icon>
        <ng-icon *ngIf="isOpen" class="h-10 w-10 flex-none text-[#207EA7] bg-neutral-100 rounded-full" name="ionChevronUp" size="1.5rem"></ng-icon>
      </div>
    </button>

    <div *ngIf="isOpen" class="origin-top-right absolute right-0 mt-2 w-80 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-20">
      <div class="py-1">
        <ng-container *ngFor="let option of options">
          <button (click)="selectOption(option)" type="button" class="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-blue-500 hover:text-white">
            {{ option.details }}
          </button>
        </ng-container>
      </div>
    </div>
  </div>

