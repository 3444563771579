import { Injectable, inject } from "@angular/core";
import { ClientService } from "../client/client.service";
import { switchMap } from "rxjs";
import { accountinternal } from "../client/client";

@Injectable({
    providedIn: 'root'
})
export class AfiClinicalHistoryService {
    clientService = inject(ClientService);

    GetClinicalHistory(request: accountinternal.GetAFIClinicalHistoryRequest) {
        return this.clientService.client.pipe(switchMap(client => client.accountinternal.GetAFIClinicalHistory(request)))
    }

    GetClincialHistoryDocument(request: accountinternal.GetAFIClinicalHistoryDocumentRequest) {
        return this.clientService.client.pipe(switchMap(client => client.accountinternal.GetAFIClinicaHistoryDocument(request)))
    }
}