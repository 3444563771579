import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import OT from '@opentok/client';
import { NgIf, NgClass, NgFor } from '@angular/common';
import { LucideAngularModule } from 'lucide-angular';

@Component({
  selector: 'app-tokboxvideo',
  templateUrl: './tokboxvideo.component.html',
  standalone: true,
  imports: [LucideAngularModule, NgIf, NgClass, NgFor]
})
export class TokboxVideoComponent implements OnInit, AfterViewInit {

  @Input() roomSessionId: string = "";
  @Input() userTokenId: string = "";
  @Input() largeMode?: boolean = false;
  @Input() isClinician?: boolean = false;

  @Output() callEnd: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('subscriberThem') subscriberThem!: ElementRef;
  @ViewChild('publisherMe') publisherMe!: ElementRef;

  showMenuOptions: boolean = false;
  session: OT.Session | null = null;
  sessionSubscription: OT.Subscriber | undefined = undefined;
  publisher: OT.Publisher = new OT.Publisher;
  publisherAudioOn: boolean = true;
  publisherVideoOn: boolean = true;
  publisherMainView: boolean = false;
  publisherVideoBlurred: boolean = false;
  publisherVideoBackgroundOn: boolean = false;
  publisherAudioDevices: Array<OT.Device> = [];
  publisherAudioOutputDevices: Array<OT.AudioOutputDevice> = [];
  publisherVideoDevices: Array<OT.Device> = [];
  displayPublisherAudioDevices: boolean = false;
  displayPublisherAudioOutputDevices: boolean = false;
  displayPublisherVideoDevices: boolean = false;
  publisherPlayerSize: string = 'h-20 w-25';
  clientJoined: boolean = false;
  sharingScreen: boolean = false;
  defaultBackgroundUrl: string = './assets/images/call-background.png';
  clinicianBackgroundUrl: string = 'https://images.ctfassets.net/i3mh0tjpjx7v/1xT6anBNDLMxrQa75jUQ4a/c05bbf203c1d7cbb2f6749bf8f3b928d/Branded-Virtual-Background-4x3.jpg';

  constructor(private elem: ElementRef) {

  }

  ngOnInit() {
    this.publisherPlayerSize = this.largeMode ? 'h-44 w-60' : 'h-20 w-25';
  }

  ngAfterViewInit() {
    this.startVideoConnection();
  }

  private startVideoConnection() {
    const publisherMe = this.publisherMe.nativeElement;
    const subscriberThem = this.subscriberThem.nativeElement;
    this.initializeSession(publisherMe, subscriberThem);
  }

  ngOnDestroy() {
    this.stopVideoSubscriptions();
  }

  private stopVideoSubscriptions() {
    if (this.session) {
      this.session.disconnect();
    }
    if (this.publisher) {
      this.publisher.destroy();
    }
  }

  // Handling all of our errors here by alerting them
  handleError(error: any) {
    if (error) {
      alert(error.message);
    }
  }

  async requestPictureInPicture(): Promise<any> {
    const videoElement =
      this.elem.nativeElement?.querySelector('.OT_subscriber')?.querySelector('video');

    if (videoElement) {
      await videoElement
        .requestPictureInPicture()
        .catch((error: any) =>
          console.error('Error entering Picture in Picture mode:', error)
        );
    }
  }

  initializeSession(publisherMe: HTMLElement, subscriberThem: HTMLElement) {
    var apiKey = environment.tokboxAPIKey;
    this.session = OT.initSession(apiKey, this.roomSessionId);
    this.getPublisherAudioDevices();
    this.getPublisherVideoDevices();
    this.getPublisherAudioOutputDevices();
    // Subscribe to a newly created stream
    this.session.on('streamCreated', (event: any) => {
      this.clientJoined = true;
      this.sessionSubscription = this.session?.subscribe(event.stream, subscriberThem, {
        insertMode: 'after',
        fitMode: 'contain',
        width: '100%',
        height: '100%',
        style: {
          buttonDisplayMode: 'off'
        }
      }, this.handleError);
    });
    this.session.on('streamDestroyed', (event: any) => {
      // TODO: temporarily disable resetting replacing the client joining screen
      //this.clientJoined = false;
    });
    // Create a publisher - this is my video steam - the preview window
    OT.setLogLevel(6);

    this.publisher = OT.initPublisher(publisherMe, {
      insertMode: 'after',
      mirror: false,
      fitMode: 'cover',
      width: '100%',
      height: '100%',
      style: {
        buttonDisplayMode: 'off'
      }
    }, this.handleError);
    setTimeout(() => {
      if (this.isClinician) {
        this.publisherVideoBackgroundOn = true;
        this.publisher?.applyVideoFilter({
          type: 'backgroundReplacement',
          backgroundImgUrl: this.clinicianBackgroundUrl,
        });
      }
    }, 2000);
    this.connectToSession(this.publisher);
  }

  connectToSession(publisher: OT.Publisher) {
    this.session?.connect(this.userTokenId, (error: any) => {
      // If the connection is successful, initialize a publisher and publish to the session
      if (error) {
        this.handleError(error);
      } else {
        this.session?.publish(publisher, this.handleError);
      }
    });
  }

  getPublisherAudioDevices() {
    OT.getDevices((err, devices) => {
      const audioDevices = devices?.filter((device) => device.kind === 'audioInput');
      audioDevices?.forEach(device => {
        this.publisherAudioDevices.push(device);
      });
    });
  }

  getPublisherAudioOutputDevices() {
    OT.getAudioOutputDevices().then((devices) => {
      this.publisherAudioOutputDevices = devices;
    });
  }

  getPublisherVideoDevices() {
    OT.getDevices((err, devices) => {
      const videoDevices = devices?.filter((device) => device.kind === 'videoInput');
      videoDevices?.forEach(device => {
        this.publisherVideoDevices.push(device);
      });
    });
  }

  emitCallEnd() {
    this.callEnd.emit();
  }

  setPublisherAudioDevice(deviceID: string) {
    this.publisher?.setAudioSource(deviceID);
    this.displayPublisherAudioDevices = false;
  }

  setPubliserAudioOutputDevice(deviceID: any) {
    OT.setAudioOutputDevice(deviceID);
  }

  setPublisherVideoDevice(deviceID: string) {
    this.publisher?.setVideoSource(deviceID);
    this.displayPublisherVideoDevices = false;
  }

  toggleDisplayPublisherAudioDevices() {
    this.displayPublisherVideoDevices = false;
    this.displayPublisherAudioDevices = !this.displayPublisherAudioDevices;
  }

  toggleDisplayPublisherVideoDevices() {
    this.displayPublisherAudioDevices = false;
    this.displayPublisherVideoDevices = !this.displayPublisherVideoDevices;
  }

  toggleDisplayPublisherAudioOutputDevices() {
    this.displayPublisherAudioOutputDevices = !this.displayPublisherAudioOutputDevices;
  }

  toggleDisplayMenuOptions() {
    this.showMenuOptions = !this.showMenuOptions;
  }

  togglePublisherAudio() {
    this.publisherAudioOn = !this.publisherAudioOn;
    this.publisher?.publishAudio(this.publisherAudioOn);
  }

  togglePublisherVideo() {
    this.publisherVideoOn = !this.publisherVideoOn;
    this.publisher?.publishVideo(this.publisherVideoOn);
  }

  toggleBlurPublisherVideo() {
    this.publisherVideoBlurred = !this.publisherVideoBlurred;
    if (this.publisherVideoBlurred) {
      this.publisher?.applyVideoFilter({
        type: 'backgroundBlur',
        blurStrength: 'high',
      });
    } else {
      this.publisher?.clearVideoFilter();
    }
  }

  togglePublisherVideoBackground() {
    this.publisherVideoBackgroundOn = !this.publisherVideoBackgroundOn;
    if (this.publisherVideoBackgroundOn) {
      const backgroundImgUrl = this.isClinician ? this.clinicianBackgroundUrl : this.defaultBackgroundUrl;
      this.publisher?.applyVideoFilter({
        type: 'backgroundReplacement',
        backgroundImgUrl: backgroundImgUrl,
      });
    } else {
      this.publisher?.clearVideoFilter();
    }
  }

  togglePublisherMainView() {
    this.publisherMainView = !this.publisherMainView;
  }

  sharePublisherScreen() {
    OT.checkScreenSharingCapability((response) => {
      if (!response.supported || response.extensionRegistered === false) {
        // This browser does not support screen sharing
        this.handleError({ error: 'Screen sharing not supported' });
      } else {
        this.sharingScreen = true;
        this.session?.unpublish(this.publisher);
        OT.getUserMedia({
          videoSource: null
        }).then((devices) => {
          this.publisher = OT.initPublisher(this.publisherMe.nativeElement, {
            videoSource: 'screen',
            audioSource: devices.getAudioTracks()[0],
            insertMode: 'after',
            fitMode: 'contain',
            width: '100%',
            height: '100%',
            style: {
              buttonDisplayMode: 'off'
            }
          });
          this.publisher.on('accessDenied', (event) => {
            this.republishCamera();
          });
          this.publisher.on('mediaStopped', (event: any) => {
            this.republishCamera();
          });
          this.session?.publish(this.publisher);
        }).catch(error => {
          this.republishCamera();
          this.handleError({ error: 'Problem sharing screen (User Media)' });
        });
      }
    });
  }

  republishCamera() {
    this.session?.unpublish(this.publisher);
    this.publisher = OT.initPublisher(this.publisherMe.nativeElement, {
      insertMode: 'after',
      mirror: false,
      fitMode: 'contain',
      width: '100%',
      height: '100%',
      style: {
        buttonDisplayMode: 'off'
      }
    });
    this.sharingScreen = false;
    this.session?.publish(this.publisher);
    setTimeout(() => {
      const backgroundImgUrl = this.isClinician ? this.clinicianBackgroundUrl : this.defaultBackgroundUrl;
      this.publisherVideoBackgroundOn = true;
      this.publisher?.applyVideoFilter({
        type: 'backgroundReplacement',
        backgroundImgUrl: backgroundImgUrl,
      });
    }, 2000);
  }

  async launchPictureInPicture() {
    await this.requestPictureInPicture();
  }

  reload() {
    this.stopVideoSubscriptions();
    this.startVideoConnection();
  }
}
