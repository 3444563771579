import { NgIf } from '@angular/common';
import {booleanAttribute, Component, EventEmitter, Input, Output} from '@angular/core';
import { NgIconsModule } from '@ng-icons/core';

@Component({
  selector: 'app-title-sidebar',
  templateUrl: './title-sidebar.component.html',
  standalone: true,
  imports: [NgIconsModule, NgIf]
})

export class TitleSidebarComponent {
  @Input() title: string = "";
  @Input() description!: string;
  @Input() linktext: string = "";
  @Input({transform: booleanAttribute}) createBtn = false;
  @Input({transform: booleanAttribute}) backBtn = false;
  @Output() linkclick = new EventEmitter<void>();
  @Output() createclick = new EventEmitter<void>();
  @Output() backclick = new EventEmitter<void>();


  linkedClicked() {
    this.linkclick.emit();
  }

  createClicked() {
    this.createclick.emit();
  }
  
  backClicked() {
    this.backclick.emit();
  }
}
