import { DatePipe, NgIf, UpperCasePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { RichTextViewerComponent } from '../forms/rich-text/rich-text-viewer/rich-text-viewer.component';
import { AccordionComponent } from '../accordion/accordion.component';
import { NgIconsModule } from '@ng-icons/core';
import { StaffNamePipe } from 'src/shared/pipes/staff-name/staff-name.pipe';
@Component({
  selector: 'app-medical-history',
  templateUrl: './medical-history.component.html',
  styleUrl: './medical-history.component.scss',
  standalone: true,
  imports: [UpperCasePipe, DatePipe, RichTextViewerComponent, NgIf, AccordionComponent, NgIconsModule, StaffNamePipe, ]
})
export class MedicalHistoryComponent implements OnInit {
  isExpanded = false;
  venomCodes: string = '';
  @Input() consultationHistory: any;
  ngOnInit(): void {
    this.displayVenomCodes();
  }

  toggleExpand() {
    this.isExpanded = !this.isExpanded;
  }

  displayVenomCodes() {
    const processDiagnosisGroups = (reason: { [x: string]: any[] }) => {
      // Extract the names from each non-empty diagnosis group and concatenate them
      return [
        'likely_diagnosis_codes',
        'likely_diagnosis_groups',
        'other_diagnosis_codes',
        'other_diagnosis_groups',
      ]
        .map((key) =>
          reason[key] && reason[key].length
            ? reason[key].map((d) => d.name).join(', ')
            : ''
        )
        .filter((names) => names)
        .join(', ');
    };

    this.venomCodes = this.consultationHistory.reasons
      .map(processDiagnosisGroups)
      .join(', ');
  }
}
