<div class="relative" [class.open]="isDropdownOpen">
    <app-label [label]="label"></app-label>
    <input
      #dropdownInput
      type="text"
      [(ngModel)]="searchText"
      (input)="filterOptions()"
      (click)="toggleDropdown()"
      placeholder="{{placeholder}}"
      class="w-full px-4 py-4 border border-gray-300 rounded-[10px] shadow-sm focus:ring focus:ring-indigo-300 focus:border-indigo-300 sm:text-sm"
    />
  
    <div
      class="absolute z-10 mt-2 w-full bg-white border border-gray-300 rounded-md shadow-lg"
      *ngIf="isDropdownOpen"
    >
      <div class="max-h-36 overflow-y-auto">
        <div
          class="py-2 px-4 cursor-pointer hover:bg-indigo-100 flex items-center justify-between"
          *ngFor="let option of filteredOptions"
          (click)="toggleOption(option.value)"
        >
           {{ option.name }}
          <span *ngIf="isSelected(option.value)" class="mr-2"
            ><svg
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="12" cy="12" r="12" fill="#4AB8B9" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.6094 8.25L10.6875 13.2L8.39062 10.89L6.75 12.54L10.6875 16.5L17.25 9.9L15.6094 8.25Z"
                fill="white"
              />
            </svg>
          </span>
  
        </div>
      </div>
    </div>
  </div>
  