<ul role="list" class="divide-y divide-gray-100" data-automationid="collapsed-list-container">
  <li (click)="rowclicked(item)" *ngFor="let item of config.items" data-automationid="collapsed-list-row"
    class="relative py-5 cursor-pointer hover:bg-gray-50">
    <div class="collapse-header collapse-header justify-between flex gap-x-6">
      <div class="flex min-w-0 gap-x-4">
        <img data-automationid="collapsed-list-row-image" *ngIf="item.image"
          class="h-12 w-12 flex-none rounded-full bg-gray-50" [src]="item.image" alt="">
        <div class="min-w-0 flex-auto">
          <p class="text-sm font-semibold leading-6 text-gray-900" data-automationid="collapsed-list-row-title">
            <span class="absolute inset-x-0 -top-px bottom-0"></span>
            {{item.title}}
          </p>
          <p class="mt-1 flex text-xs leading-5 text-gray-500" data-automationid="collapsed-list-row-description">
            <a class="relative truncate" data-automationid="collapsed-list-row-description-link">{{item.description}}</a>
          </p>
          <div class="flex space-x-2 mt-3" *ngIf="item.badges">
            <span *ngFor="let badge of item.badges"
              class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium border-dotted border-1 "
              [ngStyle]="{'border-color': badge.color}"
              [ngClass]="'bg-' + badge.color + '-50 text-' + badge.color + '-700'">{{badge.name}}</span>
          </div>
        </div>
      </div>
      <div class="flex shrink-0 items-center gap-x-4">
        <ng-icon *ngIf="item.isCollapsed" class="h-5 w-5 flex-none text-gray-400" name="ionChevronDown" size="1.0rem" data-automationid="collapse-down-button"></ng-icon>
        <ng-icon *ngIf="!item.isCollapsed" class="h-5 w-5 flex-none text-gray-400" name="ionChevronUp" size="1.0rem" data-automationid="collapse-up-button"></ng-icon>
      </div>
    </div>
    <div *ngIf="item.isCollapsed" data-automationid="collapsed-text-container" class="mt-1">
    </div>
  </li>
</ul>