import { NgFor, NgIf } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { NgIconsModule } from '@ng-icons/core';
import { VenomCode, VenomType } from 'src/shared/models/interfaces/venomCode';
import { venomcodeinternal } from 'src/shared/services/client/client';
import { VenomCodesService } from 'src/shared/services/venomCodes/venom-codes.service';

@Component({
  selector: 'app-venom-dropdown',
  templateUrl: './venom-dropdown.component.html',
  styleUrl: './venom-dropdown.component.scss',
  standalone: true,
  imports: [NgFor, NgIconsModule, NgIf]
})
export class VenomDropdownComponent implements OnInit {
  error!: string;
  showDropdown: boolean = false;
  categories = new Set<venomcodeinternal.CategoryResponse>()
  groups = new Set<venomcodeinternal.VenomGroupWithCodesResponse>()
  codes = new Set<VenomCode>()

  selectedOptions: VenomType[] = [];

  @Input() showGroup: boolean = false;
  @Input() showCategory: boolean = false;
  @Input() showCode: boolean = false;
  @Input() placeholder: string = "Select an option..."

  @Output() selectedOptionsChange: EventEmitter<VenomType[]> = new EventEmitter<VenomType[]>();

  constructor(
    private eRef: ElementRef,
    private venomCodeService: VenomCodesService,
  ) { }

  ngOnInit() {
    this.load()
  }

  load() {
    this.venomCodeService.getListVenomCodes().subscribe({
      next: (data) => {
        data?.categories.forEach((category: venomcodeinternal.CategoryResponse) => {
          this.categories.add(category)
          category.groups.forEach((group) => {
            this.groups.add(group)
            group.venom_codes.forEach((code) => {
              this.codes.add({
                "doc_id": code.doc_id,
                "name": code.term_name
              })
            })
          })
        })
      },
      error: err => this.error = err
    })
  }

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  removeSelection(option: VenomType, event: MouseEvent) {
    event.stopPropagation();
    const index = this.selectedOptions.indexOf(option);
    if (index > -1) {
      this.selectedOptions.splice(index, 1);
    }
  }

  toggleSelection(option: VenomType, event?: MouseEvent) {
    const index = this.selectedOptions.findIndex(selectedOption => selectedOption.name === option.name)
    if (index > -1) {
      this.selectedOptions.splice(index, 1);
    } else {
      this.selectedOptions.push(option);
    }
    if (event) {
      event.stopPropagation();
    }

    this.selectedOptionsChange.emit(this.selectedOptions);
  }

  isSelected(option: VenomType) {
    return this.selectedOptions.some(selectedOption => selectedOption.name === option.name);
  }

  @HostListener('document:click', ['$event'])
  clickout(event: MouseEvent) {
    if (!this.showDropdown) return;
    if (!this.eRef.nativeElement.contains(event.target)) this.showDropdown = false;
  }
}
