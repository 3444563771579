import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormSubmissionService } from 'src/shared/services/formSubmission/formSubmission.service';
import { ConsultationFormsDataService, FormDetails } from 'src/shared/services/consultation-forms.data.service/consultation-forms.data.service'
import { BehaviorSubject, Subscription } from 'rxjs';
import { FormioComponent, FormioModule } from '@formio/angular';

@Component({
  selector: 'app-formio-form',
  templateUrl: './formio-form.component.html',
  standalone: true,
  imports: [FormioModule]
})
export class FormioFormComponent implements OnInit, OnDestroy {
  constructor(private formSubmissionService: FormSubmissionService, private consultationFormsDataService: ConsultationFormsDataService) {

  }
  ngOnDestroy(): void {
    this.saveSubmission?.unsubscribe();
  }

  ngOnInit(): void {
    this.formUrl = `/jxcochdpcfazlkz/${this.formDetails.formName}`;
    this.saveSubmission = this.formDetails.submit.subscribe(_ => {
      if (!this.submitted && this.formSubmission) {
        if (this.formSubmission.isValid) {
          let submission = { data: this.formSubmission.data, _fvid: this.version }
          this.formSubmissionService.SendSubmission(submission, this.formDetails.formName, this.consultationId).subscribe(() => {
            this.submitted = true
            this.formDetails.submitComplete.next(true)
          })
        }
      }
    })
  }

  @Input()
  preloadDetails: any;

  @Input()
  formDetails!: FormDetails;

  version!: number;

  @Input()
  consultationId!: string;

  @Input()
  standAlone?: boolean = false;

  @ViewChild(FormioComponent, { static: false })
  formio!: FormioComponent;

  @ViewChild('formioContainer', { static: true })
  formioContainer!: ElementRef;

  suggestedVenomCodes: string[] = []
  futureForms: string[] = []

  submitted: boolean = false;

  formUrl!: string;

  formSubmission: any;
  outcomes: string[] = [];
  saveSubmission?: Subscription;
  refreshForm: EventEmitter<any> = new EventEmitter<any>();

  @Output() sendUpdates: EventEmitter<any> = new EventEmitter<any>();
  @Output() sendIsValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() sendIsTouched: EventEmitter<any> = new EventEmitter<any>();

  formChanged(data: any) {
    if (data.data) {
      if (this.standAlone) {
        const formData = {
          data: data.data,
          _fvid: this.version
        }
        this.sendUpdates.emit(formData);
        if (!data.flags?.noValidate)
          this.sendIsValid.emit(data.isValid);
      }
      this.formSubmission = data;
      this.outcomes = []
      if (data?.data?.outputs?.suggestedVenomCodes) {
        let newSuggestedVenomCodes = Object.keys(data.data.outputs.suggestedVenomCodes);
        if (newSuggestedVenomCodes !== this.suggestedVenomCodes) {
          // get new venom codes and update the suggestion list
          let added = newSuggestedVenomCodes.filter(item => this.suggestedVenomCodes.indexOf(item) < 0);
          this.consultationFormsDataService.suggestedVenomCodes$().push(...added)
          // get removed venom codes and update the suggestion list
          let removed = this.suggestedVenomCodes.filter(item => newSuggestedVenomCodes.indexOf(item) < 0);
          this.consultationFormsDataService.suggestedVenomCodes$.set(this.consultationFormsDataService.suggestedVenomCodes$().filter(item => !removed.includes(item)))

          this.suggestedVenomCodes = newSuggestedVenomCodes;
        }
      }
      if (data?.data?.outputs?.futureForms) {
        let newforms = Object.keys(data.data.outputs.futureForms);
        if (newforms !== this.futureForms.map(form => form)) {
          // get new venom codes and update the suggestion list
          let added = newforms.filter(item => this.futureForms.indexOf(item) < 0);
          this.consultationFormsDataService.formsRequired$().push(...added.map(formname => { return { formName: formname, submitted: false, submitComplete: new BehaviorSubject<boolean>(false), submit: new EventEmitter<boolean>() } }))

          // get removed venom codes and update the suggestion list
          let removed = this.futureForms.filter(form => newforms.indexOf(form) < 0);
          this.consultationFormsDataService.formsRequired$.set(this.consultationFormsDataService.formsRequired$().filter(item => !removed.includes(item.formName)))

          this.futureForms = newforms;
        }
      }
      var inputValue = document.getElementsByName("data[input][checkbox1]")
      if (inputValue.length == 1) {
        (<HTMLInputElement>inputValue[0]).checked = true;
      }
    }
  }
  formLoaded(data: any) {
    this.version = data._vid
    if (this.preloadDetails) {
      this.refreshForm.emit({
        submission: this.preloadDetails,
      });
    }
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const clickedInside = this.formioContainer.nativeElement.contains(event.target);
    if (clickedInside) {
      this.sendIsTouched.emit({ touched: true });
    }
  }

}
