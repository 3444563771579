import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild, afterRender, inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { isBoolean } from 'lodash';

@Component({
  selector: 'app-dialog-simple',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './dialog-simple.component.html',
  styleUrl: './dialog-simple.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogSimpleComponent implements OnDestroy {
  @Input() dialogTitle!: string;
  @Input() set isOpen(open: boolean) {
    if (isBoolean(open) && open && !this.dialog?.nativeElement?.open) {
      this.open();
    }
    if (isBoolean(open) && !open && this.dialog?.nativeElement?.open) {
      this.close();
    }
  }
  dialogOpen = signal<boolean>(false);
  @Input() styleClass?: string;
  @ViewChild('appDialog', { static: true }) dialog!: ElementRef<HTMLDialogElement>;
  cdr = inject(ChangeDetectorRef);
  backdropClick!: (event: any) => void;
  @Output() onClose = new EventEmitter<boolean>();

  constructor() {
    afterRender(() => {
      this.backdropClick = (event) => this.onBackdropClick(event);
    })
  }

  ngOnDestroy(): void {
    this.close();
    this.dialog.nativeElement.removeEventListener('click', this.backdropClick);
  }

  open() {
    this.removeClickListener();
    // Open modal and start event listener.
    this.dialog.nativeElement?.show();
    this.dialogOpen.set(true);
    this.setClickListener();
    this.cdr.detectChanges();
  }

  close() {
    this.dialog.nativeElement?.close();
    this.dialogOpen.set(false);
    this.onClose.emit();
    this.removeClickListener();
    this.cdr.detectChanges();
  }


  setClickListener() {
    this.dialog.nativeElement.addEventListener('click', this.backdropClick)
  }

  removeClickListener() {
    this.dialog.nativeElement.removeEventListener('click', this.backdropClick);
  }

  onBackdropClick(event: any) {
    let rect = this.dialog.nativeElement.getBoundingClientRect();
    // If click is outside of dialog rect
    if (rect.left > event.clientX ||
      rect.right < event.clientX ||
      rect.top > event.clientY ||
      rect.bottom < event.clientY
    ) {
      this.close();
    }
  }
}
