import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { NgIconsModule } from '@ng-icons/core';
import { LucideAngularModule } from 'lucide-angular';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrl: './file-upload.component.scss',
  standalone: true,
  imports: [NgIf, LucideAngularModule, NgIconsModule]
})
export class FileUploadComponent {
  @Input() label: string = '';
  @Input() description: string = '';
  @Input() multiple: boolean = false;
  @Input() fileTypes: string = '';
  @Input() uploadText: string = 'Click to upload';
  @Input() progress: number = 0;
  @Input() displayUploadTooltip: boolean = true;
  @Output() fileSelected = new EventEmitter<{ event: Event | DragEvent, additionalParam?: any }>();
  @Input() additionalParam?: any;
  @Input() uniqueId: string = 'file-upload';
  @Input() useCustomTemplate: boolean = false;

  handleFileSelection(event: Event): void {
    this.fileSelected.emit({ event, additionalParam: this.additionalParam });
  }

  handleDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.fileSelected.emit({ event, additionalParam: this.additionalParam });
  }

  handleDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }
}
