import { Injectable, inject } from "@angular/core";
import { ClientService } from "../client/client.service";
import { Observable, switchMap } from "rxjs";
import { consultationinternal } from "../client/client";
import { doc } from "@angular/fire/firestore";

@Injectable({
    providedIn: 'root'
})
export class PartnerPracticeService {
    clientService = inject(ClientService);
    listPartnerPractices() {
        return this.clientService.client.pipe(switchMap(client => client.consultationinternal.ListPractices()))
    }

    createPartnerPractice(practice: consultationinternal.CreatePartnerPracticeRequest) {
        return this.clientService.client.pipe(switchMap(client => client.consultationinternal.CreatePartnerPractice(practice)))
    }
    getPartnerPractice(docId: string): Observable<consultationinternal.GetPartnerPracticeResponse> {
        return this.clientService.client.pipe(switchMap(client => client.consultationinternal.GetPartnerPractice(docId)))
    }
    updatePartnerPractice(docId: string, request: consultationinternal.UpdatePartnerPracticeRequest) {
        return this.clientService.client.pipe(switchMap(client => client.consultationinternal.UpdatePartnerPractice(docId, request)))
    }
    deletePartnerPractice(docId: string) {
        return this.clientService.client.pipe(switchMap(client => client.consultationinternal.DeletePartnerPractice(docId)))
    }
}