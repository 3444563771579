import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ModalPositionsEnum } from '../../../models/interfaces/modal-positions.enum';
import { NgClass, NgStyle } from '@angular/common';

@Component({
  selector: 'app-joii-modal',
  templateUrl: './joii-modal.component.html',
  styleUrl: './joii-modal.component.scss',
  standalone: true,
  imports: [NgClass, NgStyle]
})
export class JoiiModalComponent implements OnInit {

  @Input() showModal: boolean = false;
  @Input() customArticleClasses: string ='';
  @Input() customArticleStyles: {[key: string]: string} = {};
  @Input() modalBackground: string='';
  @Input() position: ModalPositionsEnum = ModalPositionsEnum.center;
  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();
  positionStyleString: string = '';

  ngOnInit() {
    this.setPositionStyles();
  }

  setPositionStyles() {
    switch (this.position) {
      case ModalPositionsEnum.topLeft:
        this.positionStyleString = 'left-4 top-4';
        break;
      case ModalPositionsEnum.topRight:
        this.positionStyleString = 'right-4 top-4';
        break;
      default:
      case ModalPositionsEnum.center:
        this.positionStyleString = 'inset-0 mx-auto my-auto';
        break;
      case ModalPositionsEnum.bottomLeft:
        this.positionStyleString = 'left-4 bottom-4';
        break;
      case ModalPositionsEnum.bottomRight:
        this.positionStyleString = 'right-4 bottom-4';
        break;
    }
  }

  setCloseModal() {
    this.closeModal.emit();
  }
}
