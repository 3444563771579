import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from 'src/shared/components/authentication/login/login.component';
import { LoginGuard } from 'src/shared/guards/login.guard';
import { RegisterComponent } from 'src/shared/components/authentication/register/register.component';
import { ForgottenPasswordComponent } from 'src/shared/components/authentication/forgottenpassword/forgottenpassword.component';
import { TenantsComponent } from 'src/shared/components/authentication/tenants/tenants.component';
import { NotFoundComponent } from 'src/shared/components/notfound/notfound.component';
import { CheckStaffAccessComponent } from 'src/shared/components/authentication/checkstaffaccess/checkstaffaccess.component';
import { NotAuthorisedComponent } from 'src/shared/components/notauthorised/notauthorised.component';

const routes: Routes = [
  { path: '', redirectTo: "checkaccess", pathMatch: "full" },
  { path: 'checkaccess', component: CheckStaffAccessComponent, canActivate: [LoginGuard] },
  { path: 'login', component: LoginComponent, title: 'Joii PMS - Login' },
  { path: 'register', component: RegisterComponent, title: 'Joii PMS - Register' },
  { path: 'forgottenpassword', component: ForgottenPasswordComponent, title: 'Joii PMS - Forgotten Password' },
  { path: 'tenants/:redirectUrl', component: TenantsComponent },
  { path: 'unauthorised', component: NotAuthorisedComponent },
  { path: '**', redirectTo: "notfound" },
  { path: 'notfound', component: NotFoundComponent, title: 'Joii PMS - Page Not Found' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class PMSRoutingModule { }
