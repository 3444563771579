<div class="flex flex-col content-between w-full">
    @if(healthCheckData.diet) {
        <h2>Diet</h2>
        <div class="w-full text-gray-500 text-base font-medium font-['Inter'] leading-normal tracking-tight">
            {{ healthCheckData.diet }}
        </div>
    }
    @if (healthCheckData.weight) {
        <h2>Weight</h2>
        <div class="w-full text-gray-500 text-base font-medium font-['Inter'] leading-normal tracking-tight">
            {{ healthCheckData.weight / 1000 }} KG
        </div>
    }
    @if(petImages.length > 0) {
        <div class="flex w-full flex-wrap flex-row">
            @for (photo of petImages; track $index) {
                @if ((photo | fileExtension: 'type') === 'image') {
                    <img [src]="photo" class="max-w-1/4 cursor-pointer p-1 border-blue-600 rounded-lg overflow-hidden object-cover" (click)="showModal()">
                }
            }
        </div>
    }
    @if (walkingVideos.length > 0) {
        <div class="flex w-full flex-wrap flex-row">
            @for (video of walkingVideos; track $index) { 
                    <video controls class="max-w-1/4 cursor-pointer p-1 border-blue-600 rounded-lg overflow-hidden object-cover">
                        <source [src]="video">
                    </video>
            }
        </div>
    }
</div>