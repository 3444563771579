<div *ngIf="view == 'list'">
    <div *ngFor="let staff of staffOnline" class="group block flex-shrink-0">
        <div class="flex items-center">
            <div>
                <span class="relative inline-block">
                    <img class="h-8 w-8 rounded-full" [src]="staff.photoUrl" alt="">
                    <span
                        class="absolute right-0 top-0 block h-2 w-2 rounded-full bg-green-400 ring-2 ring-white"></span>
                </span>
            </div>
            <div class="ml-3">
                <p class="text-sm font-medium text-gray-700 group-hover:text-gray-900">{{staff.name}}<span
                        *ngIf="!production">&nbsp;({{staff.environment ?? 'unknown'}})</span></p>
                <!-- <p class="text-xs font-medium text-gray-500 group-hover:text-gray-700">View profile</p> -->
            </div>
        </div>
    </div>
</div>
<div *ngIf="view == 'photos'">
    <div class="flex -space-x-1 overflow-hidden p-1 pr-2">
        @for (staff of staffOnline | slice:0:staffToShow; track $index) {
        <div [floatUi]="staff.name" [showTrigger]="tooltipTriggerEnum.hover" [placement]="tooltipPlacementEnum.BOTTOM"
            [showDelay]="50" [preventOverflow]="true" [positionFixed]="true">
            <img *ngIf="staff.photoUrl" class="inline-block h-8 w-8 rounded-full ring-2 ring-white"
                [src]="staff.photoUrl" [alt]="staff.name">
            <span *ngIf="!staff.photoUrl && staff.name"
                class="inline-block h-8 w-8 rounded-full ring-2 joii-ring-danger-mid joii-bg-danger-light joii-text-danger-dark text-center align-middle text-2xl leading-9">
                {{staff.name[0]}}
            </span>
        </div>
        }
        @if (overflowNames) {
        <div [floatUi]="overflowNames" [showTrigger]="tooltipTriggerEnum.hover"
            [placement]="tooltipPlacementEnum.BOTTOM" [showDelay]="50" [preventOverflow]="true" [positionFixed]="true"
            class="inline-block h-8 w-8 rounded-full ring-2 joii-ring-danger-mid joii-bg-danger-light joii-text-danger-dark text-center align-middle text-2xl leading-9">
            ...
        </div>
        }
    </div>
</div>
