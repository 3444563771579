<span class="sm:col-span-1 sm:col-span-2 sm:col-span-3 sm:col-span-4 sm:col-span-5 sm:col-span-6"></span>
<app-label [label]="label"></app-label>
<div class="relative rounded-md shadow-sm border border-gray-300">
  <div class="flex" [ngClass]="{
    'text-gray-900 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ': !readonly,
    'text-gray-500 bg-gray-200 focus:outline-none focus:ring-0 sm:text-sm sm:leading-6 cursor-not-allowed': readonly
  }">
    <button id="dropdown-phone-button" (click)="togglePostcodeDropdown()" class="joii-input flex justify-center items-center w-1/5 border-r border-gray-300 relative" type="button">
      <span class="fi fi-{{selectedCountryCode?.value.toLowerCase()}} fis absolute top-1/2 transform -translate-y-1/2"></span>
      <ng-icon *ngIf="!showPostcodeDropdown" class="h-10 w-10 flex-none absolute right-0 top-1/2 transform -translate-y-1/2" name="ionChevronDown" size="1.5rem"></ng-icon>
      <ng-icon *ngIf="showPostcodeDropdown" class="h-10 w-10 flex-none absolute right-0 top-1/2 transform -translate-y-1/2" name="ionChevronUp" size="1.5rem"></ng-icon>
    </button>
    <span class="py-4 ml-2">{{ selectedCountryCode?.data }}</span>
    <input [placeholder]="placeholder || ''" [id]="name" [readonly]="readonly" [disabled]="disabled" [type]="type"
      [ngModel]="value" (ngModelChange)="onInputChange($event)" (blur)="onInputBlur($event)" class="joii-input pl-1 ml-2 w-4/5">
  </div>
  <ng-container *ngIf="showPostcodeDropdown">
    <div class="z-10 absolute top-full bg-white divide-y divide-gray-100 rounded-lg shadow w-full dark:bg-gray-700 max-h-40 overflow-y-auto">
      <ul class="py-2 text-sm text-gray-700 dark:text-gray-200">
        <li *ngFor="let option of countryCodeOptions">
          <button type="button" (click)="selectCountry(option)"
            class="inline-flex w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-600 dark:hover:text-white" role="menuitem">
            <div class="inline-flex items-center">
              <span class="fi fi-{{option.value.toLowerCase()}} fis mr-5"></span>
              {{option.name}} ({{ option.data }})
            </div>
          </button>
        </li>
      </ul>
    </div>
  </ng-container>
</div>
<app-validation [errorPositionAbsolute]="errorPositionAbsolute" *ngIf="formControl || errormessage" [control]="formControl" [submitted]="submitted"
  [errormessage]="errormessage"></app-validation>
