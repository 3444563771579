import { Injectable, inject } from "@angular/core";
import { uuid } from "../client/client";
import { ClientService } from "../client/client.service";
import { Observable, switchMap } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class FormSubmissionService {
    clientService = inject(ClientService);

    public GetSubmission(submissionId: uuid.UUID): Observable<FormSubmission> {
        return this.clientService.client.pipe(
            switchMap(client => client.consultationinternal.GetSubmission(submissionId))
        )
    }

    public SendSubmission(submissionData: any, formName: string, consultationId: uuid.UUID): Observable<any> {
        return this.clientService.client.pipe(
            switchMap(client => client.consultationinternal.CreateSubmission({
                form_data: submissionData,
                form_name: formName,
                consultation_id: consultationId
            })));
    }

    public GetAllSubmissions() {
        return this.clientService.client.pipe(
            switchMap(client => client.consultationinternal.GetSubmissions()));
    }
}
export interface FormSubmission {
    form_name: string
    form_data: any
}