import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  inject,
  OnDestroy,
  ViewChild,
  ElementRef
} from "@angular/core";
import { venomcodeextended } from "src/shared/models/interfaces/venom-code-extended.interface";
import { has, uniqBy} from 'lodash';
import { VenomCodesUtilsService } from "src/shared/services/venomCodes/venom-codes-utils.service";
import { BehaviorSubject, Subscription, combineLatest, debounceTime, map, of, switchMap } from "rxjs";
import { LabelComponent } from "../forms/label/label.component";
import { NgClass, NgFor, NgIf, NgTemplateOutlet } from "@angular/common";
import { NgIconsModule } from "@ng-icons/core";
import { FormsModule } from "@angular/forms";
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ScrollingModule as ExperimentalScrollingModule } from '@angular/cdk-experimental/scrolling';

@Component({
  selector: 'app-venom-codes-picker',
  templateUrl: './venom-codes-picker.component.html',
  styleUrl: './venom-codes-picker.component.scss',
  standalone: true,
  imports: [NgIf, LabelComponent, NgIconsModule, FormsModule, NgClass, NgTemplateOutlet, ScrollingModule, ExperimentalScrollingModule, NgFor]
})
export class VenomCodesPickerComponent implements OnInit, OnDestroy {

  @Input() label: string = '';
  @Input() availableVenomCodes: Array<venomcodeextended.venomCategory> = [];
  @Input() incomingGroups: Array<string> = [];
  @Input() incomingCodes: Array<string> = [];
  @Output() updateCodeValues: EventEmitter<Array<string>> = new EventEmitter<Array<string>>();
  @Output() updateGroupValues: EventEmitter<Array<string>> = new EventEmitter<Array<string>>();
  @Output() updateOccurred: EventEmitter<boolean | null> = new EventEmitter<boolean | null>();
  @Output() updateTouched: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('searchInput') searchInput!: ElementRef;
  @ViewChild('pickerInput') pickerInput!: ElementRef;
  allVenomCodes: Array<any> = [];
  filteredVenomCodesCategories: Array<venomcodeextended.venomCategory> = [];
  selectedCodes: Array<any> = [];
  selectedGroups: Array<any> = [];
  categoryFilters: Array<any> = [];
  selectedCategory = new BehaviorSubject<any>(null);
  displayOverlay: boolean = false;
  pickerSearchTerm: string = '';
  searchSubject = new BehaviorSubject<string>('');
  utils = inject(VenomCodesUtilsService);
  overlayTop: boolean = false;
  overlayHeight: number = 370;

  subscription = new Subscription();

  constructor() {
   }

  ngOnInit() {
    this.allVenomCodes = this.flattenApiResponseBatched(this.incomingGroups, this.incomingCodes);
    this.filteredVenomCodesCategories = [...this.allVenomCodes];
    this.subscription = this.setupSearch().subscribe();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  flattenApiResponseBatched(incomingGroups: string[], incomingCodes: string[]): any[] {
    const batchSize = 100; // Process 100 items at a time
    const flattenedArray: any[] = [];

    for (let i = 0; i < this.availableVenomCodes.length; i += batchSize) {
      const batchCategories = this.availableVenomCodes.slice(i, i + batchSize);
      this.processBatch(batchCategories, incomingGroups, incomingCodes, flattenedArray);
    }

    return flattenedArray;
  }

  processBatch(categories: any[], incomingGroups: string[], incomingCodes: string[], flattenedArray: any[]): void {
    categories.forEach((category, index) => {
      flattenedArray.push({ type: 'category', name: category.name, doc_id: category.doc_id, selected: false });
      this.categoryFilters.push({ name: category.name, doc_id: category.doc_id, selected: false });

      category.groups.forEach((group: { name: any; doc_id: string; venom_codes: any[]; }, groupIndex: number) => {
        const groupItem = { type: 'group', name: group.name, doc_id: group.doc_id, category_doc_id: category.doc_id, selected: incomingGroups?.includes(group.doc_id) || false};
        flattenedArray.push(groupItem);
        groupItem.selected && this.selectedGroups.push(groupItem);

        group.venom_codes.forEach((code: { term_name: any; doc_id: string; }, codeIndex: number) => {
          const codeItem = { type: 'code', name: code.term_name, doc_id: code.doc_id, group_doc_id: group.doc_id, category_doc_id: category.doc_id, selected: incomingCodes?.includes(code.doc_id) || false };
          flattenedArray.push(codeItem);
          codeItem.selected && this.selectedCodes.push(codeItem);
        });
      });
    });
  }

  triggerDisplayOverlay() {
    this.displayOverlay = true;
    this.determineOverlayPosition();
    setTimeout(() => {
      if (this.searchInput) {
        this.searchInput.nativeElement.focus();
      }
    }, 200);
  }

  determineOverlayPosition() {
    const windowHeight = window.innerHeight;
    const pickerTop = this.pickerInput.nativeElement.getBoundingClientRect().top;
    this.overlayTop = windowHeight - pickerTop < this.overlayHeight;
  }

  closeDisplayOverlay() {
    this.updateTouched.emit();
    this.displayOverlay = false;
  }

  setupSearch() {
    return combineLatest([this.searchSubject.pipe(debounceTime(500)), this.selectedCategory]).pipe(
      switchMap(([term, selectedCategory]) => {
        return of(this.filterCodes(term, selectedCategory));
      }),
      map((data: any) => {
        this.filteredVenomCodesCategories = data;
      })
    );
  }
  filterCodes(term: string, selectedCategory: any) {
    if (!term && !selectedCategory) {
        return [...this.allVenomCodes];
    }

    const filtered: any[] = [];
    const visitedCategories: Set<any> = new Set();
    const visitedGroups: Set<any> = new Set();

    this.allVenomCodes.forEach((item: any) => {
        const itemNameLower = item.name.toLowerCase();
        const termLower = term.toLowerCase();

        if (!selectedCategory && itemNameLower.includes(termLower) || (item.doc_id === selectedCategory?.doc_id || (has(item, 'category_doc_id') && item?.category_doc_id === selectedCategory?.doc_id)) && itemNameLower.includes(termLower)) {
            switch (item.type) {
                case 'category':
                    filtered.push(item);
                    break;
                case 'group':
                    if (!visitedCategories.has(item.category_doc_id)) {
                        const category = this.allVenomCodes.find((x: { doc_id: any; }) => x.doc_id === item.category_doc_id);
                        if (category) {
                            filtered.push(category);
                            visitedCategories.add(item.category_doc_id);
                        }
                    }
                    filtered.push(item);
                    break;
                case 'code':
                    if (!visitedCategories.has(item.category_doc_id)) {
                        const category = this.allVenomCodes.find((x: { doc_id: any; }) => x.doc_id === item.category_doc_id);
                        if (category) {
                            filtered.push(category);
                            visitedCategories.add(item.category_doc_id);
                        }
                    }
                    if (!visitedGroups.has(item.group_doc_id)) {
                        const group = this.allVenomCodes.find((x: { doc_id: any; }) => x.doc_id === item.group_doc_id);
                        if (group) {
                            filtered.push(group);
                            visitedGroups.add(item.group_doc_id);
                        }
                    }
                    filtered.push(item);
                    break;
            }
        }
    });

    return uniqBy(filtered, item => {
      if (item.type === 'code') {
        return item.doc_id + item?.group_doc_id + item?.category_doc_id; // Multiple of the same code allowed if in different groups / categories
      } else {
        return item.doc_id + '_' + item.type; // Categories and groups must be unique.
      }
    });
}

  clearSearch() {
    this.pickerSearchTerm = '';
    this.searchSubject.next('');
  }

  handleSelection(item: any) {
    item.selected = !item.selected;
    if (item.selected) {
      if (item.type === 'group') {
        this.selectedGroups.push(item);
      } else if (item.type === 'code') {
        this.selectedCodes.push(item);
      }
    } else {
      if (item.type === 'group') {
        const itemIndex = this.selectedGroups.indexOf(item);
        this.selectedGroups.splice(itemIndex, 1);
      } else if (item.type === 'code') {
        const itemIndex = this.selectedCodes.indexOf(item);
        this.selectedCodes.splice(itemIndex, 1);
      }
    }
    this.sendUpdate();
  }

  removeSelection(type: string, index: number) {
    let groupToRemove = null, codeToRemove = null;
    switch(type) {
      case 'group':
        groupToRemove = this.selectedGroups[index];
        if (groupToRemove) {
          groupToRemove.selected = false;
        }
        break;
      case 'code':
        codeToRemove = this.selectedCodes[index];
        if (codeToRemove) {
          codeToRemove.selected = false;
        }
        break;
    }

    if (type === 'group' && !groupToRemove) return;
    if (type === 'code' && !codeToRemove) return;

    switch(type) {
      case 'group':
        this.selectedGroups.splice(index, 1);
        break;
      case 'code':
        this.selectedCodes.splice(index, 1);
        break;
    }
    this.sendUpdate();
  }

  sendUpdate() {
    this.updateGroupValues.emit(this.selectedGroups?.map(x => x.doc_id) || []);
    this.updateCodeValues.emit(this.selectedCodes?.map(x => x.doc_id) || []);
    const hasValues = (this.selectedGroups?.length > 0 || this.selectedCodes?.length > 0)?true:null;
    this.updateOccurred.emit(hasValues);
  }

  filterByCategory(category: any) {
    this.categoryFilters.forEach((filter: any) => {
        if (filter !== category) {
            filter.selected = false;
        }
    });

    category.selected = !category.selected;
    this.selectedCategory.next(category?.selected ? category : null);
}
}
