import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginGuard } from 'src/shared/guards/login.guard';
import { AppointmentsComponent } from './pages/appointments/appointments.component';
import { AppointmentDetailsComponent } from './pages/appointmentdetails/appointmentdetails.component';

const routes: Routes = [
  {
    path: "appointments",
    canActivate: [LoginGuard],
    component: AppointmentsComponent,
    title: 'Joii PMS - Appointments'
  },
  {
    path: "appointments/:id",
    canActivate: [LoginGuard],
    component: AppointmentDetailsComponent,
    title: 'Joii PMS - Appointment'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppointmentsRoutingModule { }
