import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CollapseListConfig, CollapseListItem } from './collapselist.interface';
import { NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { NgIconsModule } from '@ng-icons/core';

@Component({
  selector: 'app-collapselist',
  templateUrl: './collapselist.component.html',
  standalone: true,
  imports: [NgIf, NgFor, NgStyle, NgClass, NgIconsModule]
})
export class CollapseListComponent {
  @Input() config: CollapseListConfig = {
    items: [],
  }

  @Output() rowclick = new EventEmitter<CollapseListItem>();

  rowclicked(menuItem: CollapseListItem) {
    menuItem.isCollapsed = !menuItem.isCollapsed;
    this.rowclick.emit(menuItem);
  }
}
