import { Injectable, OnDestroy } from '@angular/core';
import { venomcodeextended } from 'src/shared/models/interfaces/venom-code-extended.interface';
import { generateVenomMap } from './venom-code.worker';
import { venomcodeinternal } from '../client/client';

@Injectable({
  providedIn: 'root'
})
export class VenomCodesUtilsService {

  get worker (): any {
    // Disable web worker whilst known performance issues are resolved.
    return undefined;

    // @TODO: Uncomment this code once the performance issues are resolved.
    // if (typeof Worker !== 'undefined') {
    //   // Create a new
    //   return new Worker(new URL('./venom-code.worker', import.meta.url));
    // } else {
    //   // Web workers are not supported in this environment.
    //   // You should add a fallback so that your program still executes correctly.
    //   return undefined;
    // }
  }

  /**
   * Get the venom codes map using webworker.
   * @param list
   * @returns Map of venom codes
   */
  getVenomMap(list: venomcodeinternal.AllVenomCodesResponse | undefined): Promise<Map<string, string>> {
    const funcName = 'generateVenomMap';
    const worker = this.worker;
    if(!worker) return Promise.resolve(generateVenomMap(list));

    worker.postMessage({ funcName, list });

    return new Promise((resolve, reject) => {
      worker.onmessage = ({ data }: any) => {
        worker?.terminate;
        resolve(data);
      };
    });
  }
}
