import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, OnDestroy, Output, ViewChild } from "@angular/core";

import NetworkTest from "opentok-network-test-js";
import { ConnectivityTestResults } from "opentok-network-test-js/dist/NetworkTest/testConnectivity";
import { QualityTestResults } from "opentok-network-test-js/dist/NetworkTest/testQuality";
import { environment } from "src/environments/environment";
import { OpenTokTestSessionService } from "src/shared/services/opentokTestSession/opentokTestSession.service";
import { Subscription } from 'rxjs';
import { NgIconsModule } from "@ng-icons/core";
import { JsonPipe, NgIf } from "@angular/common";
import { ExpandableDivComponent } from "../ExpandableDiv/expandable-div.component";

@Component({
    selector: 'app-tokbox-test',
    templateUrl: './tokbox_test.component.html',
    standalone: true,
    imports: [NgIconsModule, JsonPipe, ExpandableDivComponent, NgIf]
})
export class TokboxTestComponent implements OnInit, OnDestroy, AfterViewInit {
    constructor(private cdr: ChangeDetectorRef, private openTokSessionService: OpenTokTestSessionService) {

    }


    ngAfterViewInit(): void {

        this.videoPublisher = OT.initPublisher(this.preview.nativeElement, {
            insertMode: 'after',
            fitMode: 'contain',
            width: '100%',
            height: '100%'
        }, err => console.log(err));
        this.videoPublisher.on("audioLevelUpdated", level => {
            this.audioLevel = level.audioLevel;
        });
    }
    ngOnInit(): void {
        //TODO replace these tokens and sessions with something that wont expire on us

        this.videoSubscription = this.openTokSessionService.GenerateTestOpenTokSession().subscribe(opentokSession => {
            // @ts-ignore
            this.connectionTest = new NetworkTest(OT, {
                apiKey: environment.tokboxAPIKey, // Add the API key for your OpenTok project here.
                token: opentokSession.token,
                sessionId: opentokSession.session_id
            });

            this.connectionTest.testConnectivity().then((result: any) => {
                this.connectivityTest = result
                this.connectivityOk = result.success;
                this.connectionTest?.testQuality().then((qualityResult: any) => {
                    this.qualityTest = qualityResult;
                    this.videoOk = qualityResult.video.supported ?? false
                    this.videoError = qualityResult.video.reason
                    this.audioOk = qualityResult.audio.supported ?? false
                    this.audioError = qualityResult.audio.reason;
                    this.cdr.markForCheck();
                    this.cdr.detectChanges();
                    console.log(qualityResult)
                })
            });
        })
    }

    ngOnDestroy() {
      if (this.connectionTest) {
        this.connectionTest.stop();
        this.connectionTest = null;
      }
      this.videoPublisher.destroy();
      this.videoSubscription.unsubscribe();
    }


    connectivityOk?: boolean;
    videoOk?: boolean;
    videoError?: string;
    audioOk?: boolean;
    audioError?: string
    qualityTest?: QualityTestResults;
    connectivityTest?: ConnectivityTestResults;
    audioLevel = 0;
    videoSubscription: Subscription = new Subscription;
    videoPublisher: OT.Publisher = new OT.Publisher();
    connectionTest: NetworkTest | null = null;
    @ViewChild('preview') preview!: ElementRef;

    @Input()
    proceedButtonText!: string;

    @Output()
    proceedClicked = new EventEmitter<boolean>();



    proceed() {
        this.proceedClicked.emit(true);
    }

}
