<section *ngIf="petDetails" class="w-full">
    <article class="w-1/2 inline-block align-top">
        <p><strong>Name:</strong> {{petDetails.name}}</p>
        <p><strong>Species:</strong> {{petDetails.species}}</p>
        <p><strong>Gender:</strong> {{petDetails.gender}}</p>
        <p><strong>Breed:</strong>
            <span *ngIf="petDetails.breed">&nbsp;{{petDetails.breed.name}}</span>
            <span *ngIf="!petDetails.breed">&nbsp;None Set</span>
        </p>
        <div class="profile-image-holder">
            <span *ngIf="petDetails.photo_url"><img [src]="petDetails.photo_url" [title]="petDetails.name" /></span>
            <span class="bg-cyan-100 text-cyan-800 w-full h-full block text-center align-middle text-2xl leading-9" [title]="petDetails.name">
                {{petDetails.name[0]}}
            </span>
        </div>
    </article>
    <article class="w-1/2 inline-block align-top">
      <p>
        <span *ngIf="petDetails.age"><strong>Age:</strong> {{petDetails.age.years}} year<span *ngIf="petDetails.age.years > 1">s</span>
        <span *ngIf="petDetails.age.months > 0">, {{petDetails.age.months}} month<span *ngIf="petDetails.age.months > 1">s</span>
        </span>
        </span>
      </p>
        <p><strong>Weight:&nbsp;</strong><span *ngIf="petDetails.weight">{{petDetails.weight.kg}}(kg)</span><span *ngIf="!petDetails.weight">Not Set</span></p>
        <p><strong>Neutered:&nbsp;</strong>
            <span class="text-lg relative top-1">
                <span *ngIf="petDetails.neutered" title="Neutered"><ng-icon name="ionCheckmarkCircleOutline"></ng-icon></span>
                <span *ngIf="!petDetails.neutered" title="Not Neutered"><ng-icon name="ionCloseCircleOutline"></ng-icon></span>
            </span>
        </p>
    </article>
</section>
