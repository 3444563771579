import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { DragAndDropEventData, DragAndDropListConfig, DragAndDropListItem } from './drag-and-drop-list.interface';
import { Router } from '@angular/router';
import { NgxFloatUiModule, NgxFloatUiPlacements } from 'ngx-float-ui';
import { CdkDragDrop, DragDropModule } from '@angular/cdk/drag-drop';
import { LucideAngularModule } from 'lucide-angular';

@Component({
  selector: 'app-drag-and-drop-list',
  templateUrl: './drag-and-drop-list.component.html',
  styleUrl: './drag-and-drop-list.component.scss',
  standalone: true,
  imports: [DragDropModule, LucideAngularModule, NgxFloatUiModule]
})
export class DragAndDropListComponent {
  isDraggable: boolean = false;
  currentlyDraggedIndex: number = 0;
  tooltipPlacementEnum = NgxFloatUiPlacements;

  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef) { }

  @Input() config: DragAndDropListConfig = {
    items: []
  }

  @Output() rowclicked = new EventEmitter<DragAndDropListItem>();
  @Output() dropped = new EventEmitter<DragAndDropEventData>();

  onDrop(event: CdkDragDrop<DragAndDropListItem[]>) {
    this.dropped.emit({
      dragFrom: this.config.items[event.previousIndex],
      dragTo: this.config.items[event.currentIndex]
    });
  }

  onMouseEnterDragIcon($event: MouseEvent) {
    this.isDraggable = true;
  }

  onMouseLeaveDragIcon($event: MouseEvent) {
    this.isDraggable = false;
  }

  onClick(item: DragAndDropListItem): void {
    this.rowclicked.emit(item);
  }
}
