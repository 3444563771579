import { inject, Injectable, signal, Signal } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { Loader } from "@googlemaps/js-api-loader"
import { BehaviorSubject, Observable, Subject, catchError, map, of, switchMap, tap } from "rxjs";
import { environment } from "src/environments/environment";
import { PartnerPracticeService } from "../partner-practices.service/partner-practice.service";
import { entities } from "../client/client";

@Injectable({
    providedIn: 'root'
})
export class GooglePlacesService {

    private partnerPracticeService = inject(PartnerPracticeService)
    private cachedPartnerPractices = new BehaviorSubject<entities.PartnerPractice[] | undefined>(undefined); // This is the cached venom codes
    private cacheInitialized = signal<boolean>(false);
    private fetchingData = signal<boolean>(false); // This is a signal that will be used to indicate that the data is being fetched
    private requestQueue: Subject<boolean>[] = [];
    private cachedLocationLookups = new BehaviorSubject<{ [address: string]: entities.PartnerPractice | undefined }>({})

    private practicesCache(): Observable<entities.PartnerPractice[] | undefined> {
        // The cache is currently being loaded
        if (this.fetchingData()) {
            const newRequest = new Subject<boolean>();
            this.requestQueue.push(newRequest);
            return newRequest.asObservable().pipe(
                switchMap(() => this.cachedData())
            );
        }
        // the cache is already initialised
        if (this.cacheInitialized())
            return this.cachedData()

        this.fetchingData.set(true)
        return this.partnerPracticeService.listPartnerPractices().pipe(map(resp => resp.practices), tap(resp => {
            this.cachedPartnerPractices.next(resp)
            this.cacheInitialized.set(true)
            this.fetchingData.set(false)
            this.processRequestQueue()
        }, catchError(error => {
            console.error('Error fetching partner practices:', error);
            this.cachedPartnerPractices.next(undefined);
            this.cacheInitialized.set(false);
            this.processRequestQueue();
            this.fetchingData.set(false)
            return of(undefined);
        })
        ))

    }

    private placesService: Observable<google.maps.places.PlacesService | undefined> = new Observable(observer => {
        const loader = new Loader({
            apiKey: environment.firebase.apiKey,
            version: "weekly"
        });

        loader.importLibrary("places").then(lib => {
            observer.next(new lib.PlacesService(document.createElement('div')))
        })
    })

    private goemetry: Signal<google.maps.GeometryLibrary | undefined> = toSignal(new Observable(observer => {
        const loader = new Loader({
            apiKey: environment.firebase.apiKey,
            version: "weekly"
        });

        loader.importLibrary("geometry").then(lib => {
            observer.next(lib)
        })
    }))

    GetVetsForAddress(address: string): Observable<searchResult> {

        return this.placesService.pipe(switchMap((places) => new Observable<searchResult>(observer => {
            places?.textSearch({
                query: address,
                type: "veterinary_care"
            }, (resp, status, paging) => {
                if (status === 'OK') {
                    observer.next({
                        results: resp,
                        paging: paging
                    })
                }
                else {
                    observer.error(status)
                }
            })
        })))
    }

    getPartnerPracticeWithin25Miles(address: string): Observable<entities.PartnerPractice | undefined> {
        if (address == "")
            return of(undefined);
        return this.cachedLocationLookups.pipe(switchMap(cache => {
            if (address in cache) {
                return of(cache[address])
            }

            return this.practicesCache().pipe(switchMap(pp => this.placesService.pipe(switchMap((places) => new Observable<entities.PartnerPractice>(observer => {
                places!.textSearch({
                    query: address
                }, (resp, status) => {
                    if (status === 'OK') {
                        var practice = this.getFirstPracticeWithin25Miles(resp![0], pp!)
                        cache[address] = practice
                        this.cachedLocationLookups.next(cache)
                        observer.next(practice)
                    }
                    else {
                        observer.error(status)
                    }
                })
            })))))
        }))



    }
    getFirstPracticeWithin25Miles(resp: google.maps.places.PlaceResult, partnerPractices: entities.PartnerPractice[]): entities.PartnerPractice | undefined {
        const twentyFiveMilesInMetres = 40233.6


        let praticesInRange = partnerPractices.filter(value => {
            return this.goemetry()!.spherical.computeDistanceBetween(resp.geometry!.location!, {
                lat: Number.parseFloat(value.lat), lng: Number.parseFloat(value.long)
            }) <= twentyFiveMilesInMetres
        }
        )
        if (praticesInRange?.length)
            return praticesInRange[0]
        else
            return undefined
    }

    private processRequestQueue(): void {
        while (this.requestQueue.length > 0) {
            const request = this.requestQueue.shift();
            if (request) {
                request.next(true);
            }
        }
    }
    private cachedData(): Observable<entities.PartnerPractice[] | undefined> {
        return this.cachedPartnerPractices.asObservable()
    }
}

export interface searchResult {
    results: google.maps.places.PlaceResult[] | null,
    paging: google.maps.places.PlaceSearchPagination | null
}


