import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-translation',
  templateUrl: './translation.component.html',
  styleUrls: ['./translation.component.scss'],
  standalone: true,
  imports: [NgIf, FormsModule]
})
export class TranslationComponent {

  selectedLanguage = 'en';
  excludedRoutes = ['login', 'register', 'forgot-password', 'forgottenpassword', 'tenants'];

  constructor(private translate: TranslateService, private router: Router) {
    const savedLang = localStorage.getItem('lang');
    if (savedLang) this.selectedLanguage = savedLang;
  }

  switchLanguage($event: Event) {
    const selectElement = $event.target as HTMLSelectElement;
    const language = selectElement.value;
    this.translate.use(language);
    localStorage.setItem('lang', language);
  }

  isExcludedPage(): boolean {
    const currentRoute = this.router.url;;
    return this.excludedRoutes.some(route => currentRoute?.includes(route));
  }

}

