import { Injectable, signal } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class ImageCorouselDataService {
    images = signal<string[]>([]);
    showCarousel = signal(false)

    updateImages(images: string[]) {
        this.images.update(() => images)
    }

    showModal() {
        this.showCarousel.update(() => true)
    }
    hideModal() {
        this.showCarousel.update(() => false)
    }
}