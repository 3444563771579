import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DetailsListConfig, DetailsListItem, DetailsListItemType } from './detailslist.interface';
import { DatePipe, DecimalPipe, NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'app-detailslist',
  templateUrl: './detailslist.component.html',
  standalone: true,
  imports: [NgFor, NgIf, DecimalPipe, DatePipe]
})

export class DetailsListComponent {

  constructor(private router: Router) { }

  DetailsListItemType = DetailsListItemType;

  @Input() config: DetailsListConfig = {
    items: [],
  }

  @Output() rowclick = new EventEmitter<DetailsListItem>();

  // rowclicked(menuItem: DetailsListItem) { }

}
