<div class="sidebar max-h-screen overflow-y-auto px-4 py-10 shadow {{styleClass()}}"
  [ngClass]="{'w-[400px]': !wide(), 'w-1/2': wide(), 'dark':darkModeService.darkModeOn()}"
  [@slideInOut]="animationState()" [class.left]="position() === 'left'" [class.right]="position() === 'right'">
  <div #header class="relative">
    <ng-content select="[slot=header]"></ng-content>
    <ng-icon *ngIf="!hideClose()" (click)="closeSidebar()" name="ionCloseOutline" size="1.5rem"
      class="absolute top-2 right-2 opacity-50 hover:opacity-75 cursor-pointer transition-opacity duration-300"></ng-icon>
  </div>

  <!-- Sidebar content goes here -->
  <ng-content select="[slot=content]"></ng-content>
  <ng-content select="[slot=footer]"></ng-content>
</div>