export interface DetailsListConfig {
  items: DetailsListItem[];
}

export interface DetailsListItem {
  id: string;
  title: string;
  content: any;
  type: DetailsListItemType;
}

export enum DetailsListItemType {
  Text = 'text',
  Date = 'date',
  Decimal = 'decimal',
  Image = 'image',
}
