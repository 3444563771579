import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { FileExtensionPipe } from 'src/shared/pipes/file-extension/file-extension.pipe';
import { ImageCorouselDataService } from 'src/shared/services/imageCarousel.data.service/image-carousel.data.service';
import { DialogSimpleComponent } from '../../dialog-simple/dialog-simple.component';

@Component({
  selector: 'app-image-carousel-modal',
  templateUrl: './image-carousel-modal.component.html',
  standalone: true,
  imports: [FileExtensionPipe, NgClass, DialogSimpleComponent]
})

export class ImageCorouselModalComponent {

  imageCarouselDataService = inject(ImageCorouselDataService)


  selectedItem = 0;

  next() {
    this.selectedItem++

    if (this.selectedItem >= this.imageCarouselDataService.images().length) {
      this.selectedItem = 0
    }
  }
  previous() {
    this.selectedItem--
    if (this.selectedItem < 0) {
      this.selectedItem = this.imageCarouselDataService.images().length - 1
    }
  }
  selectItem(item: number) {
    this.selectedItem = item
  }

  close() {
    this.imageCarouselDataService.hideModal()
  }

}
