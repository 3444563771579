import { inject, Injectable } from '@angular/core';
import { ClientService } from "../client/client.service";
import { Observable, switchMap } from "rxjs";
import { accountinternal, entities } from '../client/client';

@Injectable({
  providedIn: 'root'
})
export class SignUrlService {
    clientService = inject(ClientService);

    public signUrl(signUrlsRequest: accountinternal.SignUrlRequest): Observable<accountinternal.SignUrlResponse> {
        return this.clientService.client.pipe(switchMap(client => client.accountinternal.SignURL(signUrlsRequest)));
    }
}