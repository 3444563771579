import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, effect, forwardRef, inject, signal } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import Editor from '@toast-ui/editor';
import { trim, replace, isString } from 'lodash';
import { DarkModeService } from 'src/shared/services/darkmode/darkmode_service';

@Component({
  selector: 'app-rich-text',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './rich-text.component.html',
  styleUrl: './rich-text.component.scss',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => RichTextComponent),
    multi: true
  }],
})
export class RichTextComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() id: string = '';
  @Input() initialEditType: 'markdown' | 'wysiwyg' = 'wysiwyg';
  @Input() previewStyle: 'vertical' | 'tab' = 'tab';
  @Input() height!: string;
  @Input() value!: string;
  @Input() outputType: 'markdown' | 'html' = 'markdown';
  @Input() showToolbar: boolean = true;
  @Input() styleClass: string = '';
  @Output() touched = new EventEmitter<void>();
  private updatingEditor = signal<boolean>(false);

  private editor!: Editor;
  focused = false;
  darkModeService: DarkModeService = inject(DarkModeService)

  initalised = false;
  @ViewChild('tuiEditor', { static: true }) editorElement!: ElementRef;

  onChange: any = (value: any) => {
  };
  onTouched: any = () => {
  };

  ngOnInit() {
    this.editor = new Editor({
      el: this.editorElement?.nativeElement,
      initialValue: this.value,
      initialEditType: this.initialEditType,
      // previewStyle: this.previewStyle,
      theme: this.darkModeService.darkModeOn() ? 'dark' : '',
      toolbarItems: this.showToolbar ? [
        ['heading', 'bold', 'italic', 'strike'],
        ['ul', 'ol'],
        ['link'],
      ] : undefined,

      height: this.height,
      events: ({
        load: () => {
          this.initalised = true;
        },
        change: ($event: any) => {
          if (this.updatingEditor()) return;
          this.onEditorValueChange();
        },
        focus: () => {
          this.onTouched();
          this.touched.emit();
          this.focused = true;
        },
        blur: () => {
          this.onTouched();
          this.touched.emit();
          this.focused = false;
        },
      } as any),
      autofocus: false,
    });
    this.editor?.blur();
  }

  onEditorValueChange() {
    const value = this.getEditorValue();
    if (value !== this.value && isString(value)) {
      this.writeValue(value, true);
      this.updateChanges();
    }
  }

  getEditorValue() {
    return this.outputType === 'html' ? this.editor?.getHTML() || '' : this.editor?.getMarkdown() || '';
  }

  getEditorHTML() {
    return this.editor?.getHTML() || '';
  }

  getEditorMarkdown() {
    return this.editor?.getMarkdown() || '';
  }

  isHTML(str: string): boolean {
    const htmlRegex = /<[^>]+>/g;
    return htmlRegex.test(str);
  }

  setEditorValue(value: string) {
    if (!isString(value)) return;

    this.isHTML(value) ? this.editor?.setHTML(value, this.focused) : this.editor?.setMarkdown(value, this.focused);
  }

  updateChanges(): void {
    this.onChange(this.value);
  }
  writeValue(value: string, skipEditor = false): void {
    if (this.value !== value && !skipEditor) {
      this.updatingEditor.set(true);
      this.setEditorValue(value);
    };
    this.value = value;
    this.updatingEditor.set(false);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public getPlainText(): string {
    // Get the HTML content from the ElementRef
    if (!this.editor || !this.editorElement || !this.editorElement?.nativeElement) return '';

    try {
      const htmlContent = this.editor?.getHTML() || '';

      if (!htmlContent) return '';

      // Convert HTML to plain text using DOMParser
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlContent, 'text/html');

      // Function to traverse DOM tree and extract text content with spaces
      const getTextWithSpaces = (node: Node): string => {
        let text = '';
        node.childNodes.forEach(childNode => {
          if (childNode.nodeType === Node.TEXT_NODE) {
            text += childNode.textContent;
          } else {
            text += getTextWithSpaces(childNode);
            // Add space after each element (except for the last one)
            if (childNode.nextSibling) {
              text += ' ';
            }
          }
        });
        return text;
      };

      const plainText = getTextWithSpaces(doc.body);
      const plainTextTrimmed = trim(replace(plainText, /\s+/g, ' '));
      return plainTextTrimmed;
    } catch (error) {
      console.log('Error getting plain text', error);
      return '';
    }
  }

  ngOnDestroy(): void {
    this.editor?.destroy();
  }
}
