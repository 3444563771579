import { Component} from '@angular/core';

@Component({
  selector: 'app-divider',
  standalone: true,
  template: `
  <div class="relative hidden sm:block lg:my-16 md:my-12">
    <div class="absolute inset-0 flex items-center" aria-hidden="true">
      <div class="w-full border-t border-gray-100"></div>
    </div>
    <div class="relative flex justify-center">
    </div>
  </div>
  `
})

export class DividerComponent {
  
}
