export interface StackedListConfig {
  items: StackedListItem[];
}

export interface StackedListItem {
  id: string,
  doc_id?: string,
  title: string,
  description: string,
  image: string,
  type: StackedListItemType;
  badges?: StackedListBadgeConfig[];
  actions?: StackedListAction[];
}

export interface StackedListBadgeConfig {
  name: string,
  color: string
}

export interface StackedListAction {
  icon: string; // Lucide Icon
  classes: string;
  handler: (item: StackedListItem, event: MouseEvent) => void
}


export enum StackedListItemType {
  Path = 'path',
  Callback = 'callback'
}
