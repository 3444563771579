/// <reference lib="webworker" />

import { cloneDeep } from "lodash";

addEventListener('message', ({ data }) => {
  if (!data) return;
  let response = null;

  switch(data?.funcName) {
    case 'generateVenomMap':
      response = generateVenomMap(data.list);
      break;  
    default:
      break;
  }

  postMessage(response);
});

export function generateVenomMap(list: any): Map<string, string> {
  const venomMap = new Map<string, string>();
  if (!list) return venomMap;

  list?.categories?.forEach((category: { groups: any[]; }) => {
    if (Array.isArray(category.groups)) {
      category.groups.forEach(group => {
        venomMap.set(group.doc_id, group.name);
        if (Array.isArray(group.venom_codes)) {
          group.venom_codes.forEach((venom: { doc_id: any; term_name: any; }) => {
            const { doc_id, term_name } = venom;
            venomMap.set(doc_id, term_name);
          });
        }
      });
    }
  });
  return venomMap;
}


