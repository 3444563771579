import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NgIconsModule } from '@ng-icons/core';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  standalone: true,
  imports: [NgIf, NgIconsModule],
})
export class AccordionComponent {
  @Input() title: string = '';
  @Input() isExpanded: boolean = false;

  Expand() {
    this.isExpanded = !this.isExpanded;
  }
}
