import { DatePipe, NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Injector, Input, OnInit, Optional, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormGroup, FormGroupDirective, FormsModule, NG_VALUE_ACCESSOR, NgControl, ValidationErrors } from '@angular/forms';
import { DateFormats } from 'src/portal/shared/models/enums/date-format.enum';
import { LabelComponent } from '../forms/label/label.component';
import { ValidationComponent } from '../forms/validation/validation.component';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrl: './date-picker.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DatePickerComponent),
    },
    DatePipe
  ],
  standalone: true,
  imports: [LabelComponent, NgIf, NgFor, ValidationComponent, FormsModule]
})
export class DatePickerComponent implements ControlValueAccessor, OnInit {

  @Input() label!: string;
  @Input() name!: string;
  @Input() maximumYears!: number;
  @Input() tooltipMessage!: string;
  @Input() errormessage!: string;
  @Input() form!: FormGroup;
  @Input() currentFormat: DateFormats = DateFormats.DayMonthYear;
  @Output() dateSelection = new EventEmitter<string>();
  
  value!: any;
  errors!: ValidationErrors | null | undefined;
  disabled!: boolean;
  formControl!: NgControl | null;
  dateFormats = DateFormats;


  
  days: number[] = [];
  months: number[] = [];
  years: number[] = [];

  selectedDay: string = 'DD';
  selectedMonth: string = 'MM';
  selectedYear: string = 'YYYY';

  constructor(
    private datePipe: DatePipe,
    private injector: Injector,
    @Optional() public formDirective: FormGroupDirective
  ) {}

  ngOnInit(): void {
    this.days = [];
    this.months = Array.from({ length: 12 }, (_, i) => i + 1);
    const currentYear = new Date().getFullYear();
    this.years = Array.from({ length: this.maximumYears === undefined ? 100 : this.maximumYears }, (_, i) => currentYear - i);
    
    this.updateDays();
    this.formControl = this.ngControl;
    if (this.formControl) {
      this.formControl.valueAccessor = this;
    }
  }

  onChange: any = (value: any) => {
  };

  onTouched: any = () => {
  };

  get submitted() {
    return this.formDirective ? this.formDirective.submitted : false;
  }

  get ngControl(): NgControl | null {
    return this.injector.get(NgControl, null);
  }

  writeValue(value: any): void {
    if (value) {
      const dateParts = value.split('-');
      this.selectedYear = dateParts[0];
      this.selectedMonth = dateParts[1];
      this.selectedDay = dateParts[2];
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  get showDaySelector(): boolean {
    return this.currentFormat === DateFormats.DayMonthYear;
  }
  
  get showMonthSelector(): boolean {
    return this.currentFormat === DateFormats.DayMonthYear|| this.currentFormat === DateFormats.MonthYear;
  }

  updateDays(): void {
    const daysInMonth = this.getDaysInMonth(
      +this.selectedYear,
      +this.selectedMonth
    );
    this.days = Array.from({ length: daysInMonth }, (_, i) => i + 1);
    if (this.selectedDay !== 'DD' && this.selectedMonth !== 'MM' && this.selectedYear !== 'YYYY') {
      this.dateSelection.emit(
        this.datePipe.transform(
          new Date(+this.selectedYear, +this.selectedMonth - 1, +this.selectedDay), this.currentFormat) ?? '');
          this.emitSelectedDate();
    }
  }

  private emitSelectedDate(): void {
    const selectedDate = new Date(+this.selectedYear, +this.selectedMonth - 1, +this.selectedDay);
    const formattedDate = this.datePipe.transform(selectedDate, 'yyyy-MM-dd');
    this.onChange(formattedDate);
    this.dateSelection.emit(formattedDate ?? '');
  }

  isLeapYear(year: number): boolean {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  }

  getDaysInMonth(year: number, month: number): number {
    if (month == 2) {
      return this.isLeapYear(year) ? 29 : 28;
    } else if (['4', '6', '9', '11'].includes(`${month}`)) {
      return 30;
    } else {
      return 31;
    }
  }
}
