<section class="relative">
  <app-label *ngIf="label" [label]="label"></app-label>
  <div class="relative multi-select-holder z-2" #pickerInput>
    <div
      class="list-wrapper bg-gray-50 dark:bg-slate-800 block w-full rounded-md border-0 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300">
      <span class="joii-chip-place-holder text-gray-400  text-sm"
        *ngIf="(selectionType === 'multi' && selectedItems.length === 0) || (selectionType === 'single' && !selectedItem)">
        <span>Please select</span>
      </span>
      <div class="list-bg" (click)="toggleDisplayOverlay()"></div>
      <div class="joii-chip-holder" *ngIf="selectionType === 'multi' && selectedItems.length > 0">
        <ng-container *ngFor="let item of selectedItems; index as i;">
          <span class="joii-chip text-sm text-white bg-blue-700 rounded-sm">
            {{item.name}}
            <ng-icon name="ionCloseOutline" (click)="clearMultiSelection(item, i)"></ng-icon>
          </span>
        </ng-container>
      </div>
      <div class="joii-selection" *ngIf="selectionType === 'single' && selectedItem">
        <ng-icon name="ionCloseOutline" title="Clear selection"
          class="joii-single-selection-clear inline-block mr-2 cursor-pointer"
          (click)="clearSingleSelection()"></ng-icon>
        <span>{{selectedItem.name}}</span>
      </div>
    </div>
    <span class="absolute picker-trigger-icon" (click)="toggleDisplayOverlay()">
      <ng-icon *ngIf="!displayOverlay" name="ionChevronDownOutline"></ng-icon>
      <ng-icon *ngIf="displayOverlay" name="ionChevronUpOutline"></ng-icon>
    </span>
  </div>
  <section [ngClass]="{'hidden':!displayOverlay, 'top': overlayTop}"
    class="picker-overlay mt-1 bg-white dark:bg-gray-700  w-full shadow-md">
    <header class="p-2 px-3 bg-slate-100 dark:bg-gray-700">
      <input #searchInput [(ngModel)]="pickerSearchTerm" (keyup)="handleSearch($event)" placeholder="Search"
        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
      <span class="search-icon" *ngIf="!pickerSearchTerm">
        <ng-icon name="ionSearchOutline"></ng-icon>
      </span>
      <span class="clear-icon" *ngIf="pickerSearchTerm">
        <ng-icon name="ionCloseOutline" (click)="clearSearch()"></ng-icon>
      </span>
    </header>
    <ng-container *ngIf="filteredSelectableOptions.length > 0 && groupOptionsName && selectionType === 'single'">
      <section class="main-list">
        <div class="p-3">
          <div class="text-gray-500 text-sm font-medium font-['Inter'] leading-tight tracking-tight">Filter category
          </div>
          <div class="joii-chip-holder flex flex-wrap">
            @for (group of selectableOptions; track $index) {
            <span
              class="p-1.5 mr-2 mt-2 rounded-md border border-blue-400 justify-start items-center gap-px flex text-xs font-medium font-['Inter'] leading-none tracking-tight cursor-pointer"
              [class.border-2]="group.group_name === 'Body Maps'"
              [ngClass]="{'bg-blue-500 text-white':selectedGroup == group.group_name, 'text-blue-900 dark:text-white':selectedGroup != group.name}"
              (click)="groupSelect(group.group_name)">
              <span *ngIf="group.group_name !== 'Body Maps'">{{group.group_name}}</span>
              <span *ngIf="group.group_name === 'Body Maps'"><strong>{{group.group_name}}</strong></span>
            </span>
            }
          </div>
        </div>
        <article *ngFor="let rootOption of filteredSelectableOptions; trackBy:identify">
          <div [ngClass]="{'hidden': selectedGroup !== rootOption.group_name && selectedGroup}" class="p-2">
            <span class="top-0 bg-white dark:bg-gray-700 block"><strong>{{rootOption.group_name}}</strong></span>
            <ng-container *ngIf="rootOption[groupOptionsName]">
              <div *ngFor="let subOption of rootOption[groupOptionsName]; trackBy:identify" class="pl-3 cursor-pointer"
                (click)="handleSingleSelection(subOption)">
                <span>{{subOption.name}}</span>
              </div>
            </ng-container>
          </div>
        </article>
      </section>
    </ng-container>
    <ng-container *ngIf="filteredSelectableOptions.length > 0 && groupOptionsName && selectionType === 'multi'">
      <section class="main-list">
        <div class="joii-chip-holder flex flex-wrap">
          @for (group of selectableOptions; track $index) {
          <span
            class="p-1.5 mr-2 mt-2 rounded-md border border-blue-400 justify-start items-center gap-px flex text-xs font-medium font-['Inter'] leading-none tracking-tight cursor-pointer"
            [class.border-2]="group.group_name === 'Body Maps'"
            [ngClass]="{'bg-blue-500 text-white':selectedGroup == group.group_name, 'text-blue-900':selectedGroup != group.name}"
            (click)="groupSelect(group.group_name)">
            <span *ngIf="group.group_name !== 'Body Maps'">{{group.group_name}}</span>
            <span *ngIf="group.group_name === 'Body Maps'"><strong>{{group.group_name}}</strong></span>
          </span>
          }
        </div>
        <article *ngFor="let rootOption of filteredSelectableOptions; trackBy:identify" class="p-3">
          <span><strong>{{rootOption.group_name}}</strong></span>
          <ng-container *ngIf="rootOption[groupOptionsName]">
            <div *ngFor="let subOption of rootOption[groupOptionsName]; trackBy:identify" class="pl-3 cursor-pointer">
              <div class="relative flex items-start">
                <div class="flex h-6 items-center">
                  <input [id]="subOption.name" [checked]="subOption.is_selected"
                    (click)="handleMultiSelection(subOption)" aria-describedby="checkbox input" type="checkbox"
                    class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600">
                </div>
                <div class="ml-3 text-sm leading-6">
                  <label for="{{subOption.name}}" class="font-medium text-gray-900">{{subOption.name}}</label>
                </div>
              </div>
            </div>
          </ng-container>
        </article>
      </section>
    </ng-container>
    <ng-container *ngIf="filteredSelectableOptions.length === 0">
      <article class="p-2">
        <p>No results</p>
      </article>
    </ng-container>
  </section>
  <section *ngIf="displayOverlay" class="picker-bg" (click)="closeDisplayOverlay()"></section>
</section>