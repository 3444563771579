<div class="mb-1 title">
    <app-label [label]="label" [label]="label"></app-label>
</div>
<div class="flex">
    <div class="wrapper mr-4">
        <select *ngIf="showDaySelector" [(ngModel)]="selectedDay" (change)="updateDays()">
          <option *ngFor="let day of days" [value]="day">{{ day }}</option>
        </select>
        <div *ngIf="showDaySelector" class="separator"></div>
      
        <select *ngIf="showMonthSelector" [(ngModel)]="selectedMonth" (change)="updateDays()">
          <option *ngFor="let month of months" [value]="month">{{ month }}</option>
        </select>
        <div *ngIf="showMonthSelector" class="separator"></div>
            <select class="" [(ngModel)]="selectedYear" (change)="updateDays()">
              <option *ngFor="let year of years" [value]="year">{{ year }}</option>
            </select>
      </div>
      <div class="wrapper">
          <select *ngIf="showDaySelector" [(ngModel)]="selectedHour" (change)="updateDays()">
              <option *ngFor="let hour of hours" [value]="hour">{{ hour | number: '2.0-0'  }}</option>
            </select>
            <div *ngIf="showDaySelector" class="separator"></div>
          
            <select *ngIf="showMonthSelector" [(ngModel)]="selectedMinutes" (change)="updateDays()">
              <option *ngFor="let minute of minutes" [value]="minute">{{ minute | number: '2.0-0'  }}</option>
            </select>
      </div>
</div>
<app-validation *ngIf="formControl || errormessage" [control]="formControl" [submitted]="submitted" [errormessage]="errormessage" [name]="name"></app-validation>