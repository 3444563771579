
<div class="relative">
  <div (click)="clicked()" class="divide-gray-100 collapse-header hover:bg-gray-50 cursor-pointer justify-between flex gap-x-6 py-5">
    <div class="flex min-w-0 gap-x-4">
      <div class="min-w-0 flex-auto">
        <h1 class="text-sm font-semibold leading-6 text-gray-900" data-automationid="collapsed-list-row-title">
          {{title}}
        </h1>
      </div>
    </div>
    <div class="flex shrink-0 items-center gap-x-4">
      @for(action of actions; track action) {
        <lucide-angular (click)="action.handler($event, docId ?? '')" [name]="action.icon" [ngClass]="action?.classes?.length || 0 > 0 ? action.classes : actionClasses"></lucide-angular>
      }
      <ng-icon *ngIf="this.isCollapsed" class="h-5 w-5 flex-none text-gray-400" name="ionChevronDown" size="1.0rem" data-automationid="collapse-down-button"></ng-icon>
      <ng-icon *ngIf="!this.isCollapsed" class="h-5 w-5 flex-none text-gray-400" name="ionChevronUp" size="1.0rem" data-automationid="collapse-up-button"></ng-icon>
    </div>
  </div>
  <div *ngIf="this.isCollapsed" data-automationid="collapsed-text-container" class="mt-1">
    <ng-content></ng-content>
  </div>
</div>
