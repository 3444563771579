export interface HorizontalPillsConfig {
  items: HorizontalPillsConfigItem[];
}

export interface HorizontalPillsConfigItem {
  id: string,
  title: string,
  active: boolean,
  type: HorizontalPillsConfigItemType,
  data: any
}


export enum HorizontalPillsConfigItemType {
  Path = 'path',
  Callback = 'callback'
}