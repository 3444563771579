import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'staffName',
  standalone: true
})
export class StaffNamePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    const maxLength: number = 30;
    let slicedValue = value;
    if (value.length >= maxLength) {
      slicedValue = value.slice(0, maxLength) + '...';
    }
    return slicedValue;
  }

}
