import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgIf, NgFor, DatePipe, UpperCasePipe } from '@angular/common';

import { RichTextViewerComponent } from '../forms/rich-text/rich-text-viewer/rich-text-viewer.component';
import { OwnerObservationsComponent } from '../owner-observations/owner-observations.component';
import { FormioSubmissionComponent } from '../formio_submission/formio_submission.component';
import { AssessmentDetailsComponent } from '../assessment-details/assessment-details.component';
import { StaffNamePipe } from '../../pipes/staff-name/staff-name.pipe';
import { VenomCodeExtractorPipe } from '../../pipes/venom-code-extractor/venom-code-extractor.pipe';
import { HealthCheckDataComponent } from "../health-check-data/health-check-data.component";

@Component({
  selector: 'app-consultation-history-list',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    DatePipe,
    UpperCasePipe,
    RichTextViewerComponent,
    OwnerObservationsComponent,
    FormioSubmissionComponent,
    AssessmentDetailsComponent,
    StaffNamePipe,
    VenomCodeExtractorPipe,
    HealthCheckDataComponent
],
  templateUrl: './consultation-history-list.component.html',
  styleUrl: './consultation-history-list.component.scss'
})
export class ConsultationHistoryListComponent {

  @Input() consultationHistory: Array<any> = [];
  @Input() paginationConfig: any;
  @Input() loadingMore: boolean = false;
  @Output() fetchMore: EventEmitter<any> = new EventEmitter();

  toggleExpanded(consultation: any) {
    consultation.expanded = !consultation.expanded;
  }

  fetchMoreNow() {
    this.fetchMore.emit();
  }
}
