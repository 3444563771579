import {Component, forwardRef, Injector, Input, OnChanges, OnInit, Optional, SimpleChanges} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ControlValueAccessor,
  FormGroupDirective,
  FormsModule,
  NG_VALUE_ACCESSOR,
  NgControl,
  ValidationErrors
} from "@angular/forms";
import {isEqual} from "lodash";
import {ValidationComponent} from "../validation/validation.component";

@Component({
  selector: 'app-checkbox',
  standalone: true,
  imports: [CommonModule, FormsModule, ValidationComponent],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CheckboxComponent),
    }
  ],
  templateUrl: './checkbox.component.html',
  styleUrl: './checkbox.component.scss'
})
export class CheckboxComponent implements ControlValueAccessor, OnInit, OnChanges {
  @Input() label!: string;
  @Input() name!: string;
  @Input() description!: string;
  @Input() placeholder!: string;
  @Input() type: string = "text";
  @Input() readonly: boolean = false;
  @Input() span!: number;
  @Input() errormessage!: string;

  value!: boolean | null;
  errors!: ValidationErrors | null | undefined;
  disabled = false;
  onChange: any = (value: any) => {
  };
  onTouched: any = () => {
  };

  formControl!: NgControl | null;

  constructor(
    private injector: Injector,
    @Optional() private formDirective: FormGroupDirective
  ) {}

  get ngControl(): NgControl | null {
    return this.injector.get(NgControl, null);
  }

  get submitted() {
    return this.formDirective ? this.formDirective.submitted : false;
  }

  ngOnInit() {
    this.formControl = this.ngControl;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.formControl && !isEqual(changes?.['value']?.previousValue, changes?.['value']?.currentValue)) {
      this.value = this.ngControl?.value;
      this.onChange(this.value);
      this.onTouched();
    }
  }

  writeValue(value: boolean): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  onInputChange(newValue: boolean) {
    if (newValue !== this.value) {
      this.value = newValue;
      this.onChange(this.value);
    }
    this.onTouched();
  }
}
