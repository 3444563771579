import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { uuid } from "src/shared/services/client/client";

@Component({
    selector: 'app-submission-history',
    templateUrl: './submission-history.component.html'
})
export class SubmissionHistoryComponent {
    id!: uuid.UUID;
    constructor(private route: ActivatedRoute,
        private router: Router) {

    }
    ngOnInit(): void {
        this.route.params.subscribe(params => {
            this.id = params['id'];
        });
    }

}