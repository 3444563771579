import { Component } from '@angular/core';

@Component({
  selector: 'app-loader',
  styleUrls: ['./loader.component.scss'],
  templateUrl: './loader.component.html',
  standalone: true
})

export class LoaderComponent {
}
