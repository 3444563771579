import { Component, Input, Output, EventEmitter, OnInit, ViewChild, ElementRef } from '@angular/core';
import { cloneDeep } from 'lodash';
import { LabelComponent } from '../../forms/label/label.component';
import { NgIconsModule } from '@ng-icons/core';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-joii-picker',
  templateUrl: './joii-picker.component.html',
  styleUrl: './joii-picker.component.scss',
  standalone: true,
  imports: [LabelComponent, NgIconsModule, NgIf, NgFor, NgClass, FormsModule]
})
export class JoiiPickerComponent implements OnInit {

  @Input() label: string = '';
  @Input() selectionType: string = 'single';
  @Input() selectableOptions: Array<any> = [];
  @Input() groupOptionsName: string = 'reason_types';
  @Output() updateOnTouch: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateSingleSelection: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateMultiSelection: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('searchInput') searchInput!: ElementRef;
  @ViewChild('pickerInput') pickerInput!: ElementRef;
  filteredSelectableOptions: Array<any> = [];
  selectedItems: Array<any> = [];
  selectedItem: any;
  displayOverlay: boolean = false;
  pickerSearchTerm: string = '';
  selectedGroup: string = '';
  overlayHeight: number = 370;
  overlayTop: boolean = false;

  ngOnInit() {
    this.filteredSelectableOptions = cloneDeep(this.selectableOptions);
  }

  toggleDisplayOverlay() {
    this.updateOnTouch.emit();
    this.displayOverlay = !this.displayOverlay;
    if (this.displayOverlay) {
      this.determineOverlayPosition();
      setTimeout(() => {
        if (this.searchInput) {
          this.searchInput.nativeElement.focus();
        }
      }, 200);
    }
  }

  determineOverlayPosition() {
    const windowHeight = window.innerHeight;
    const pickerTop = this.pickerInput.nativeElement.getBoundingClientRect().top;
    this.overlayTop = windowHeight - pickerTop < this.overlayHeight;
  }

  closeDisplayOverlay() {
    this.displayOverlay = false;
  }

  clearSearch() {
    this.pickerSearchTerm = '';
    this.handleSearch({ target: { value: '' } });
  }

  handleSearch(searchEvent: any) {
    const searchTerm = searchEvent.target.value.toLowerCase();
    if (searchTerm.length > 1) {
      const filteredResults: Array<any> = [];
      const availableCategories = cloneDeep(this.selectableOptions);
      availableCategories.forEach(category => {
        if (category[this.groupOptionsName]) {
          const filteredOptions = category[this.groupOptionsName].filter((option: any) =>
            option.name.toLowerCase().includes(searchTerm));
          if (filteredOptions.length > 0) {
            const groupDetails = cloneDeep(category);
            groupDetails[this.groupOptionsName] = [];
            groupDetails[this.groupOptionsName] = filteredOptions;
            filteredResults.push(groupDetails);
          }
        }
      });
      this.filteredSelectableOptions = filteredResults;
    } else {
      this.filteredSelectableOptions = cloneDeep(this.selectableOptions);
    }
  }

  handleSingleSelection(item: any) {
    this.selectedItem = item;
    this.updateSingleSelection.emit(this.selectedItem);
    this.closeDisplayOverlay();
  }

  handleMultiSelection(item: any) {
    this.selectableOptions.forEach(group => {
      if (group[this.groupOptionsName].length > 0) {
        const isMatch = group[this.groupOptionsName].filter((option: any) =>
          option.doc_id.includes(item.doc_id));
        if (isMatch.length > 0) {
          isMatch[0].is_selected = !isMatch[0].is_selected;
          if (this.selectedItems.indexOf(item) === -1) {
            this.selectedItems.push(item);
          }
        }
      }
    });
    this.filteredSelectableOptions = cloneDeep(this.selectableOptions);
    this.updateMultiSelection.emit(this.selectedItems);
  }

  clearSingleSelection() {
    this.selectedItem = null;
    this.updateSingleSelection.emit(this.selectedItem);
  }

  clearMultiSelection(item: any, index: number) {
    this.selectedItems.splice(index, 1);
    this.updateMultiSelection.emit(this.selectedItems);
    this.selectableOptions.forEach(group => {
      const filteredItems = group[this.groupOptionsName].filter((option: any) =>
        option.doc_id.includes(item.doc_id));
      if (filteredItems.length > 0) {
        filteredItems[0].is_selected = false;
      }
    });
    this.filteredSelectableOptions = cloneDeep(this.selectableOptions);
  }

  identify(index: number, item: any) {
    return item.name;
  }
  groupSelect(groupName: string) {
    if (this.selectedGroup == groupName) {
      this.selectedGroup = ''
    } else {
      this.selectedGroup = groupName;
    }

  }
}
