import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CollapseContentAction } from './collapsecontent.interface';
import { NgClass, NgIf } from '@angular/common';
import { NgIconsModule } from '@ng-icons/core';
import { LucideAngularModule } from 'lucide-angular';

@Component({
  selector: 'app-collapsecontent',
  templateUrl: './collapsecontent.component.html',
  standalone: true,
  imports: [LucideAngularModule, NgIf, NgClass, NgIconsModule]
})
export class CollapseContentComponent {
  isCollapsed: boolean = false;
  actionClasses: string = 'text-xs text-zinc-800 hover:text-zinc-400'

  constructor(private router: Router) { }

  @Input() title!: string;
  @Input() docId?: string;
  @Input() actions: CollapseContentAction[] = []
  
  @Output() headerclick = new EventEmitter<void>();

  clicked() {
    this.isCollapsed = !this.isCollapsed;
    this.headerclick.emit();
  }
}
