<div class="flex flex-col p-4">
    <div class="mb-1">
        <span
            class="text-gray-500 dark:text-gray-200 text-xs font-semibold font-['Inter'] leading-none tracking-tight">{{
            consultationHistory.completed_on |
            date: 'd MMM, yyyy' | uppercase }}</span>
    </div>

    <div class="flex justify-center items-start relative ml-2">
        <div class="w-2 h-2 mt-2 bg-blue-900 absolute left-1 transform -translate-x-1/2 rounded-full"></div>
        <div class="w-px h-full bg-stone-200 dark:bg-slate-700 absolute left-1 transform -translate-x-1/2 mt-8"></div>
        <div class="ml-3 flex-grow">

            <div
                class="text-neutral-800 dark:text-white text-base font-semibold font-['Inter'] leading-normal tracking-tight mb-1">
                {{venomCodes}}</div>

            <div class="text-gray-500 mb-2 text-base font-medium font-['Inter'] tracking-tight">
                <app-rich-text-viewer *ngIf="!isExpanded"
                    [richText]="consultationHistory.notes.substring(0,200)"></app-rich-text-viewer>
                <span *ngIf="isExpanded; else tripleDots"><app-rich-text-viewer
                        [richText]="consultationHistory.notes"></app-rich-text-viewer></span>
                <ng-template #tripleDots>...</ng-template>
            </div>

            <app-accordion *ngIf="isExpanded" [title]="'Note to Customer'">
                <app-rich-text-viewer [richText]="consultationHistory.treatment_plan"></app-rich-text-viewer>
            </app-accordion>

            <div class="text-blue-900 dark:text-blue-300 cursor-pointer font-medium font-['Inter']"
                (click)="toggleExpand()">
                {{isExpanded? 'View Less':'View More'}} <ng-icon class="text-l"
                    [name]="isExpanded? 'ionChevronUp': 'ionChevronDown'">
                </ng-icon>
            </div>

            <div class="flex items-center mt-2">
                <img [src]="consultationHistory.staffProfile?.photo_url" alt="User Profile"
                    class="w-[18px] h-[17px] rounded-full mr-2">
                <div class="text-slate-800 dark:text-white text-[15px] font-semibold font-['Inter'] tracking-tight" [title]="consultationHistory.staffProfile?.name">
                    {{consultationHistory.staffProfile?.name | staffName}}
                </div>
            </div>
        </div>
    </div>
</div>
