import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgIconsModule } from '@ng-icons/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrl: './pagination.component.scss',
  standalone: true,
  imports: [NgIconsModule, FormsModule]
})
export class PaginationComponent implements OnInit {
  startIndexPageRange = 0;
  endIndexPageRange = 0;

  @Input() pageSizeOptions: number[] = [2, 3, 10, 25, 50, 100];
  @Input() pageSize: number = 10;
  @Input() currentPage: number = 1;
  @Input() totalPagesCount: number = 10;
  @Input() visiblePageRange: number = 5;
  get pagesArray(): number[] {
    return Array.from({ length: this.totalPagesCount }, (_, index) => index + 1);
  }

  @Output() pageSizeChange = new EventEmitter<number>()
  @Output() currentPageChange = new EventEmitter<number>()
  @Output() changes = new EventEmitter<{page: number, pageSize: number}>()

  constructor() {
  }

  ngOnInit() {
    this.goToPage(this.currentPage, false);
  }

  goToPage(page: number, emitChange = true): void {
    if (page >= 1 && page <= this.totalPagesCount) {
      this.currentPage = page;
      this.calculateVisiblePages(page);
      this.currentPageChange.emit(this.currentPage);
      if (emitChange) {
        this.changes.emit({page: this.currentPage, pageSize: this.pageSize});
      }
    }
  }

  changePageSize(pageSize: number): void {
    this.pageSize = pageSize
    this.currentPage = 1;
    this.pageSizeChange.emit(this.pageSize);
    this.currentPageChange.emit(this.currentPage);
    this.changes.emit({page: this.currentPage, pageSize: this.pageSize});
    this.calculateVisiblePages(1);
  }

  private calculateVisiblePages(currentPage: number) {
    // if the range for visible pages is more than the total count we just show every page
    if (this.visiblePageRange * 2 >= this.totalPagesCount) {
      this.startIndexPageRange = 0
      this.endIndexPageRange = this.totalPagesCount;
      return;
    }

    let minPage = Math.max(1, currentPage - this.visiblePageRange);
    let maxPage = Math.min(this.totalPagesCount, currentPage + this.visiblePageRange);

    const leftOffset = this.visiblePageRange - (currentPage - minPage);
    const rightOffset = this.visiblePageRange - (maxPage - currentPage);

    this.startIndexPageRange = minPage - 1 - rightOffset;
    this.endIndexPageRange = maxPage + leftOffset;
  }
}
