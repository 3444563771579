<div class="w-[150px] h-7 justify-start items-start inline-flex p-1" (click)="darkModeService.toggleState()">
    <div class="justify-start items-center gap-4 flex">
        <div class="p-0.5 bg-blue-100 rounded-[999px] border border-blue-300 justify-start items-start flex">
            @if(darkModeService.darkModeOn()){
            <div class="w-6 h-6 rounded-[999px] shadow"></div>
            <div class="w-6 h-6 bg-blue-900 rounded-[999px]"></div>
            } @else {
            <div class="w-6 h-6 bg-slate-400 rounded-[999px]"></div>
            <div class="w-6 h-6 rounded-[999px] shadow"></div>
            }
        </div>
        <div class=" text-base font-medium font-['Inter'] leading-normal tracking-tight">Dark mode</div>
    </div>
</div>