<section>
  <header class="mt-4 mb-3">
    <h2><strong>Look up product</strong></h2>
    <div class="mt-1 relative">
      <input
        class="joii-input text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 pl-7"
        type="text" placeholder="Search product name, brand or category" [(ngModel)]="searchTerm"
        (ngModelChange)="handleProductSearch()" />
      <ng-icon name="ionSearchOutline" class="absolute top-2.5 left-2"></ng-icon>
      <ng-icon name="ionCloseOutline" class="absolute top-2.5 right-2 cursor-pointer" title="Clear search"
        *ngIf="searchTerm.length > 0" (click)="clearSearch()"></ng-icon>
    </div>
  </header>
  <hr class="mb-3" />
  <p class="mb-3">Browse products</p>
  <ng-container
    *ngIf="availableCategories.length > 0 && !loadingData && selectedSubCategories.length === 0 && !selectedCategory && searchedProducts.length === 0 && searchTerm.length === 0">
    <div *ngFor="let category of availableCategories" class="w-1/3 inline-block mb-3 pr-3">
      <article class="pl-3 pr-3 pt-7 pb-7 border border-gray-300 rounded-2xl cursor-pointer"
        (click)="selectCategory(category)">
        <p><strong>{{category.Name}}</strong></p>
      </article>
    </div>
    <div *ngIf="categoriesPaginationConfig.pages > 0 && !loadingData">
      <app-pagination [currentPage]="categoriesPaginationConfig.page_number"
        [totalPagesCount]="categoriesPaginationConfig.pages" [pageSize]="categoriesPaginationConfig.items_per_page"
        (currentPageChange)="handleCategoryPagination($event)"
        (pageSizeChange)="handleCategoryPageSizeChange($event)"></app-pagination>
    </div>
  </ng-container>
  <ng-container
    *ngIf="availableCategories.length === 0 && !loadingData && selectedSubCategories.length === 0 && !selectedCategory && searchedProducts.length === 0 && searchTerm.length === 0">
    <article>
      <p><em>No categories found</em></p>
    </article>
  </ng-container>
  <ng-container
    *ngIf="!loadingData && selectedSubCategories.length > 0 && selectedCategory && !selectedSubCategory && searchedProducts.length === 0 && searchTerm.length === 0">
    <p *ngIf="selectedCategory" class="mb-2 cursor-pointer" (click)="clearSubCategory()">
      <strong>{{selectedCategory}}</strong>
    </p>
    <div *ngFor="let subCategory of selectedSubCategories" class="w-1/3 inline-block mb-3 pr-3">
      <article class="pl-3 pr-3 pt-7 pb-7 border border-gray-300 rounded-2xl cursor-pointer"
        (click)="selectSubCategory(subCategory)">
        <p><strong>{{subCategory.name}}</strong></p>
      </article>
    </div>
  </ng-container>
  <ng-container
    *ngIf="!loadingData && selectedSubCategories.length === 0 && selectedCategory && !selectedSubCategory && searchedProducts.length === 0 && searchTerm.length === 0">
    <article>
      <p *ngIf="selectedCategory" class="mb-2 cursor-pointer" (click)="clearSubCategory()">
        <strong>{{selectedCategory}}</strong>
      </p>
      <p><em>No sub categories found</em></p>
    </article>
  </ng-container>
  <ng-container
    *ngIf="!loadingData && selectedProducts.length === 0 && selectedSubCategory && searchedProducts.length === 0 && searchTerm.length === 0">
    <article>
      <p *ngIf="selectedCategory && selectedSubCategory" class="mb-2 cursor-pointer">
        <span (click)="clearSubCategory()">{{selectedCategory}}</span>&nbsp;/&nbsp;<span
          (click)="clearProducts()"><strong>{{selectedSubCategory}}</strong></span>
      </p>
      <p><em>No products found</em></p>
    </article>
  </ng-container>
  <ng-container
    *ngIf="!loadingData && selectedProducts.length > 0 && selectedSubCategory && searchedProducts.length === 0 && searchTerm.length === 0">
    <article class="w-full overflow-auto">
      <p *ngIf="selectedCategory && selectedSubCategory" class="mb-2 cursor-pointer">
        <span (click)="clearSubCategory()">{{selectedCategory}}</span>&nbsp;/&nbsp;<span
          (click)="clearProducts()"><strong>{{selectedSubCategory}}</strong></span>
      </p>
      <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-white">
        <thead class="text-xs text-gray-700 capitalize bg-gray-50 dark:bg-slate-900 dark:text-white">
          <tr>
            <th *ngFor="let col of productTableConfig" scope="col" class="px-6 py-3">
              <strong class="capitalize">{{col}}</strong>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="bg-white dark:bg-slate-700 border-b" *ngFor="let row of selectedProducts">
            <td *ngFor="let col of productTableConfig" class="px-6 py-3">
              @switch (col) {
              @case ('name') {
              {{row.Name}} {{row.name}}
              }
              @case ('sku') {
              {{row.SKU}} {{row.sku}}
              }
              @case ('description') {
              <a class="line-clamp-3">
                {{row.description}} {{row.ProductDesc}}
              </a>
              }
              @case('price'){
              @if (row.price) {
              {{(row.price / 100).toFixed(2) | number : "1.2-2"}} {{row.currency}}
              }
              }
              @case ('select') {
              <span class="cursor-pointer" style="text-decoration: underline; text-underline-offset: 4px;"
                (click)="handleProductSelect(row)">Select</span>
              }
              }
            </td>
          </tr>
        </tbody>
      </table>
      <div *ngIf="selectedProductsPaginationConfig.pages > 0 && !loadingData">
        <app-pagination [currentPage]="selectedProductsPaginationConfig.RequestedPageNumber"
          [totalPagesCount]="selectedProductsPaginationConfig.pages"
          [pageSize]="selectedProductsPaginationConfig.RequestedItemsPerPage"
          (currentPageChange)="handleProductPagination($event)"
          (pageSizeChange)="handleProductPageSizeChange($event)"></app-pagination>
      </div>
    </article>
  </ng-container>
  <ng-container *ngIf="!loadingData && searchedProducts.length > 0 && searchTerm.length > 1">
    <div class="w-full overflow-auto">
      <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-white">
        <thead class="text-xs text-gray-700 capitalize bg-gray-50 dark:bg-slate-900 dark:text-white">
          <tr>
            <th *ngFor="let col of productTableConfig" scope="col" class="px-6 py-3">
              <strong class="capitalize">{{col}}</strong>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="bg-white dark:bg-slate-700 border-b" *ngFor="let row of searchedProducts">
            <td *ngFor="let col of productTableConfig" class="px-6 py-3">
              @switch (col) {
              @case ('name') {
              {{row.Name}} {{row.name}}
              }
              @case ('sku') {
              {{row.SKU}} {{row.sku}}
              }
              @case ('description') {
              <a class="line-clamp-3">
                {{row.description}} {{row.ProductDesc}}
              </a>
              }
              @case('price'){
              @if (row.price){
              {{(row.price/ 100).toFixed(2) | number : "1.2-2"}} {{row.currency}}
              }
              }
              @case ('select') {
              <span class="cursor-pointer" style="text-decoration: underline; text-underline-offset: 4px;"
                (click)="handleProductSelect(row)">Select</span>
              }
              }
            </td>
          </tr>
        </tbody>
      </table>
      <div *ngIf="searchedProductsPaginationConfig.pages > 0 && !loadingData">
        <app-pagination [currentPage]="searchedProductsPaginationConfig.RequestedPageNumber"
          [totalPagesCount]="searchedProductsPaginationConfig.pages"
          [pageSize]="searchedProductsPaginationConfig.RequestedItemsPerPage"
          (currentPageChange)="handleProductSearchPagination($event)"
          (pageSizeChange)="handleProductSearchPageSizeChange($event)"></app-pagination>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!loadingData && searchedProducts.length === 0 && searchTerm.length > 1">
    <article>
      <p><em>No products found</em></p>
    </article>
  </ng-container>
  <ng-container *ngIf="loadingData">
    <p>Loading...</p>
  </ng-container>
</section>
