import { Component, EventEmitter, Output, input } from "@angular/core";
import { NgIconsModule } from "@ng-icons/core";
import { entities } from "src/shared/services/client/client";
import { animatePetItem, petSelectionListAnimation } from "./pet-selection.animation";


@Component({
  selector: 'app-petselection',
  standalone: true,
  templateUrl: './pet-selection.component.html',
  imports: [NgIconsModule],
  animations: [petSelectionListAnimation, animatePetItem]
})

export class PetSelectionComponent  {
  pets = input<entities.PetResponse[]>([]);
  @Output() petSelected = new EventEmitter<entities.PetResponse>();
}
