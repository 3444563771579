import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OnboardingRoutingModule } from './onboarding-routing.module';
import { StaffOnboardingComponent } from './staffonboarding/staffonboarding.component';
import { SharedModule } from 'src/shared/shared.module';
import { OnboardingComponent } from './onboarding/onboarding.component';


@NgModule({
  declarations: [
    OnboardingComponent,
    StaffOnboardingComponent],
  imports: [
    CommonModule,
    OnboardingRoutingModule,
    SharedModule
  ]
})
export class OnboardingModule { }
