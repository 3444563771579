import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Optional, Output, SimpleChanges, forwardRef } from "@angular/core";
import { ControlValueAccessor, FormGroupDirective, FormsModule, NG_VALUE_ACCESSOR, NgControl, ValidationErrors } from "@angular/forms";
import { isEqual } from "lodash";
import { AuthenticationService } from "src/shared/services/auth/auth_service";
import { LabelComponent } from "../forms/label/label.component";
import { ValidationComponent } from "../forms/validation/validation.component";
import { NgClass, NgIf } from "@angular/common";

@Component({
    selector: 'app-weight-selector',
    templateUrl: './weightselector.component.html',
    styleUrl: './weightselector.component.scss',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => WeightSelectorComponent)
        }
    ],
    standalone: true,
    imports: [LabelComponent, ValidationComponent, FormsModule, NgClass, NgIf]
})
export class WeightSelectorComponent implements OnInit, OnChanges, ControlValueAccessor {

    @Input() defaultUnit!: string;
  @Input() label!: string;
  @Input() name!: string;
  @Input() placeholder!: string;
  @Input() errormessage!: string;
  @Input() errorPositionAbsolute: boolean = false;

  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  value!: string | null;
  errors!: ValidationErrors | null | undefined;
  disabled = false;
  selectedUnit!: string;

  onChange: any = (value: any) => {
  };
  onTouched: any = () => {
  };

  formControl!: NgControl | null;

  constructor(
    private auth: AuthenticationService,
    private injector: Injector,
    @Optional() private formDirective: FormGroupDirective
  ) {}

  get ngControl(): NgControl | null {
    return this.injector.get(NgControl, null);
  }

  get submitted() {
    return this.formDirective ? this.formDirective.submitted : false;
  }

  ngOnInit() {
    this.formControl = this.ngControl;
    if (this.defaultUnit) {
        this.selectedUnit = this.defaultUnit;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.formControl && !isEqual(changes?.['value']?.previousValue, changes?.['value']?.currentValue)) {
      this.value = this.ngControl?.value;
      this.onChange(this.value);
      this.onTouched();
    }
  }

  writeValue(value: string): void {
    this.value = this.calculateWeightFromGrams(+value, this.selectedUnit).toString();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  onInputChange(newValue: string) {
    if (newValue !== this.value) {
      this.value = newValue;
      this.onChange(this.calculateGrams(this.value));
      this.valueChange.emit(this.calculateGrams(this.value) ?? undefined);
    }
    this.onTouched();
  }

  onInputBlur(event: Event) {
    const newValue = (event.target as HTMLInputElement).value;
    if (newValue !== this.value) {
      this.value = newValue;
      this.onChange(this.calculateGrams(this.value));
    }
    this.onTouched();
  }

  onUnitChange(unit: string) {
    const currentGrams = this.calculateGrams(this.value!);
    let newWeight: number;
    newWeight = this.calculateWeightFromGrams(+currentGrams, unit);
    this.selectedUnit = unit;
    this.auth.setWeightUnit(unit);
    this.value = newWeight.toString();
    this.onChange(currentGrams);
  }

  calculateGrams(weight: string): string {
    let result: number;
    if (this.selectedUnit === 'KG') {
        result = +weight * 1000;
    } else {
        result = +weight * 453.592;
    }
    return Math.round(result) + '';
  }

  calculateWeightFromGrams(weight: number, unit: string): number {
    let result: number;
    if (unit == 'KG') {
      result = weight / 1000;
    } else {
      result = weight / 453.592;
    }

    if (result % 1 !== 0) {
      result = Math.round((result + Number.EPSILON) * 100) / 100; // Rounds to 2 decimal places
    } else {
      result = Math.round(result); // Converts to integer
    }

    return result;
  }
}