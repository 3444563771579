<div cdkDropList [cdkDropListDisabled]="!isDraggable" [cdkDropListData]="config.items" (cdkDropListDropped)="onDrop($event)">
  @for (item of config.items; track $index) {
    <div cdkDrag (click)="onClick(item)" cdkDragLockAxis="y" class="drag-and-drop-item flex mb-1 py-2 hover:bg-gray-50 cursor-pointer">
      <div class="flex w-auto h-auto cursor-pointer" 
      (mouseenter)="onMouseEnterDragIcon($event)" (mouseout)="onMouseLeaveDragIcon($event)">
        <lucide-angular
        [floatUi]="'Drag and Drop'"
        [showDelay]="50"
        [placement]="tooltipPlacementEnum.AUTO"
        [preventOverflow]="true"
        [positionFixed]="true"
        [tabIndex]="0"
        name="menu"
        class="pointer-events-none text-dark-blue-900 flex items-center justify-center"
        size="20"
        ></lucide-angular>
      </div>
      <span class="text-dark-blue-900 ms-2">
        {{item.content}}
      </span>
      <div class="flex mb-1 py-2 min-h-5 hover:bg-gray-200 cursor-pointer" *cdkDragPlaceholder></div>
    </div>
  }
</div>
