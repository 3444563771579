import { Component, ElementRef, EventEmitter, HostBinding, HostListener, Injector, Input, Output, SimpleChanges, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, NgControl, ReactiveFormsModule } from '@angular/forms';
import { isEqual } from 'lodash';
import { SelectModel } from '../forms/select/select_model';
import { LabelComponent } from '../forms/label/label.component';
import { NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => MultiSelectComponent),
    }
  ],
  standalone: true,
  imports: [LabelComponent, FormsModule, ReactiveFormsModule, NgIf, NgFor]
})
export class MultiSelectComponent implements ControlValueAccessor {
  @Input() options: SelectModel[] = [];
  @Input() selectedValues: string[] = [];
  @Output() selectedValuesChange = new EventEmitter<string[]>();
  @Input() label!: string;
  @Input() span!: number;
  @Input() multi: boolean = true;

  value: any;
  disabled!: boolean;

  @HostBinding('class') get t() {
    return "sm:col-span-" + this.span;
  };

  searchText: string = '';
  filteredOptions: SelectModel[] = [];
  isDropdownOpen: boolean = false;

  @ViewChild('dropdownInput', { static: false }) dropdownInput!: ElementRef;

  formControl!: NgControl | null

  constructor(private elementRef: ElementRef, private injector: Injector,) { }
  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  get ngControl(): NgControl | null {
    return this.injector.get(NgControl, null);
  }

  onChange: any = (value: any) => {
  };
  onTouched: any = () => {
  };

  toggleDropdown(): void {
    this.isDropdownOpen = !this.isDropdownOpen;
    if (this.isDropdownOpen) {
      this.filteredOptions = this.options
    } else {
      this.filteredOptions = [];
    }


    console.log(this.options);
  }

  getOptionName(optionValue: string): string {
    const option = this.options.find(o => o.value === optionValue);
    return option ? option.name : 'Unknown';
  }

  removeSelectedOption(optionToRemove: string): void {
    this.selectedValues = this.selectedValues.filter(option => option !== optionToRemove);
    this.selectedValuesChange.emit(this.selectedValues);
    this.onChange(this.selectedValues);
  }

  toggleOption(option: string): void {
    if (!this.multi) {
      this.selectedValues = [];
      this.selectedValues.push(option);
      this.isDropdownOpen = false;
      this.filteredOptions = [];
      this.selectedValuesChange.emit(this.selectedValues);
      this.onChange(this.selectedValues);
      return
    }
    const index = this.selectedValues.indexOf(option);
    if (index === -1) {
      this.selectedValues.push(option);
    } else {
      this.selectedValues.splice(index, 1);
    }
    this.selectedValuesChange.emit(this.selectedValues);
    this.onChange(this.selectedValues);
  }

  filterOptions(): void {
    this.filteredOptions = this.options.filter(option =>
      option.name.toLowerCase().includes(this.searchText.toLowerCase())
    );
  }

  isSelected(option: string): boolean {
    return this.selectedValues.includes(option);
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const clickedInside = this.elementRef.nativeElement.contains(event.target);
    if (!clickedInside) {
      this.isDropdownOpen = false;
      this.filteredOptions = [];
    }
  }

  ngOnInit() {
    this.formControl = this.ngControl;
  }

  blur() {
    this.onTouched();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.formControl || !isEqual(changes?.['value']?.previousValue, changes?.['value']?.currentValue)) {
      this.selectedValues = this.ngControl?.value;
      this.onChange(this.selectedValues);
      this.onTouched();
    }
  }
  onInputClick(event: Event): void {
    event.stopPropagation();
    this.toggleDropdown();
  }
}
