import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { HorizontalPillsConfig, HorizontalPillsConfigItem, HorizontalPillsConfigItemType } from './horizontalpills.interface';
import { NgClass, NgFor } from '@angular/common';

@Component({
  selector: 'app-horizontalpills',
  templateUrl: './horizontalpills.component.html',
  standalone: true,
  imports: [NgFor, NgClass]
})

export class HorizontalPillsComponent {

  constructor(private router: Router) { }

  @Input() config: HorizontalPillsConfig = {
    items: [],
  }

  @Output() itemclick = new EventEmitter<HorizontalPillsConfigItem>();

  itemclicked(menuItem: HorizontalPillsConfigItem) {
    if (menuItem.type === HorizontalPillsConfigItemType.Callback) {
      this.itemclick.emit(menuItem);
    } else if (menuItem.type === HorizontalPillsConfigItemType.Path) {
      this.router.navigate([menuItem.id]);
    }
  }

}
