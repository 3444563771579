<div class="dropdown">
  <div class="dropdown-button" (click)="toggleDropdown()">
    @if(selectedOptions.length > 0) {
    <span *ngFor="let selectedOption of selectedOptions" class="selected-tag">
      <span class="remove-tag" (click)="removeSelection(selectedOption, $event)">×</span>
      {{ selectedOption.name }}
    </span>
    } @else {
    <span class="placeholder">{{placeholder}}</span>
    }

    <ng-icon name="ionChevronDown" size="1.5rem"></ng-icon>
  </div>
  <div class="dropdown-content" *ngIf="showDropdown">
    @if (showCategory){
    <div *ngFor="let option of categories" class="dropdown-item">
      <label class="option-container" [for]="option.name">
        <input type="checkbox" [id]="option.name" [checked]="isSelected(option)" (change)="toggleSelection(option)">
        <label [for]="option.name">{{ option.name }}</label>
        <ul>
          <li *ngFor="let item of option.groups">{{ item.name }}</li>
        </ul>
      </label>
    </div>
    }

    @if (showGroup) {
    <div *ngFor="let option of groups" class="dropdown-item">
      <label class="option-container" [for]="option.name">
        <input type="checkbox" [id]="option.name" [checked]="isSelected(option)" (change)="toggleSelection(option)">
        <label [for]="option.name">{{ option.name }}</label>
        <ul>
          <li *ngFor="let item of option.venom_codes">{{ item.term_name }}</li>
        </ul>
      </label>
    </div>
    }

    @if (showCode) {
    <div *ngFor="let option of codes" class="dropdown-item">
      <label class="option-container" [for]="option.name">
        <input type="checkbox" [id]="option.name" [checked]="isSelected(option)"
          (change)="toggleSelection(option)">
        <label [for]="option.name">{{ option.name }}</label>
      </label>
    </div>
    }
  </div>
</div>
