import { Injectable, inject } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { venomcodeinternal } from '../client/client';
import { ClientService } from '../client/client.service';

@Injectable({
  providedIn: 'root'
})
export class VenomCodesDataService {
  clientService = inject(ClientService);

  public getVenomGroup(venomGroup_id: string): Observable<venomcodeinternal.GetVenomGroupResponse> {
    return this.clientService.client.pipe(
      switchMap(client => client.venomcodeinternal.GetVenomGroup(venomGroup_id))
    )
  }

  public getVenomCategory(venomCategory_id: string): Observable<venomcodeinternal.GetVenomCategoryResponse> {
    return this.clientService.client.pipe(
      switchMap(client => client.venomcodeinternal.GetVenomCategory(venomCategory_id))
    )
  }

  public getVenomCode(venomCode_id: string): Observable<venomcodeinternal.GetVenomCodeResponse> {
    return this.clientService.client.pipe(
      switchMap(client => client.venomcodeinternal.GetVenomCode(venomCode_id))
    )
  }

  public getListVenomCodes(): Observable<venomcodeinternal.AllVenomCodesResponse> {
    return this.clientService.client.pipe(
      switchMap(client => client.venomcodeinternal.ListVenomCategoriesWithCodes())
    );
  }

  public uploadVenomCodes(category: string, venomCodesCsv: string): Observable<void> {
    const request: venomcodeinternal.UploadVenomCodesRequest = {
      category: category,
      venom_codes_csv: venomCodesCsv
    };

    return this.clientService.client.pipe(
      switchMap(client => client.venomcodeinternal.UploadVenomCodes(request))
    );
  }
}
