<div class="mb-1 title">
    <app-label [label]="label" [label]="label"></app-label>
    <div class="tooltip">
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M19 9.84C19 4.9578 14.9706 1 10 1C5.02944 1 1 4.9578 1 9.84V10.16C1 15.0422 5.02944 19 10 19C14.9706 19 19 15.0422 19 10.16V9.84Z" stroke="#009CB8" stroke-width="1.5"/>
        <path d="M11.2817 11.9503C11.2817 12.6398 11.2817 13.3149 11.2817 13.9901C11.323 14.4006 11.0797 14.7943 10.6575 15.0004C10.2352 15.2065 9.71008 15.1878 9.30982 14.9525C8.94775 14.8007 8.71764 14.4896 8.71484 14.1481C8.71484 13.1426 8.71484 12.1371 8.71484 11.1315C8.71484 10.6575 8.71484 10.1691 8.71484 9.6951C8.73325 9.15102 9.26066 8.7181 9.9048 8.71832C10.2189 8.69452 10.5311 8.77859 10.7709 8.95155C11.0108 9.12452 11.1582 9.37181 11.1798 9.63764C11.1798 10.399 11.1798 11.1603 11.1798 11.9216L11.2817 11.9503Z" fill="#009CB8"/>
        <path d="M9.98318 7.21402C9.64541 7.21874 9.32008 7.08666 9.08121 6.84782C8.84234 6.60899 8.71024 6.2837 8.71497 5.94597C8.71024 5.59903 8.846 5.26491 9.09139 5.01956C9.33677 4.77421 9.67094 4.63846 10.0179 4.64319C10.7135 4.67043 11.2684 5.23287 11.2861 5.9286C11.291 6.27402 11.1547 6.60647 10.9088 6.84909C10.6628 7.09172 10.3286 7.22351 9.98318 7.21402Z" fill="#009CB8"/>
    </svg>
    <span class="tooltiptext">{{ tooltipMessage }}</span>
    </div>
</div>
<div class="wrapper">
  <select *ngIf="showDaySelector" [(ngModel)]="selectedDay" (change)="updateDays()">
    <option selected disabled>DD</option>
    <option *ngFor="let day of days" [value]="day">{{ day }}</option>
  </select>
  <div *ngIf="showDaySelector" class="separator"></div>

  <select *ngIf="showMonthSelector" [(ngModel)]="selectedMonth" (change)="updateDays()">
    <option selected disabled>MM</option>
    <option *ngFor="let month of months" [value]="month">{{ month }}</option>
  </select>
  <div *ngIf="showMonthSelector" class="separator"></div>
      <select class="" [(ngModel)]="selectedYear" (change)="updateDays()">
        <option selected disabled>YYYY</option>
        <option *ngFor="let year of years" [value]="year">{{ year }}</option>
      </select>
</div>
<app-validation *ngIf="formControl || errormessage" [control]="formControl" [submitted]="submitted" [errormessage]="errormessage" [name]="name"></app-validation>