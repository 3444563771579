import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StaffProfileDataService } from 'src/pms/services/staffprofiles.data.service/staffprofiles.data.service';
import { APIError } from 'src/shared/services/client/client';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html'
})

export class OnboardingComponent implements OnInit {

  constructor(
    private router: Router,
    private staffProfileDataService: StaffProfileDataService) { }

  ngOnInit(): void {
    this.staffProfileDataService.GetMyStaffProfile()
      .subscribe({
        next: (response) => this.next(),
        error: error => this.handlerError(error),
        complete: () => this.finally()
      });
  }

  next() {
    // staff profile already set up
    this.router.navigate(['/dashboard']);
  }

  handlerError(error: APIError) {
    if (error?.code === "not_found") {
      this.router.navigate(['/onboarding/staff']);
    } else {
      this.unknownUserState();
    }
  }

  unknownUserState() {
    this.router.navigate(['unauthorised']);
  }

  finally() { }

}
