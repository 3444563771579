@if(appointmentDetails){
<div class="fixed w-screen h-screen top-0 left-0 z-10" (click)="showLess()"></div>
}
<app-sidebar [ngClass]="'max-h-screen overflow-y-auto z-20 relative'" position="right"
    [isOpen]="appointmentDetails!== undefined" [wide]="true" [hideClose]="true">
    @if(appointmentDetails){
    <section slot="header">
        <div class="flex flex-row justify-between mt-2 mr-2">
            <button
                class="px-4 py-3.5 rounded-lg justify-center items-center gap-2.5 text-gray-500 dark:text-white text-[15px] font-semibold font-['Inter'] tracking-tight flex flex-row"
                (click)="showLess()"><lucide-angular name="chevrons-right"></lucide-angular>
                Show
                Less</button>
            @if (appointmentDetails.consult_status) {
            <button class="joii-btn-primary" [disabled]="appointmentDetails.staff_profile_id != staffProfile.id"
                (click)="handleContinueConsultation()">
                Continue consult
            </button>
            } @else {
            <button class="joii-btn-primary" [disabled]="appointmentDetails.staff_profile_id != staffProfile.id"
                (click)="handleStartConsultation()">
                Start consult
            </button>
            }
        </div>
    </section>
    }
    @if(appointmentDetails){
    <section slot="content">
        <div class="p-2">
            <div class="text-neutral-800 dark:text-white text-2xl font-semibold font-['Inter'] tracking-tight">
                <span class="inline-block">{{appointmentDetails.user.first_name}} {{appointmentDetails.user.last_name}} calling for
                  {{appointmentDetails.pet.name}}</span>
                <span *ngIf="appointmentDetails.pet.policy_active">
                  @switch (appointmentDetails.business.partner_integration) {
                    @case('ANIMALFRIENDS') {
                      <span class="w-6 h-6 ml-1 inline-block">
                        <img src="https://firebasestorage.googleapis.com/v0/b/vetai-e3e47.appspot.com/o/joii%2FAFI_icon.png?alt=media&token=87f113af-06d5-48c2-bc2c-7a98bf925afd" class="w-full h-auto" title="AFI" />
                      </span>
                    }
                    @case('ASDA') {
                      <span class="w-6 h-6 ml-1 inline-block">
                        <img src="https://firebasestorage.googleapis.com/v0/b/vetai-e3e47.appspot.com/o/joii%2FASDA_icon.png?alt=media&token=960ed7ad-5e44-4b71-9c32-00e5e085eb9c" class="w-full h-auto" title="Asda" />
                      </span>
                    }
                    @case('PURELYPETS') {
                      <span class="w-6 h-6 ml-1 inline-block">
                        <img src="https://firebasestorage.googleapis.com/v0/b/vetai-e3e47.appspot.com/o/joii%2FPurelyPets_icon.png?alt=media&token=0f5a8133-d362-49e5-a5a0-14585ad33e36" class="w-full h-auto" title="Purely Pets" />
                      </span>
                    }
                    @case('WAGGLE') {
                      <span class="w-6 h-6 ml-1 inline-block">
                        <img src="https://firebasestorage.googleapis.com/v0/b/vetai-e3e47.appspot.com/o/joii%2FWaggel_icon.png?alt=media&token=f68f5688-49dd-44af-851d-9fb4c470f725" class="w-full h-auto" title="Waggle" />
                      </span>
                    }
                    @default {
                      <span class="inline-block">{{appointmentDetails.business.name}}</span>
                    }
                  }
                </span>
            </div>
            <p class="text-lg">
                <span class="appointment-time-holder rounded"
                    [ngClass]="{'pl-1 pr-1 bg-yellow-300 dark:bg-slate-600': appointmentDetails.is_late}">
                    <lucide-icon class="inline-block" name="calendar-check"></lucide-icon>
                    <strong>
                        <span>&nbsp;{{appointmentDetails.start.utc | date: 'h:mm a'}}</span>
                        <span *ngIf="appointmentDetails.is_late">&nbsp;(behind schedule)</span>
                    </strong>
                </span>
                <span>&nbsp;|&nbsp;{{appointmentDetails.start.utc | date: 'EEEE, MMMM d'}}</span>
            </p>
            <section class="mt-2">
                <p class="joii-text-primary capitalize">
                    <span *ngIf="appointmentDetails.pet.species">{{appointmentDetails.pet.species}},&nbsp;</span>
                    <span *ngIf="appointmentDetails.pet.gender">{{appointmentDetails.pet.gender}},&nbsp;</span>
                    <span *ngIf="appointmentDetails.pet.breed">{{appointmentDetails.pet.breed.name}},&nbsp;</span>
                    <span *ngIf="appointmentDetails.pet.age">Age: {{appointmentDetails.pet.age.years}} year<span
                            *ngIf="appointmentDetails.pet.age.years > 1">s</span>
                        <span *ngIf="appointmentDetails.pet.age.months > 0">, {{appointmentDetails.pet.age.months}}
                            month<span *ngIf="appointmentDetails.pet.age.months > 1">s</span></span>
                        <span *ngIf="appointmentDetails.pet.weight">,&nbsp;</span></span>
                    <span *ngIf="appointmentDetails.pet.weight"><!--TODO:change-->{{appointmentDetails.pet.weight.kg}}
                        kg</span>
                </p>
                <p class="joii-text-primary"><strong>Owner Email:</strong> {{appointmentDetails.user.email}}</p>
                <p class="joii-text-primary"><strong>Owner Phone:</strong> {{appointmentDetails.user.phone_number}}</p>
                <p class="joii-text-primary"><strong>Owner Timezone:</strong> {{appointmentDetails.start.timezone}}</p>
                <!--<p *ngIf="appointmentDetails.long_reason" class="mb-2">{{appointmentDetails.long_reason}}</p>-->
                <!-- <span *ngIf="appointmentDetails.is_priority"
                    class="bg-red-600 text-white text-sm p-1 pl-3 pr-3 mr-2 rounded-xl">Urgent Call</span> -->
            </section>
            <app-divider></app-divider>

            
            @if (healthCheckData) {
                <div class="text-neutral-800 dark:text-white text-2xl font-semibold font-['Inter'] tracking-tight">Health Score Survey</div>
                <app-health-check-data [healthCheckData]="healthCheckData"></app-health-check-data>
            } @else {
                <div class="text-neutral-800 dark:text-white text-2xl font-semibold font-['Inter'] tracking-tight">Owner Observations</div>
                <app-owner-observations [appointmentDetails]="appointmentDetails"></app-owner-observations>
            }

            <app-divider></app-divider>
            <div class="text-neutral-800 dark:text-white text-2xl font-semibold font-['Inter'] tracking-tight">Medical
                history</div>
            @if (petConsultationsHistory.length > 0) {
              <app-consultation-history-list
                [loadingMore]="loadingHistory"
                [consultationHistory]="petConsultationsHistory"
                [paginationConfig]="petFullHistoryPaginationConfig"
                (fetchMore)="handleGetMoreHistory()"></app-consultation-history-list>
            }
            @if (petConsultationsHistory.length === 0) {
              <p class="joii-text-primary">No consultation records found</p>
            }
            @if (loadingHistory) {
              <p class="joii-text-primary">Loading...</p>
            }
        </div>
    </section>
    }
</app-sidebar>
