<div class="text-blue-900 text-xl font-medium font-['Inter'] tracking-tight pt-5">{{title}}</div>
<div class="shadow-lg box-content p-4 border-2 rounded-lg bg-white">
    @if(!disableExpansion){
    <div (click)="toggle()" class="p-5 bg-white flex-col justify-start items-start gap-4 inline-flex "
        ngClass="{'cursor-pointer: !disableExpansion'}">

        <ng-icon *ngIf="!showContent" name="ionChevronDownOutline"></ng-icon>
        <ng-icon *ngIf="showContent" name="ionChevronUpOutline"></ng-icon>

    </div>
    }
    @if(showContent || disableExpansion){
    <ng-content></ng-content>
    }

</div>