<!-- <div>
  
</div> -->
 <!-- Settings forms -->
 <div class="divide-y divide-white/5">
  <div class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 md:grid-cols-3">
    <div>
  <h2 class="flex items-center text-2xl font-bold leading-7 tracking-tight text-gray-900">
    @if(backBtn) {
      <button (click)="backClicked()" class="py-1 px-2 mt-1" type="button"><ng-icon name="ionArrowBackOutline" size="1.5rem"></ng-icon></button>
    }
    <span>{{title}}</span>
    @if(createBtn) {
      <button (click)="createClicked()" class="joii-btn-primary ms-2 py-1 px-2" type="button" data-automationid="create-button"><ng-icon name="ionAddOutline" size="1.5rem"></ng-icon></button>
    }
  </h2>
  <p  class="mt-2 text-sm leading-6 text-gray-500">
    <span *ngIf="description">{{description}}</span>
    <a *ngIf="linktext" (click)="linkedClicked()" class="font-semibold joii-text-primary cursor-pointer hover:underline">
      {{linktext}}
    </a>
  </p>
    </div>

    <div class="md:col-span-2 pb-12">
      <ng-content></ng-content>
  </div>
</div>