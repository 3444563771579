import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, map, of } from 'rxjs';
import { HorizontalPillsConfig, HorizontalPillsConfigItem, HorizontalPillsConfigItemType } from 'src/shared/components/design/horizontalpills/horizontalpills.interface';
import { StackedListConfig, StackedListItem, StackedListItemType } from 'src/shared/components/design/stackedlist/stackedlist.interface';
import { bookingexternal, bookinginternal, entities } from 'src/shared/services/client/client';
import { AppointmentsDataService } from '../../services/appointments.data.service/appointments.data.service';
import { StaffProfileDataService } from 'src/pms/services/staffprofiles.data.service/staffprofiles.data.service';

@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html'
})

export class AppointmentsComponent implements OnInit {

  constructor(
    private router: Router,
    private staffProfileDataService: StaffProfileDataService,
    private appointmentsDataService: AppointmentsDataService) { }

  ngOnInit(): void {
    this.load();
  }

  activePillId: string = "future";
  appointments$!: Observable<bookinginternal.ListAppointmentsV2Response>;
  config$!: Observable<StackedListConfig>;

  getPillsConfig(): HorizontalPillsConfig {
    let config: HorizontalPillsConfig = {
      items: [{
        id: "future",
        active: this.activePillId == "future",
        type: HorizontalPillsConfigItemType.Callback,
        title: "Upcoming",
        data: null,
      },
      {
        id: "past",
        active: this.activePillId == "past",
        type: HorizontalPillsConfigItemType.Callback,
        title: "Past",
        data: null,
      }],
    }
    return config;
  }

  pillClicked(pill: HorizontalPillsConfigItem) {
    this.activePillId = pill.id;
    if (pill.id == "future") {
      this.load();
    } else if (pill.id == "past") {
      this.load(true);
    }
  }

  load(desc: boolean = false) {
    // TODO - required refactor
    this.staffProfileDataService.GetMyStaffProfile()
      .subscribe((response) => {
        const params: bookinginternal.ListAppointmentsV2Request = {
          RequestedItemsPerPage: 50,
          RequestedPageNumber: 1,
          BusinessDocID: "",
          EndDate: "",
          StartDate: "",
          Status: ["Booked"],
          ServiceDocID: "",
          OrderBy: desc ? "desc" : "asc",
          PetDocID: "",
          ShiftDocID: "",
          StaffProfileDocID: response.id,
          // TODO - set this from central service
          Timezone: "Europe/London",
          UserDocID: "",
          Open: false,
          Waiting: false,
          ListAppointmentWithoutStaffProfiles: false,
          ExcludeAppointmentsInConsultationStates: []
        }
        this.appointments$ = this.appointmentsDataService.ListAppointments(params).pipe(
          catchError((err) => {
            return of(err);
          })
        );
        this.config$ = this.appointments$.pipe(map(data => this.getConfig(data)))
      })
  }

  book() {
    this.router.navigate(['/book']);
  }

  getConfig(appointments: bookinginternal.ListAppointmentsV2Response): StackedListConfig {
    let config: StackedListConfig = {
      items: [

      ],
    }
    for (let appointment of appointments.appointments) {
      var item: StackedListItem = {
        id: `/appointments/${appointment.doc_id}`,
        type: StackedListItemType.Path,
        title: new Date(appointment.start.local).toLocaleString(),
        description: appointment.status,
        image: "",
        badges: []
      }
      if (appointment.is_open) {
        item.badges?.push({
          name: 'Open',
          color: 'yellow'
        })
      }
      if (appointment.is_waiting) {
        item.badges?.push({
          name: 'Waiting',
          color: 'green'
        })
      }
      config.items.push(item);
    }
    return config;
  }

}
