import { Pipe, PipeTransform } from '@angular/core';
import { ClientErrorMessage } from './clienterror.interface';

@Pipe({
    name: 'isClientErrorMessage',
    standalone: true
})
export class IsClientErrorMessagePipe implements PipeTransform {
    transform(value: any): value is ClientErrorMessage {
        return !!value && typeof value.message !== 'undefined';
    }
}