import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DefaultDropdownItem } from './dropdown-list.interface';
import { NgFor, NgIf } from '@angular/common';
import { NgIconsModule } from '@ng-icons/core';

@Component({
  selector: 'app-dropdown-list',
  templateUrl: './dropdown-list.component.html',
  styleUrl: './dropdown-list.component.scss',
  standalone: true,
  imports: [NgIf, NgIconsModule, NgFor]
})
export class DropdownListComponent {
  @Input() options: DefaultDropdownItem[] = [];
  @Input() title: string = '';
  @Input() icon: string | undefined;
  @Output() optionSelected: EventEmitter<DefaultDropdownItem> = new EventEmitter<DefaultDropdownItem>();

  selectedOption: string = '';
  isOpen: boolean = false;

  constructor() {}

  selectOption(option: DefaultDropdownItem): void {
    this.selectedOption = option.details;
    this.isOpen = false;

    this.optionSelected.emit(option);
  }
  open(){
    this.isOpen = !this.isOpen;

  }
}
