@if (consultationHistory.length > 0) {
  <section>
    @for (history of consultationHistory; track history) {
      <article class="bg-gray-50 dark:bg-slate-700 mb-3 p-3 rounded">
        <header class="pt-1 mb-2">
          <span
            class="text-gray-500 dark:text-gray-200 text-xs font-semibold font-['Inter'] leading-none tracking-tight">
            {{history.appointment.actual_end_time | date: 'd MMM, yyyy HH:MM' | uppercase }}
          </span>
          <span class="float-right">
            <img [src]="history.appointment?.staff_profile_photo_url" alt="User Profile"
                 class="w-[18px] h-[17px] rounded-full mr-2 inline-block">
            <span class="text-slate-800 dark:text-white text-[15px] font-semibold font-['Inter'] tracking-tight" [title]="history.appointment?.staff_profile_name">
              {{history.appointment?.staff_profile_name | staffName}}
            </span>
          </span>
        </header>
        <section class="text-gray-500 mb-2 text-base font-medium font-['Inter'] tracking-tight">
          <span class="text-slate-800 dark:text-white text-[18px] font-semibold font-['Inter'] tracking-tight">
            {{history | venomCodeExtractor}}
          </span>
          @if (!history.expanded) {
            <app-rich-text-viewer [richText]="history.notes.substring(0,200)"></app-rich-text-viewer>
            @if (history.notes.length > 200) {<span>...</span>}
          }
          @if (history.expanded) {
            <app-rich-text-viewer [richText]="history.notes"></app-rich-text-viewer>
          }
        </section>
        <main *ngIf="history.expanded">
          <hr class="mb-2" />
          <section class="text-gray-500 mb-2 text-base font-medium font-['Inter'] tracking-tight">
            <span class="text-slate-800 dark:text-white text-[18px] font-semibold font-['Inter'] tracking-tight">Product Recommendations</span>
            @if (history.recommended_products.length > 0) {
              @for (product of history.recommended_products; track $index) {
                <div class="flex flex-col">
                  <a>
                    <strong>product id: </strong> {{product.product_doc_id}}</a><a>
                  <strong>usage: </strong>{{product.usage}}</a><a>
                  <strong>frequency: </strong>{{product.frequency}}</a><a>
                  <strong>link: </strong> {{product.product_url}}</a>
                </div>
              }
            }
            @if (history.recommended_products.length === 0) {
              <p>No recommended products</p>
            }
          </section>
          <hr class="mb-2" />
          @if (history.healthCheckData) {
            <section class="text-gray-500 mb-2 text-base font-medium font-['Inter'] tracking-tight">
              <span class="text-slate-800 dark:text-white text-[18px] font-semibold font-['Inter'] tracking-tight">Health Score Survey</span>
              <app-health-check-data [healthCheckData]="history.healthCheckData"></app-health-check-data >
            </section>
          } @else {
            <section class="text-gray-500 mb-2 text-base font-medium font-['Inter'] tracking-tight">
              <span class="text-slate-800 dark:text-white text-[18px] font-semibold font-['Inter'] tracking-tight">Owner Observations</span>
              <app-owner-observations [appointmentDetails]="history.appointment"></app-owner-observations>
            </section>
          }

          <hr class="mb-2" />
          <section class="text-gray-500 mb-2 text-base font-medium font-['Inter'] tracking-tight">
            <span class="text-slate-800 dark:text-white text-[18px] font-semibold font-['Inter'] tracking-tight">Assessment</span>
            <article class="joii-raised-well p-3 rounded">
              <app-assessment-details [consultation]="history"></app-assessment-details>
            </article>
          </section>
          <hr class="mb-2" />
          @if(history.outcome){
            <section class="mb-2">
              <span class="text-slate-800 dark:text-white text-[18px] font-semibold font-['Inter'] tracking-tight">Outcomes</span>
              <article class="joii-raised-well p-3 rounded">
                <app-formio-submission [SubmissionId]="history.outcome.doc_id"></app-formio-submission>
              </article>
            </section>
            <hr class="mb-2" />
          }
          <section class="mb-2">
            <span class="text-slate-800 dark:text-white text-[18px] font-semibold font-['Inter'] tracking-tight">Note to customer</span>
            <app-rich-text-viewer [richText]="history.treatment_plan"></app-rich-text-viewer>
          </section>
        </main>
        <footer>
          <button class="joii-btn-outline-sml-round" (click)="toggleExpanded(history)">
            Show @if (history.expanded) {Less} @if (!history.expanded) {More}
          </button>
        </footer>
      </article>
    }
    <footer>
      <div class="pb-2"><span>Displaying records 1-{{consultationHistory.length}} of {{paginationConfig.total}}</span></div>
      @if (paginationConfig.total > paginationConfig.RequestedItemsPerPage && !(consultationHistory.length === paginationConfig.total)) {
        <button class="joii-btn-outline-sml-round" [disabled]="loadingMore" (click)="fetchMoreNow()">
          Load More
        </button>
      }
    </footer>
  </section>
}
@if (consultationHistory.length === 0) {
  <section>
    <article><p>No Consultation History</p></article>
  </section>
}
