import { Component, inject } from "@angular/core";
import { DarkModeService } from "src/shared/services/darkmode/darkmode_service";

@Component({
    selector: 'app-dark-mode-toggle',
    standalone: true,
    imports: [],
    templateUrl: './dark-mode-toggle.component.html'
})
export class DarkModeToggleComponent {
    darkModeService: DarkModeService = inject(DarkModeService)
}