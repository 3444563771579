import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { bookinginternal } from "../client/client";
import { ClientService } from "../client/client.service";

@Injectable({
    providedIn: 'root'
})

export class OpenTokTestSessionService {
    constructor(private client: ClientService) { }


    GenerateTestOpenTokSession(): Observable<bookinginternal.GenerateTestOpenTokSessionResponse> {
        return this.client.requestWithClient(client => client.bookinginternal.GenerateTestOpenTokSession())
    }
}