@for (reason of consultation.reasons; track $index) {
  <div class="font-bold">{{reason.reason_type_name}}</div>

  @if (reason.pre_clinical_history_submissions) {
    @for (preClinicalForm of reason.pre_clinical_history_submissions; track $index) {
      {{preClinicalForm.form_name}}
      <app-formio-submission [SubmissionId]="preClinicalForm.doc_id"></app-formio-submission>
    }
  }
  @for (history of reason.clinical_history; track $index) {
    {{history.clinical_history_category_name}}
    <app-formio-submission [SubmissionId]="history.clinical_history_submission.doc_id"></app-formio-submission>
  }
  @if (reason.post_clinical_history_submissions) {
    @for (postClinicalForm of reason.post_clinical_history_submissions; track $index) {
      {{postClinicalForm.form_name}}
      <app-formio-submission [SubmissionId]="postClinicalForm.doc_id"></app-formio-submission>
    }
  }
  @if (reason.pre_examinations_submissions) {
    @for (preExamForm of reason.pre_examinations_submissions; track $index) {
      {{preExamForm.form_name}}
      <app-formio-submission [SubmissionId]="preExamForm.doc_id"></app-formio-submission>
    }
  }
  @for (exam of reason.examinations; track $index) {
    {{exam.examination_category_name}}
    <app-formio-submission [SubmissionId]="exam.examination_submission.doc_id"></app-formio-submission>
  }
  <div>
    Likely diagnosis
    <div>
      @for (likelyGroup of reason.likely_diagnosis_groups; track likelyGroup; let index = $index) {
        <span>{{likelyGroup.display_name}}<span
          *ngIf="index < reason.likely_diagnosis_groups.length - 1">,&nbsp;</span></span>
      }
      @for (likelyCodes of reason.likely_diagnosis_codes; track likelyCodes; let index = $index) {
        <span>{{likelyCodes.display_name}}<span
          *ngIf="index < reason.likely_diagnosis_codes.length - 1">,&nbsp;</span></span>
      }
    </div>
  </div>
  <div *ngIf="reason.other_diagnosis_groups.length > 0 || reason.other_diagnosis_codes.length > 0">
    Other diagnosis
    <div>
      @for (otherGroup of reason.other_diagnosis_groups; track otherGroup; let index = $index) {
        <span>{{otherGroup.display_name}}<span
          *ngIf="index < reason.other_diagnosis_groups.length - 1">,&nbsp;</span></span>
      }
      @for (otherCodes of reason.other_diagnosis_codes; track otherCodes; let index = $index) {
        <span>{{otherCodes.display_name}}<span
          *ngIf="index < reason.other_diagnosis_codes.length - 1">,&nbsp;</span></span>
      }
    </div>
  </div>
}
