import { Component, Input, OnInit } from '@angular/core';
import { FormioModule } from '@formio/angular';
import { uuid } from 'src/shared/services/client/client';
import { FormSubmissionService } from 'src/shared/services/formSubmission/formSubmission.service';

@Component({
  selector: 'app-formio-submission',
  templateUrl: './formio_submission.component.html',
  standalone: true,
  imports: [FormioModule]
})
export class FormioSubmissionComponent implements OnInit {
  constructor(private formSubmissionService: FormSubmissionService) {

  }

  async ngOnInit(): Promise<void> {
  }

  private async getSubmission(submissionId: uuid.UUID) {
    this.loading = true;
    this.formSubmissionService.GetSubmission(submissionId).subscribe(submissionReponse => {
      this.submission = submissionReponse.form_data;
      this.formUrl = `/jxcochdpcfazlkz/${submissionReponse.form_name}/v/${submissionReponse.form_data._fvid}`
      this.loading = false;
    })
  }

  @Input()
  set SubmissionId(sub: uuid.UUID) {
    this.getSubmission(sub);
  };

  loading: boolean = true;

  submission: any;

  formUrl!: string;

}


