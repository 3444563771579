<div class="d-flex justify-content-between align-items-center">
    <div class="w-full">
        <app-input data-automationid="search-input" [placeholder]="placeholder" (keydown.enter)="onBtnClick()" [ngModel]="searchTerm()"
            (ngModelChange)="searchTerm.set($event)"></app-input>
    </div>
    @if (filterButton) {
        <button data-automationid="filter-button" (click)="onFilterBtnClick()" class="joii-btn-primary ms-2 py-1 px-2" type="button">
            <ng-icon size="1.5rem" [tabIndex]="0" name="ionFilterOutline"></ng-icon>
        </button>
    }

    <button data-automationid="search-button" (click)="onBtnClick()" class="joii-btn-primary ms-2 py-1 px-2" type="button">
        <ng-icon name="ionSearchOutline" size="1.5rem"></ng-icon>
    </button>
</div>