import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NgIconsModule } from '@ng-icons/core';

@Component({
  selector: 'app-pet-details-display',
  templateUrl: './pet-details-display.component.html',
  styleUrl: './pet-details-display.component.scss',
  standalone: true,
  imports: [NgIf, NgIconsModule],
})
export class PetDetailsDisplayComponent {

  @Input() petDetails: any;

}
