import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LoginGuard } from 'src/shared/guards/login.guard';
import { SubmissionHistoryComponent } from './pages/submissionHistory/submission-history.component';
import { VideoChecksComponent } from './pages/videochecks/videochecks.component';

const routes: Routes = [
  {
    path: "dashboard",
    canActivate: [LoginGuard],
    component: DashboardComponent,
    title: 'Joii PMS - Dashboard'
  },
  {
    path: "submission/:id",
    canActivate: [LoginGuard],
    component: SubmissionHistoryComponent,
    title: 'Joii PMS - Submission History'
  },
  {
    path: "videocheck",
    canActivate: [LoginGuard],
    component: VideoChecksComponent,
    title: 'Joii PMS - Video Check'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
