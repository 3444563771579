import { Component, OnInit, inject } from '@angular/core';
import { Firestore, Timestamp, doc, setDoc } from '@angular/fire/firestore';
import { Subscription, interval, of, startWith } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StaffProfileDataService } from 'src/pms/services/staffprofiles.data.service/staffprofiles.data.service';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';
import { bookinginternal } from 'src/shared/services/client/client';

interface StaffOnlineData {
  id: string;
  name: string;
  description: string;
  last_online: Timestamp;
  environment: string;
  photoUrl?: string;
}

@Component({
  selector: 'app-staffonlinewrite',
  templateUrl: './staffonlinewrite.component.html',
  standalone: true,
})

export class StaffOnlineWriteComponent implements OnInit {

  constructor(
    private staffProfileDataService: StaffProfileDataService,
    private authenticationService: AuthenticationService
  ) { }

  firestore: Firestore = inject(Firestore);

  staffProfile!: bookinginternal.GetStaffProfileResponse;
  private updateSubscription!: Subscription;
  collection = 'staffonline';
  intervalToCheckLoggedIn = 5000; // 5 seconds
  intervalToUpdateStaffProfile = 1800 * 1000; // 30 minutes
  intervalToSayIAMOnline = 10000; // 10 seconds

  ngOnInit() {
    this.fetchStaffData();
    this.updateStaffOnlineStatus();
  }

  ngOnDestroy() {
    if (this.updateSubscription) this.updateSubscription.unsubscribe();
  }

  fetchStaffData() {
    if (!this.authenticationService.isLoggedIn()) {
      setTimeout(() => this.fetchStaffData(), this.intervalToCheckLoggedIn);
    } else {
      this.staffProfileDataService.GetMyStaffProfile().subscribe({
        next: (response) => {
          this.staffProfile = response;
          setTimeout(() => this.fetchStaffData(), this.intervalToUpdateStaffProfile);
        },
        error: error => this.handlerError(error.message),
      });
    }
  }

  updateStaffOnlineStatus() {
    this.updateSubscription = interval(this.intervalToSayIAMOnline).pipe(
      startWith(0) // Start immediately
    ).subscribe(async () => {
      try {
        if (this.authenticationService.isLoggedIn() && this.staffProfile) {

          this.staffProfileDataService.GetMyStaffProfile().subscribe({
            next: (response) => {
              this.staffProfile = response;
            },
            error: error => this.handlerError(error.message),
          });

          const staffRef = doc(this.firestore, this.collection, this.staffProfile.id);
          const data: StaffOnlineData = {
            id: this.staffProfile.id,
            name: this.staffProfile.name ?? 'unknown',
            description: this.staffProfile.description ?? 'unknown',
            last_online: Timestamp.fromDate(new Date(Date.now())),
            environment: environment.environment,
            photoUrl: this.staffProfile.photo_url ?? '',
          };
          await setDoc(staffRef, data, { merge: true });
        }
      } catch (error) {
        console.error('Error updating staff online status:', error);
      }
    });
  }

  handlerError(error: any) {
    console.error(error);
  }

}

