import { NgModule } from '@angular/core';
import { ConsultationsRoutingModule } from './consultations-routing.module';

@NgModule({
  declarations: [],
  imports: [
    ConsultationsRoutingModule,
  ],
})
export class ConsultationsModule { }
