<div class="text-neutral-800 text-xs font-normal leading-[18px] tracking-tight">
    {{ description }}
</div>
<div
    *ngIf="!useCustomTemplate; else customTemplate"
    class="upload-container w-full h-full p-4"
    (dragover)="handleDragLeave($event)"
    (dragenter)="handleDragLeave($event)"
    (dragleave)="handleDragLeave($event)"
    (drop)="handleDrop($event)"
>
    <input
        [multiple]="multiple"
        accept="{{
            fileTypes.length > 0
                ? fileTypes
                : '.jpg, .jpeg, .png, .gif, .bmp, .svg, .tif, .tiff, .webp, .heic, .mp4, .pdf, .ttf, .mov, .doc, .docx'
        }}"
        type="file"
        #fileInput
        id="{{ uniqueId }}"
        hidden
        (change)="handleFileSelection($event)"
    />
    <label for="{{ uniqueId }}" class="upload-label h-full">
        <div
            class="w-full h-full upload-area flex flex-column text-center justify-center gap-2 items-center"
        >
            <lucide-icon
                *ngIf="displayUploadTooltip"
                name="image-plus"
                size="64"
            ></lucide-icon>
            <p *ngIf="displayUploadTooltip">{{ uploadText }}</p>
        </div>
    </label>
</div>
<ng-template #customTemplate>
    <div class="font-semibold joii-text-primary hover:underline cursor-pointer">
        <input
            type="file"
            #fileInput
            id="file-upload"
            hidden
            (change)="handleFileSelection($event)"
        />
        <label for="file-upload"
            ><ng-icon class="mr-1" name="ionAttach"></ng-icon
            >{{ uploadText }}</label
        >
    </div>
</ng-template>
