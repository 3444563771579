import { DecimalPipe, NgFor, NgIf } from '@angular/common';
import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgIconsModule } from '@ng-icons/core';
import { Subject, Subscription, debounceTime } from 'rxjs';
import { PaginationComponent } from '../pagination/pagination.component';

type ProductTableColumnTypes = 'sku' | 'name' | 'description' | 'price' | 'select';

@Component({
  selector: 'app-product-selection',
  templateUrl: './product-selection.component.html',
  styleUrl: './product-selection.component.scss',
  standalone: true,
  imports: [NgIconsModule, FormsModule, NgIf, NgFor, PaginationComponent, DecimalPipe]
})
export class ProductSelectionComponent implements OnInit, OnDestroy {
  @Input() productTableConfig: ProductTableColumnTypes[] = ['name', 'sku', 'description', 'price', 'select'];
  @Input() availableCategories: Array<any> = [];
  @Input() categoriesPaginationConfig: any;
  @Input() selectedSubCategories: Array<any> = [];
  @Input() selectedProducts: Array<any> = [];
  @Input() selectedProductsPaginationConfig: any;
  @Input() searchedProducts: Array<any> = [];
  @Input() searchedProductsPaginationConfig: any;
  @Input() loadingData: boolean = true;
  @Input() changingProduct: boolean = false;
  @Input() changeProductIndex: number = 0;
  @Input() multiProductSelect: boolean = false;
  @Output() getSelectedCategory: EventEmitter<any> = new EventEmitter<any>();
  @Output() getSelectedSubCategory: EventEmitter<any> = new EventEmitter<any>();
  @Output() getSearchResults: EventEmitter<any> = new EventEmitter<any>();
  @Output() getCategoryPage: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeCategoryPageSize: EventEmitter<any> = new EventEmitter<any>();
  @Output() emptySubCategories: EventEmitter<any> = new EventEmitter<any>();
  @Output() emptyProducts: EventEmitter<any> = new EventEmitter<any>();
  @Output() getProductPage: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeProductPageSize: EventEmitter<any> = new EventEmitter<any>();
  @Output() getSearchedProductPage: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeSearchedProductPageSize: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectProduct: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeProduct: EventEmitter<any> = new EventEmitter<any>();
  searchTerm: string = '';
  selectedCategory: string = '';
  selectedSubCategory: string = '';
  searchTerms = new Subject<string>();
  searchTermSubscription = new Subscription;

  ngOnInit() {
  this.searchTermSubscription = this.searchTerms.pipe(
      debounceTime(500),
    ).subscribe(term => {
      this.getSearchResults.emit(term);
    });
  }

  selectCategory(category: any) {
    this.selectedCategory = category.Name;
    this.getSelectedCategory.emit(category);
  }

  selectSubCategory(category: any) {
    this.selectedSubCategory = category.name;
    this.getSelectedSubCategory.emit(category.doc_id);
  }

  clearSubCategory() {
    this.selectedCategory = '';
    this.selectedSubCategory = '';
    this.emptySubCategories.emit();
    if (this.selectedProducts.length > 0) {
      this.clearProducts();
    }
  }

  clearProducts() {
    this.selectedSubCategory = '';
    this.emptyProducts.emit();
  }

  clearSearch() {
    this.searchTerm = '';
    this.getSearchResults.emit(this.searchTerm);
  }

  handleCategoryPagination(page: any) {
    this.getCategoryPage.emit(page);
  }

  handleCategoryPageSizeChange(pageSize: any) {
    this.changeCategoryPageSize.emit(pageSize);
  }

  handleProductPagination(page: any) {
    this.getProductPage.emit(page);
  }

  handleProductPageSizeChange(pageSize: any) {
    this.changeProductPageSize.emit(pageSize);
  }

  handleProductSelect(product: any) {
    if (this.changingProduct) {
      this.changeProduct.emit(product);
    } else {
      this.selectProduct.emit(product);
    }
  }

  handleProductSearch() {
    this.searchTerms.next(this.searchTerm);
  }

  handleProductSearchPagination(page: any) {
    this.getSearchedProductPage.emit(page);
  }

  handleProductSearchPageSizeChange(pageSize: any) {
    this.changeSearchedProductPageSize.emit(pageSize);
  }

  ngOnDestroy() {
    this.searchTermSubscription.unsubscribe();
  }
}
