<div class="flex flex-row">
    <div class="w-1/3">
        <div class="relative w-full aspect-1">
            <div #preview class="absolute w-full bottom-0 left-0 z-10 border-1 border-white"></div>
        </div>
        <ng-icon name="ionMic"></ng-icon>
        <progress [value]="audioLevel" max="1"></progress>
    </div>
    <div class="w-1/3 shadow-lg box-content p-4 border-2 rounded">
        <div class="flex flex-col">
            <div class="flex flex-row">
                <label>Connectivity test</label>
                <ng-icon name="ionCheckmarkCircleOutline" class="text-green-600" *ngIf="connectivityOk"></ng-icon>
                <ng-icon name="ionRefresh" class="animate-spin text-orange-600" *ngIf="!connectivityTest"></ng-icon>
                <ng-icon name="ionCloseCircleOutline" class="text-red-600" *ngIf="connectivityOk == false"></ng-icon>
            </div>
            <div class="flex flex-row">
                <label>Video Quality Test</label>
                <ng-icon name="ionCheckmarkCircleOutline" class="text-green-600" *ngIf="videoOk"></ng-icon>
                <ng-icon name="ionRefresh" class="animate-spin text-orange-600" *ngIf="!qualityTest"></ng-icon>
                <ng-icon name="ionCloseCircleOutline" class="text-red-600" *ngIf="videoOk == false"></ng-icon>
            </div>
            <div class="flex flex-row">
                <label>Audio Connectivity Test</label>
                <ng-icon name="ionCheckmarkCircleOutline" class="text-green-600" *ngIf="audioOk"></ng-icon>
                <ng-icon name="ionRefresh" class="animate-spin text-orange-600" *ngIf="!qualityTest"></ng-icon>
                <ng-icon name="ionCloseCircleOutline" class="text-red-600" *ngIf="audioOk == false"></ng-icon>
            </div>
            <app-expandable-div title="Advanced Results">
                {{connectivityTest | json}}
                {{qualityTest | json}}
            </app-expandable-div>
            <button class="joii-btn-primary mt-4 disabled:opacity-50" (click)="proceed()"
                [disabled]="!connectivityOk || !audioOk || !videoOk"
                data-automationid="videoTestProceedButton">{{proceedButtonText}}</button>
        </div>
    </div>