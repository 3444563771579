import { NgIf, NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NgIconsModule } from '@ng-icons/core';

@Component({
  selector: 'app-expandable-div',
  templateUrl: './expandable-div.component.html',
  standalone: true,
  imports: [NgIf, NgClass, NgIconsModule]
})

export class ExpandableDivComponent {
  @Input()
  title!: string;

  @Input() showContent?: boolean = false;

  @Input() disableExpansion: boolean = false;

  toggle() {
    if (this.disableExpansion)
      return;

    this.showContent = !this.showContent
  }
}
