import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginGuard } from 'src/shared/guards/login.guard';
import { PetAndOwnerHistoryComponent } from './pages/pet-and-owner-history/pet-and-owner-history.component';
import { HistoryComponent } from './pages/history/history.component';
import { PetSummaryComponent } from './pages/pet-summary/pet-summary.component';
import { UnsavedChangesGuard } from 'src/shared/guards/unsavedChangesGuard.guard';


const routes: Routes = [
  {
    path: 'history',
    loadComponent: () => import('./pages/history/history.component').then(m => m.HistoryComponent),
    canActivate: [LoginGuard],
    children: [
      {
        path:'',
        redirectTo: 'petHistory',
        pathMatch: 'full'
      },
      {
        path: "consultationHistory",
        loadComponent: () => import('./pages/consultationHistory/consultation-history.component').then(m => m.ConsultationHistoryComponent),
        title: 'Joii PMS - Consultation History'

      },
      {
        path: "petHistory",
        loadComponent: () => import('./pages/pet-and-owner-history/pet-and-owner-history.component').then(m => m.PetAndOwnerHistoryComponent),
        title: 'Joii PMS - Pet & Owner History'

      },
    ]
  },
  {
    path: "petSummary/:userId/:petId",
    canActivate: [LoginGuard],
    loadComponent: () => import('./pages/pet-summary/pet-summary.component').then(m => m.PetSummaryComponent),
    title: 'Joii PMS - Pet Summary'
  },
  {
    path: "consultationSummary/:id",
    canActivate: [LoginGuard],
    loadComponent: () => import('./pages/consultationSummary/consultation-summary.component').then(m => m.ConsultationSummaryComponent),
    title: 'Joii PMS - Consultation Summary'
  },
  {
    path: "consultation/:id",
    canActivate: [LoginGuard],
    loadComponent: () => import('./pages/consultation/consultation.component').then(m => m.ConsultationComponent),
    canDeactivate: [UnsavedChangesGuard],
    title: 'Joii PMS - Consultation'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ConsultationsRoutingModule { }
