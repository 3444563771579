import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { PMSModule } from './pms/pms.module';
import { Formio } from 'formiojs';
import premium from '@formio/premium';
import tailwind from "@tsed/tailwind-formio";
import * as Sentry from "@sentry/angular-ivy";
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';


Formio.use(premium);
Formio.use(tailwind)

if (environment.production) {
  Sentry.init({
    dsn: "https://630d919edd9a086c3bcd47921e9dae95@o4506773953773568.ingest.sentry.io/4506773993422848",
    release: `joii@${environment.VERSION}`,
    dist: '1',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    environment: environment.environment,
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(PMSModule)
  .catch(err => console.error(err));
