<div class="grid grid-cols-1 gap-x-8 gap-y-4 md:grid-cols-2" [@list]="pets().length">
    @for(pet of pets(); track pet.doc_id) {
        <div class="w-full relative cursor-pointer transition-all duration-200 scale-100 ease-in-out hover:scale-105" (click)="petSelected.emit(pet)" [@animate]="true" [tabIndex]="0" (keyup.enter)="petSelected.emit(pet)">
            <div class="absolute top-3 right-5">
                <ng-icon name="ionPawOutline" color="white" size="20px"></ng-icon>
            </div>
            <div class="absolute top-7 right-10">
                <ng-icon name="ionPawOutline" color="white" size="20px"></ng-icon>
            </div>
            <div (click)="petSelected.emit(pet)" class="w-24 h-24 bg-white rounded-full absolute top-3 left-4 flex justify-center items-center cursor-pointer">
                @if (pet.photo_url) {
                    <img class="w-22 h-22 rounded-full object-cover" [src]="pet.photo_url"/>
                } @else {
                    <div class="w-22 h-22 rounded-full object-cover flex justify-center items-center">
                        @if (pet.species === 'Dog') {
                            <svg width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="Dog icon 3">
                                <path id="Vector" d="M74.7565 40.436C72.1165 26.213 62.2165 16.28 44.4955 15.906L44.5175 15.873C44.3415 15.873 44.1765 15.884 44.0005 15.895C43.8245 15.895 43.6595 15.873 43.4835 15.873L43.4615 15.906C25.6085 16.291 15.8845 26.224 13.2445 40.436C10.5825 54.791 14.6195 52.899 22.3415 53.669C22.2095 52.701 22.1105 51.711 22.1105 50.699V33.396C22.1105 33.242 22.2315 33.121 22.3855 33.121C22.5395 33.121 22.6605 33.242 22.6605 33.396V50.699C22.6605 62.359 32.1425 71.841 43.8025 71.841C55.4625 71.841 64.9445 62.359 64.9445 50.699V33.187C64.9445 33.033 65.0655 32.912 65.2195 32.912C65.3735 32.912 65.4945 33.033 65.4945 33.187V50.699C65.4945 51.711 65.3955 52.712 65.2635 53.691H65.4285C73.2935 52.8661 77.4405 54.934 74.7455 40.436H74.7565ZM47.8175 59.158L45.7055 60.764C44.6935 61.534 43.2965 61.534 42.2955 60.764L40.1835 59.158C38.0385 57.519 39.1935 54.098 41.8885 54.098H46.1235C48.8185 54.098 49.9735 57.519 47.8285 59.158H47.8175Z" fill="#207EA7"/>
                                </g>
                            </svg>
                        } @else {
                            <svg width="120" height="120" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="Cat icon 3">
                                <path id="Vector" d="M65.4839 20.7236L55.2869 29.9086C55.0889 30.0846 54.7919 30.0956 54.5719 29.9416L53.9229 29.5786C47.5539 26.0366 39.7659 26.2236 33.5729 30.0626C33.3639 30.2386 33.0449 30.2386 32.8359 30.0516L22.5069 20.7236C21.9569 20.2286 21.0879 20.6136 21.0879 21.3506V45.4076C21.0879 57.5956 30.9659 67.4736 43.1539 67.4736H44.8259C57.0139 67.4736 66.8919 57.5956 66.8919 45.4076V21.3396C66.8919 20.6026 66.0229 20.2176 65.4729 20.7126L65.4839 20.7236ZM47.9499 54.2736L45.4199 55.5276C44.9029 55.7806 44.2979 55.7806 43.7699 55.5276L41.2399 54.2736C39.4799 53.4046 40.0959 50.7536 42.0649 50.7536H47.1139C49.0719 50.7536 49.6989 53.4046 47.9389 54.2736H47.9499Z" fill="#B15420"/>
                                </g>
                            </svg>
                        }
                    </div>
                }

            </div>
            <div class="h-14 bg-cyan-700 rounded-tl-[10px] rounded-tr-[10px]">
            </div>
            <div class="h-14 bg-white rounded-[10px] shadow">
                <div class="ml-28 mr-3 pt-2 flex justify-between items-center">
                    <div class="text-neutral-800 text-xl font-bold leading-[30px] tracking-tight">{{ pet.name }}</div>
                    <div (click)="petSelected.emit(pet)" class="cursor-pointer">
                        <div class="rounded-full w-[36px] h-[36px] bg-neutral-100 flex justify-center items-center">
                            <ng-icon name="ionChevronForward" color="#207EA7" size="24px"></ng-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

</div>
