@if (filteredVenomCodesCategories){
<main class="venom-code-picker-wrapper">
  <app-label *ngIf="label" [label]="label"></app-label>
  <div class="multi-select-holder" #pickerInput>
    <div
      class="list-wrapper bg-gray-50 dark:bg-slate-700 block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300">
      <span *ngIf="selectedCodes.length === 0 && selectedGroups.length === 0"
        class="joii-chip-place-holder text-gray-400 text-sm">
        <span>Please select</span>
      </span>
      <div class="list-bg cursor-pointer" data-automationid="venom-codes-input-trigger"  (click)="triggerDisplayOverlay()"></div>
      <div *ngIf="selectedCodes.length > 0 || selectedGroups.length > 0" class="joii-chip-holder">
        <ng-container *ngFor="let group of selectedGroups; index as i;">
          <span class="joii-chip text-sm text-white bg-blue-400 rounded-sm">
            {{group.name}}
            <ng-icon name="ionCloseOutline" (click)="removeSelection('group', i)"></ng-icon>
          </span>
        </ng-container>
        <ng-container *ngFor="let code of selectedCodes; index as i;">
          <span class="joii-chip text-sm text-white bg-blue-700 rounded-sm">
            {{code.name}}
            <ng-icon name="ionCloseOutline" class="cursor-pointer" (click)="removeSelection('code', i)"></ng-icon>
          </span>
        </ng-container>
      </div>
    </div>
  </div>
  <span class="picker-trigger-icon" (click)="triggerDisplayOverlay()">
    <ng-icon *ngIf="!displayOverlay" name="ionChevronDownOutline"></ng-icon>
    <ng-icon *ngIf="displayOverlay" name="ionChevronUpOutline"></ng-icon>
  </span>
  @if(displayOverlay) {
  <section class="picker-overlay mt-1 bg-white dark:bg-slate-700 w-full shadow-md" [class.top]="overlayTop">
    <header class="p-2 px-3 bg-slate-100 dark:bg-slate-500">
      <input #searchInput [(ngModel)]="pickerSearchTerm" (keyup)="searchSubject.next(pickerSearchTerm)"
        placeholder="Search"
            data-automationid="venom-codes-search-input"
        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
      <span class="search-icon" *ngIf="!pickerSearchTerm">
        <ng-icon name="ionSearchOutline"></ng-icon>
      </span>
      <span class="clear-icon" *ngIf="pickerSearchTerm">
        <ng-icon name="ionCloseOutline" (click)="clearSearch()"></ng-icon>
      </span>
    </header>
    @if (categoryFilters.length > 0) {
    <section class="p-4">
      <h6 class="text-gray-500 dark:text-gray-100 text-sm font-medium tracking-tight">Filter category</h6>
      <div class="flex flex-wrap gap-2 mt-2">
        @for (item of categoryFilters; track item.doc_id) {
        <span (click)="filterByCategory(item)"
          class="p-1.5 border border-blue rounded-md flex items-center justify-start text-primary dark:text-white text-sm font-medium cursor-pointer hover:bg-primary hover:text-white"
          [ngClass]="{'bg-primary text-white': item.selected}">
          {{item.name}}
        </span>
        }
      </div>
    </section>
    }
    @if (filteredVenomCodesCategories.length > 0) {
    <cdk-virtual-scroll-viewport [itemSize]="40" class="h-[220px]">
      <ng-container *cdkVirtualFor="let item of filteredVenomCodesCategories" cdkVirtualForTemplateCacheSize="0">
        @switch (item.type) {
        @case('category') {
        <ng-container *ngTemplateOutlet="Category; context: { category: item }"></ng-container>
        }
        @case('group') {
        <ng-container *ngTemplateOutlet="Group; context: { categoryGroup: item }"></ng-container>
        }
        @case('code') {
        <ng-container *ngTemplateOutlet="Code; context: { venomCode: item }"></ng-container>
        }
        }
      </ng-container>

    </cdk-virtual-scroll-viewport>
    }
  </section>
  }

    <section
      *ngIf="displayOverlay"
      class="picker-bg"
      data-automationid="venom-codes-picker-backdrop"
      (click)="closeDisplayOverlay()"></section>
  </main>
} @else {
<section>
  <p>Missing venom codes</p>
</section>
}

<ng-template #Category let-category="category">
  <article class="pt-2 pl-4 h-[40px] w-full">
    <div class="text-neutral-800 dark:text-white text-base font-medium truncate">{{category.name}}</div>
  </article>
</ng-template>

<ng-template #Group let-categoryGroup="categoryGroup">
  <div class="relative flex items-start pl-7 py-2 pr-4 h-[40px]" data-automationid="venom-codes-picker-checkbox">
    <div class="flex h-6 items-center">
      <input [id]="categoryGroup.name" [checked]="categoryGroup.selected" (change)="handleSelection(categoryGroup)"
        aria-describedby="checkbox input" type="checkbox"
        class="h-4 w-4 rounded border-gray-300 text-primary dark:text-primary-100 focus:ring-primary">
    </div>
    <div class="ml-3 text-sm leading-6">
      <label for="{{categoryGroup.name}}"
        class="text-base font-normal text-slate-800 dark:text-white truncate">{{categoryGroup.name}}</label>
    </div>
  </div>
</ng-template>

<ng-template #Code let-venomCode="venomCode">
  <div class="relative flex items-start pl-12 py-2 pr-4 h-[40px]" data-automationid="venom-codes-picker-checkbox">
    <div class="flex h-6 items-center">
      <input [id]="venomCode.name" [checked]="venomCode.selected" (change)="handleSelection(venomCode)"
        aria-describedby="checkbox input" type="checkbox"
        class="h-4 w-4 rounded border-gray-300 text-primary dark:text-primary-light focus:ring-primary">
    </div>
    <div class="ml-3 text-sm leading-6">
      <label for="{{venomCode.name}}"
        class="text-base font-normal text-slate-800 dark:text-white truncate">{{venomCode.name}}</label>
    </div>
  </div>
</ng-template>