import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription, catchError, forkJoin, of } from 'rxjs';
import { DetailsListConfig, DetailsListItemType } from 'src/shared/components/design/detailslist/detailslist.interface';
import { bookingexternal, bookinginternal } from 'src/shared/services/client/client';
import { AppointmentsDataService } from '../../services/appointments.data.service/appointments.data.service';
import { ConsultationService } from 'src/shared/services/consultations/consultations.service';

@Component({
  selector: 'app-appointmentdetails',
  templateUrl: './appointmentdetails.component.html'
})

export class AppointmentDetailsComponent implements OnInit, OnDestroy {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appointmentsDataService: AppointmentsDataService,
    private consultationService: ConsultationService) { }


  ngOnDestroy(): void {
    this.createConsultationSubscription?.unsubscribe();
    this.continueConsultationSubscription?.unsubscribe();
  }

  get id() {
    return this.route.snapshot.paramMap.get('id');
  }

  ngOnInit(): void {
    this.load();
  }

  appointment$!: Observable<bookinginternal.GetAppointmentResponse>;
  createConsultationSubscription?: Subscription;
  continueConsultationSubscription?: Subscription;

  load() {
    this.appointment$ = this.appointmentsDataService.GetAppointment(this.id ?? "", {
      // TODO - set this from cetnral service
      Timezone: "Europe/London"
    }).pipe(
      catchError((err) => {
        return of(err);
      })
    );
  }

  back() {
    this.router.navigate([`/appointments`]);
  }

  start(petId: string, ownerId: string) {
    this.createConsultationSubscription =
      forkJoin([
        this.consultationService.CreateConsultation({
          appointment_id: this.id!,
          pet_id: petId,
          owner_id: ownerId,
          notes: ''
        }),
        this.appointmentsDataService.UpdateAppointmentConsultStatus(this.id ?? "", {
          consult_status: "Ready",
          disrupted_reason: ""
        })]).subscribe((resp) => {
          this.router.navigate([`/consultation/${resp[0].id}`]);
        })
  }
  continue(appointmentId: string) {
    this.continueConsultationSubscription = this.consultationService.GetConsultationIdByAppointment(appointmentId).subscribe(resp => {
      this.router.navigate([`/consultation/${resp.consultation_doc_id}`]);
    })
  }

  getConfig(data: bookinginternal.GetAppointmentResponse): DetailsListConfig {
    let config: DetailsListConfig = {
      items: [
        {
          id: 'start_time',
          title: 'Start Time',
          content: data.start.time_friendly,
          type: DetailsListItemType.Text,
        },
        {
          id: 'end_time',
          title: 'End Time',
          content: data.end.time_friendly,
          type: DetailsListItemType.Text,
        },
        {
          id: 'status',
          title: 'Status',
          content: data.status,
          type: DetailsListItemType.Text,
        },
      ],
    }
    return config;
  }

}
