import { Component, EventEmitter, HostBinding, Injector, Input, OnChanges, OnInit, Optional, Output, SimpleChanges, forwardRef } from '@angular/core';
import { SelectModel } from '../select/select_model';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, ValidationErrors, NgControl, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { isEqual } from 'lodash';
import { LabelComponent } from '../label/label.component';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { NgIconsModule } from '@ng-icons/core';
import { ValidationComponent } from '../validation/validation.component';

@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrl: './phone-input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => PhoneInputComponent),
    }
  ],
  standalone: true,
  imports: [LabelComponent, NgIf, NgClass, NgIconsModule, FormsModule, ReactiveFormsModule, NgFor, ValidationComponent]
})

export class PhoneInputComponent implements ControlValueAccessor, OnInit, OnChanges {
  @Input() label!: string;
  @Input() name!: string;
  @Input() placeholder!: string;
  @Input() type: string = "text";
  @Input() readonly: boolean = false;
  @Input() span!: number;
  @Input() errormessage!: string;
  @Input() errorPositionAbsolute: boolean = false;
  @Input() countryCodeOptions: SelectModel[] = [];
  @Input() selectedCountryCode?: SelectModel = {
    name: "GB",
    value: "GB",
    data: "+44"
  }


  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  value!: string | null;
  errors!: ValidationErrors | null | undefined;
  disabled = false;
  showPostcodeDropdown: boolean = false;

  @Output() countryCodeChange: EventEmitter<string> = new EventEmitter<string>();
  @HostBinding('class') get t() {
    return "p-0 border-0 sm:col-span-" + this.span;
  };

  onChange: any = (value: any) => {
  };
  onTouched: any = () => {
  };

  formControl!: NgControl | null;

  constructor(
    private injector: Injector,
    @Optional() private formDirective: FormGroupDirective
  ) { }

  get ngControl(): NgControl | null {
    return this.injector.get(NgControl, null);
  }

  get submitted() {
    return this.formDirective ? this.formDirective.submitted : false;
  }

  ngOnInit() {
    this.formControl = this.ngControl;
    this.countryCodeChange.emit(this.selectedCountryCode?.value);

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.formControl && !isEqual(changes?.['value']?.previousValue, changes?.['value']?.currentValue)) {
      this.value = this.ngControl?.value;
      this.onChange(this.value);
      this.onTouched();
    }
  }

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  onInputChange(newValue: string) {
    if (newValue !== this.value) {
      this.value = newValue;
      this.onChange(this.value);
      this.valueChange.emit(this.value ?? undefined);
    }
    this.onTouched();
  }

  onInputBlur(event: Event) {
    const newValue = (event.target as HTMLInputElement).value;
    if (newValue !== this.value) {
      this.value = newValue;
      this.onChange(this.value);
    }
    this.onTouched();
  }

  togglePostcodeDropdown() {
    this.showPostcodeDropdown = !this.showPostcodeDropdown;
  }

  selectCountry(option: any): void {
    this.showPostcodeDropdown = false;
    this.selectedCountryCode = option;

    this.countryCodeChange.emit(option.value);
  }
}
