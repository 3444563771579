<div class="relative flex items-start">
  <div class="flex h-6 items-center">
    <input
      [id]="name"
      [readonly]="readonly"
      [disabled]="disabled"
      [name]="name"
      [ngModel]="value"
      (ngModelChange)="onInputChange($event)"
      aria-describedby="checkbox input"
      type="checkbox"
      class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
    >
  </div>
  <div class="ml-3 text-sm leading-6">
    <label for="{{name}}" class="font-medium text-gray-900">{{label}}</label>
    @if (description) {
      <p id="checkbox-description" class="text-gray-500">{{description}}</p>
    }
  </div>
</div>
<app-validation *ngIf="formControl || errormessage" [control]="formControl" [submitted]="submitted" [errormessage]="errormessage"></app-validation>
