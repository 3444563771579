import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';

import { Observable, Subscription, map } from 'rxjs';
import { AppointmentsDataService } from 'src/pms/appointments/services/appointments.data.service/appointments.data.service';
import { FileExtensionPipe } from 'src/shared/pipes/file-extension/file-extension.pipe';
import { bookinginternal, scinternal, usersubmission } from 'src/shared/services/client/client';
import { ConsultationService } from 'src/shared/services/consultations/consultations.service';
import { ImageCorouselDataService } from 'src/shared/services/imageCarousel.data.service/image-carousel.data.service';
import { UserSubmissionsService } from 'src/shared/services/user-submissions.data.service/usersubmissions.service';




@Component({
  selector: 'app-owner-observations',
  templateUrl: './owner-observations.component.html',
  styleUrl: './owner-observations.component.scss',
  standalone: true,
  imports: [FileExtensionPipe, AsyncPipe, NgIf, NgClass]
})
export class OwnerObservationsComponent implements OnDestroy, OnInit {
  constructor(
    private appointmentDataService: AppointmentsDataService, 
    private consultationService: ConsultationService, 
    private userSubmissionsService: UserSubmissionsService) {

  }
  ngOnInit(): void {
    this.reloadAppointment()
  }
  ngOnDestroy(): void {
    this.reloadSubscription?.unsubscribe();
    this.sympomCheckerSubscription?.unsubscribe();
    this.userSubmissionsSubscription?.unsubscribe();
  }
  @Input()
  appointmentDetails!: bookinginternal.GetAppointmentResponse
  reloadSubscription?: Subscription;
  sympomCheckerSubscription?: Subscription;
  userSubmissionsSubscription?: Subscription;
  showImageModal = false
  symptomCheckerResponse$?: Observable<scinternal.SymptomCheckerSummary>
  imageCarouselDataService = inject(ImageCorouselDataService)
  userSubmissionsResponse$?: Observable<usersubmission.ListUserSubmissionsResponse>
  imageurl: string | undefined
  videourl: string | undefined


  reloadAppointment() {
    this.reloadSubscription = this.appointmentDataService.GetAppointment(this.appointmentDetails.doc_id, { Timezone: "Europe/London" }).subscribe(resp => {
      this.appointmentDetails = resp;
      this.imageCarouselDataService.updateImages(resp.appointment_photos_urls)
      //this.allImages = resp.appointment_photos_urls;
      if (this.appointmentDetails.symptom_checker_session_doc_id) {
        this.symptomCheckerResponse$ = this.consultationService.GetSymptomCheckerOverview(this.appointmentDetails.symptom_checker_session_doc_id);
        this.sympomCheckerSubscription = this.symptomCheckerResponse$.subscribe(scResp => {
          //this.allImages = this.allImages.concat(scResp.media);
          this.imageCarouselDataService.updateImages(this.imageCarouselDataService.images().concat(scResp.media))
        })
      }

      if (this.appointmentDetails.typeform_event_id) {
        console.log("-----", this.appointmentDetails.typeform_event_id)
        this.userSubmissionsResponse$ = this.userSubmissionsService.ListUserSubmissions({
          EventID: this.appointmentDetails.typeform_event_id,
          PetDocID: '',
          ExcludeEventIDs: [],
          RequestedItemsPerPage: 10,
          RequestedPageNumber: 1
        });

        this.userSubmissionsSubscription = this.userSubmissionsResponse$.subscribe(submissionResp => {
         console.log(submissionResp)
        })
      }
    })
  }

  showModal() {
    this.imageCarouselDataService.showModal();
  }

}
