import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { Component, Input, OnInit, inject } from '@angular/core';
import { FileExtensionPipe } from 'src/shared/pipes/file-extension/file-extension.pipe';
import { ImageCorouselDataService } from 'src/shared/services/imageCarousel.data.service/image-carousel.data.service';
import { HealthCheckData } from './health-check-data.interface';
import { SignUrlService } from 'src/shared/services/sign-urls/sign.service';

@Component({
  selector: 'app-health-check-data',
  templateUrl: './health-check-data.component.html',
  styleUrl: './health-check-data.component.scss',
  standalone: true,
  imports: [FileExtensionPipe, AsyncPipe, NgIf, NgClass]
})
export class HealthCheckDataComponent implements OnInit {

  @Input() healthCheckData!: HealthCheckData;
  petImages: string[] = [];
  showImageModal = false
  walkingVideos: string[] = [];

  imageCarouselDataService = inject(ImageCorouselDataService)
  signUrlService = inject(SignUrlService);

  constructor() {}

  ngOnInit(): void {
    this.signImages()
  }

  signImages() {
    this.signUrlService.signUrl({ URLS: [...this.healthCheckData.bodyImages, ...this.healthCheckData.teethImages] }).subscribe(petImages => {
      this.petImages = petImages.urls;
      this.imageCarouselDataService.updateImages(petImages.urls);
    });
    this.signUrlService.signUrl({URLS: this.healthCheckData.walkingVideos}).subscribe(walkingVideos => {
      this.walkingVideos = walkingVideos.urls;
    });
  }

  showModal() {
    this.imageCarouselDataService.showModal();
  }

}
