import { Component, EventEmitter, Input, Output, computed, inject, input } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { DarkModeService } from 'src/shared/services/darkmode/darkmode_service';
import { NgIconsModule } from '@ng-icons/core';
import { NgClass, NgIf } from '@angular/common';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
  standalone: true,
  imports: [NgIconsModule, NgClass, NgIf],
  animations: [
    trigger('slideInOut', [
      state('in-left', style({
        transform: 'translateX(0)',
      })),
      state('out-left', style({
        transform: 'translateX(-100%)',
      })),
      state('in-right', style({
        transform: 'translateX(0)',
      })),
      state('out-right', style({
        transform: 'translateX(100%)',
      })),
      transition('* => in-left', animate('0.3s ease')),
      transition('in-left => *', animate('0.3s ease')),
      transition('* => out-left', animate('0s')), // No animation for initial state
      transition('out-left => *', animate('0.3s ease')),
      transition('* => in-right', animate('0.3s ease')),
      transition('in-right => *', animate('0.3s ease')),
      transition('* => out-right', animate('0s')), // No animation for initial state
      transition('out-right => *', animate('0.3s ease')),
    ]),
  ],
})
export class SidebarComponent {
  isOpen = input(false);
  hideClose = input(false);
  position = input('left');
  wide = input(false);
  styleClass = input<string>('');
  animationState = computed(() => this.getAnimationState(this.isOpen(), this.position()));
  @Output() close = new EventEmitter();
  darkModeService: DarkModeService = inject(DarkModeService)


  closeSidebar() {
    this.close.emit();
  }

  getAnimationState(open: boolean, position: string) {
    return open ? 'in-' + position : 'out-' + position;
  }
}
