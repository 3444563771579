import { JsonPipe, NgClass } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild, effect, input, signal } from '@angular/core';
import Editor from '@toast-ui/editor';
import { QuillModule } from 'ngx-quill';

@Component({
  selector: 'app-rich-text-viewer',
  standalone: true,
  imports: [QuillModule, JsonPipe, NgClass],
  templateUrl: './rich-text-viewer.component.html',
  styleUrl: './rich-text-viewer.component.scss'
})
export class RichTextViewerComponent implements OnInit, OnDestroy {
  @ViewChild('container', {static: true}) set EditorElementSetter(el: ElementRef) {
    this.EditorElement = el;
  }
  editor!: Editor;
  richText = input<string>('');
  styleClasses = input<string>('');
  html = signal<string>(''); // Signal to update the HTML output.

  private EditorElement!: ElementRef<HTMLDivElement>;

  isHTML(str: string): boolean {
    // Check if the string contains HTML tags.
    const htmlRegex = /<[^>]+>/g;
    return htmlRegex.test(str);
  }

  constructor() { 
    effect(() => {
      // Run rich text markdown conversion through toastUI editor. This will correctly output the markdown to HTML.
      this.isHTML(this.richText()) ? this.editor?.setHTML(this.richText())  : this.editor?.setMarkdown(this.richText());
      this.html.set(this.editor?.getHTML());
    }, {allowSignalWrites: true});
  }

  ngOnInit(): void {
    // Set the intital rich text value.
    this.editor = new Editor({
      el: this.EditorElement.nativeElement,
      viewer: true,
      initialValue: this.richText(),
      autofocus: false
    } as any);
    this.html.set(this.editor?.getHTML());
    this.editor?.blur();
  }

  ngOnDestroy(): void {
    // Destroy the editor when the component is destroyed.
    this.editor?.destroy();
  }
}
