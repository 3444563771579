import { Component, computed, EventEmitter, inject, Input, OnDestroy, OnInit, Output, signal, effect } from '@angular/core';
import { NgIconsModule } from '@ng-icons/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AfiClinicalHistoryService } from 'src/shared/services/afi-clinical-history/afi-clinical-history.service';
import { accountinternal } from 'src/shared/services/client/client';
import { WINDOW } from 'src/shared/shared.module';

@Component({
  selector: 'app-afi-clinical-history',
  standalone: true,
  imports: [NgIconsModule],
  templateUrl: './afi-clinical-history.component.html',
  styleUrl: './afi-clinical-history.component.scss'
})
export class AfiClinicalHistoryComponent implements OnDestroy {


  @Input()
  petName!: string;
  @Input()
  ownerSurname!: string;
  @Input()
  policyNo!: string

  @Output()
  addressChanged = new EventEmitter<string>();

  window = inject(WINDOW);

  toastService = inject(ToastrService);
  clinicalHistoryService = inject(AfiClinicalHistoryService)
  response?: accountinternal.GetAFIClinicalHistoryResponse;
  getHistorySubscription?: Subscription;
  getDocumentSubscription?: Subscription;
  responseReceived = signal<boolean>(false)
  afiResponse = signal<accountinternal.GetAFIClinicalHistoryResponse | undefined>(undefined)
  address = computed(() => this.afiResponse()?.policyAddress)
  afiDocuments = computed(() => this.afiResponse()?.documents.map(x => {
    return {
      url: x.url,
      seen: false
    }
  }))
  constructor() {
    effect(() => {
      if (this.address()) {
        this.addressChanged.emit(`${this.address()?.building}\n${this.address()?.street}\n${this.address()?.town}\n${this.address()?.postcode}`)
      }
    })
  }

  ngOnDestroy(): void {
    this.getDocumentSubscription?.unsubscribe()
    this.getHistorySubscription?.unsubscribe()
  }

  getHistory() {
    this.getHistorySubscription = this.clinicalHistoryService.GetClinicalHistory({
      PetName: this.petName,
      PolicyHolderSurname: this.ownerSurname,
      PolicyNoLong: this.policyNo
    }).subscribe({
      next: resp => {
        this.afiResponse.set(resp)
      }, error: error => {
        this.toastService.error(error.message)
      }
    })
  }

  getDocument(doc: AfiDocument) {
    doc.seen = true
    this.getDocumentSubscription = this.clinicalHistoryService.GetClincialHistoryDocument({ Url: doc.url }).subscribe(resp => {

      const byteArray = new Uint8Array(atob(resp.response).split('').map(char => char.charCodeAt(0)));
      const file = new Blob([byteArray], { type: 'application/pdf' });
      var fileURL = URL.createObjectURL(file);
      this.window.open(fileURL);
    })
  }
  openDrive() {
    this.window.open("https://drive.google.com/drive/u/0/folders/1Y4aTrsq9ckte6-CicYSHQcW440cZcxUr")
  }
}

interface AfiDocument {
  url: string
  seen: boolean
}
