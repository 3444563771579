<app-joii-modal [showModal]="isLinkModalOpen" [position]="modalPosEnum"
    [customArticleClasses]="'border border-blue-400 bg-white rounded-md w-[647px] h-[356px]'"
    [customArticleStyles]="{'box-shadow': '0px 10px 40px 10px rgba(0, 0, 0, 0.078)'}"
    [modalBackground]="'bg-transparent'" (closeModal)="closeLinkModal()">

    <form [formGroup]="linkForm" (ngSubmit)="submitLink()">
        <h1 class="text-neutral-800 dark:text-white text-2xl mb-4 font-semibold font-['Inter'] tracking-tight">Edit Link
        </h1>

        <div class="self-stretch justify-between items-start flex">
            <label for="displayText"
                class="text-neutral-800 dark:text-white text-sm font-medium font-['Inter'] leading-tight tracking-tight">Display
                text</label>
            <span
                class="block text-gray-500 dark:text-white text-sm font-medium font-['Inter'] tracking-tight">Optional</span>
        </div>
        <input
            class="dark:text-black w-full mb-4 mt-1 px-3 py-2 border border-stone-200 rounded-lg shadow  hover:border-blue-400 grow shrink basis-0 placeholder-neutral-800 placeholder:{text-[15px] font-medium font-['Inter'] tracking-tight}"
            id="displayText" type="text" formControlName="displayText" placeholder="Please type" />

        <label class="text-neutral-800 dark:text-white text-sm font-medium font-['Inter'] leading-tight tracking-tight"
            for="linkUrl">Web
            address</label>
        <input
            class="dark:text-black w-full mb-4 mt-1 px-3 py-2 border border-stone-200 rounded-lg shadow  hover:border-blue-400 grow shrink basis-0 placeholder-neutral-800 placeholder:{text-[15px] font-medium font-['Inter'] tracking-tight}"
            [ngClass]="{
                'shadow border border-red-50': linkForm.get('linkUrl')?.invalid && linkForm.get('linkUrl')?.touched
            }" for="linkUrl" type="url" formControlName="linkUrl" placeholder="Please select" />
        <div *ngIf="linkForm.get('linkUrl')?.invalid && linkForm.get('linkUrl')?.touched"
            class="text-red-500 text-sm font-medium font-['Inter'] tracking-tight">
            Please enter a valid format (e.g. https://www.website.com)
        </div>

        <div class="flex justify-end items-center pt-3 space-x-1">
            <button type="button" (click)="closeLinkModal()"
                class="w-[83px] h-[46px] px-4 py-3.5 rounded-lg justify-center items-center gap-2.5 inline-flex text-center text-blue-900 dark:text-white text-[15px] font-semibold font-['Inter'] tracking-tight">Cancel</button>
            <button type="submit" [disabled]="linkForm.get('linkUrl')?.invalid"
                class="w-[91px] h-[46px] px-4 py-3.5 bg-blue-900 rounded-lg justify-center items-center gap-2.5 inline-flex text-center text-white text-[15px] font-semibold font-['Inter'] tracking-tight">Confirm</button>
        </div>
    </form>
</app-joii-modal>
<div class="flex flex-row justify-end items-end relative" style="font-size: 15px;">
    <a class="font-semibold joii-text-primary hover:underline cursor-pointer" (click)="addLink()">
        <ng-icon name="ionLink" class="mr-1 text-lg"></ng-icon>Link
    </a>
    <app-file-upload class="px-3" [useCustomTemplate]="true" [progress]=uploadProgress [uploadText]="'Attach'"
        (fileSelected)="onFileSelected($event)"></app-file-upload>
</div>