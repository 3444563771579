import { Component, inject, signal } from '@angular/core';
import { VetAIHeader, VetAIHeaderItemType } from 'src/shared/components/design/vetai-header/vetai-header.interface';
import { DarkModeService } from 'src/shared/services/darkmode/darkmode_service';

@Component({
  selector: 'app-root',
  templateUrl: './pms.component.html'
})
export class PMSComponent {
  darkModeService: DarkModeService = inject(DarkModeService)
  menu: VetAIHeader = {
    hiddenpaths: ['/login', '/register', '/unauthorised', '/forgottenpassword', '/notfound', '/checkaccess', '/consultation/', '/onboarding/staff'],
    logo: 'https://firebasestorage.googleapis.com/v0/b/vetai-e3e47.appspot.com/o/joii%2Fvetaiicon.png?alt=media&token=3e3942bd-4fd8-48c6-9249-096d5b35bef9',
    accountpath: '/account/staffprofile',
    items: [
      {
        id: '/dashboard',
        title: 'Home',
        type: VetAIHeaderItemType.Path,
        requiredroles: [],
        icon: "ionHomeOutline",
        dataautomationid: "pms-sidebar-dashboard",
        hidden: false
      },
      {
        id: '/history',
        title: 'History',
        type: VetAIHeaderItemType.Path,
        requiredroles: [],
        icon: "ionTimeOutline",
        dataautomationid: "pms-sidebar-consultation-history",
        hidden: false
      },
      {
        id: 'token-copier',
        title: 'Copy Auth',
        type: VetAIHeaderItemType.TokenCopier,
        requiredroles: [],
        icon: "ionClipboardOutline",
        dataautomationid: "pms-sidebar-token-copier",
        hidden: false
      },
    ]
  }
}
