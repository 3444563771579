import { Injectable } from '@angular/core';
import { Storage, ref, getDownloadURL, uploadBytesResumable } from '@angular/fire/storage';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { from } from 'rxjs';
import { FileMetadata } from './file-metadata';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  private uploadProgressSource = new BehaviorSubject<number>(0);
  private downloadUrlSource = new Subject<FileMetadata>();
  private uploadErrorSource = new Subject<any>();

  uploadProgress$ = this.uploadProgressSource.asObservable();
  downloadUrl$ = this.downloadUrlSource.asObservable();
  uploadError$ = this.uploadErrorSource.asObservable();

  constructor(private storage: Storage) {}

  public getGcpBucketPath(input: string): string {
    try {
      // Try to construct a URL from the input
      const url = new URL(input);
  
      // Split the pathname into parts
      const parts = url.pathname.split('/');
  
      // Filter out the parts that contain "appspot" or ".com"
      const filteredParts = parts.filter(part => !part.includes('appspot') && !part.includes('.com'));
  
      // If the first part is an empty string (i.e., the original pathname started with a slash),
      // remove it
      if (filteredParts[0] === '') {
        filteredParts.shift();
      }
  
      // If the input is a URL, return the pathname without the bucket name
      // and the leading slash
      return filteredParts.join('/');
    } catch (e) {
      // If the input is not a URL, return the input as is
      return input;
    }
  }

  public getImageUrl(path: string): Observable<string> {
    const storageRef = ref(this.storage, path);
    return from(getDownloadURL(storageRef));
  }

  public uploadImage(bucketFolder:string, image: File): void {
    if (bucketFolder === '' ){
      this.handleUploadError('Bucket folder is required');
      return;
    }
    var fileExtension = image.name.split('.').pop();
    // Concatenate UUID with file extension
    const filePath = `${bucketFolder}/${crypto.randomUUID()}.${fileExtension}`;
    const storageRef = ref(this.storage, filePath);
    const uploadTask = uploadBytesResumable(storageRef, image);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        this.uploadProgressSource.next(progress);
      },
      (error) => {
        this.handleUploadError(error);
      },
      () => {
        const fileMetadata: FileMetadata = {
          url: filePath,
          fileName: image.name
        };
          this.downloadUrlSource.next(fileMetadata);
      }
    );
  }

  private handleUploadError(error: any): void {
    this.uploadErrorSource.next(error);
  }
}
