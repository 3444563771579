<footer *ngIf="!isExcludedPage()" class="bg-white">
  <div class="mx-auto max-w-7xl px-6 py-12 md:flex md:items-center md:justify-between lg:px-8">
    <div class="flex justify-center space-x-6 md:order-2">
      <select  [(ngModel)]="selectedLanguage" (change)="switchLanguage($event)" class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
        <option value="en">🇬🇧 English</option>
        <option value="de">🇩🇪 German</option>
        <!-- Add more options for other languages here -->
      </select>
    </div>
    <div class="mt-8 md:order-1 md:mt-0">
      <p class="text-center text-xs leading-5 text-gray-500">&copy; 2023 Vet-AI, Inc. All rights reserved.</p>
    </div>
  </div>
</footer>