import { NgIf } from '@angular/common';
import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { fromEvent, map, merge, of, Subscription } from 'rxjs';

@Component({
  selector: 'app-joii-offline-check',
  templateUrl: './joii-offline-check.component.html',
  styleUrl: './joii-offline-check.component.scss',
  standalone: true,
  imports: [NgIf]
})
export class JoiiOfflineCheckComponent implements OnInit, OnDestroy {

  networkStatus: boolean = false;
  reconnected: boolean = false;
  offline: boolean = false;
  networkStatus$: Subscription = Subscription.EMPTY;
  goneOffline: EventEmitter<any> = new EventEmitter<any>();
  backOnline: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit() {
    this.checkNetworkStatus();
  }

  ngOnDestroy() {
    this.networkStatus$.unsubscribe();
  }

  checkNetworkStatus() {
    this.networkStatus = navigator.onLine;
    this.networkStatus$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    )
      .pipe(map(() => navigator.onLine))
      .subscribe(status => {
        this.networkStatus = status;
        switch (this.networkStatus) {
          case true:
            if (this.offline) {
              this.showReconnectMessage();
            }
            this.backOnline.emit();
            break;
          case false:
            this.offline = true;
            this.goneOffline.emit();
            break;
        }
      });
  }

  showReconnectMessage() {
    this.offline = false;
    this.reconnected = true;
    setTimeout(() => {
      this.reconnected = false;
    }, 1500);
  }

}
