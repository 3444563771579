@if(afiResponse()){
<div class="flex flex-col bg-gray-50 dark:bg-slate-700 mb-3 p-3 rounded">

    <div> <strong>Consultation History Details</strong> <span class="w-5 h-5 ml-1 inline-block">
            <img src="https://firebasestorage.googleapis.com/v0/b/vetai-e3e47.appspot.com/o/joii%2FAFI_icon.png?alt=media&token=87f113af-06d5-48c2-bc2c-7a98bf925afd"
                class="w-full h-auto" title="AFI" />
        </span> </div>
    <div class="flex flex-row justify-between flex-wrap">
        <div><strong>Building:</strong> {{address()?.building}}</div>
        <div><strong>Street:</strong> {{address()?.street}}</div>
        <div><strong>Town:</strong> {{address()?.town}}</div>
        <div><strong>Postcode:</strong> {{address()?.postcode}}</div>
    </div>
    <div class="bg-yellow-500 text-black opacity-75 rounded-sm p-1 m-1">
        <div class="flex flex-row">
            <div class="w-10">
                <ng-icon name="ionWarning"></ng-icon>
            </div>
            <p>Please download the clinical history documents you review and store them in <a (click)="openDrive()"
                    class="underline cursor-pointer">this folder</a>. Please note you can
                only open these files once.</p>
        </div>
    </div>
    @if(afiResponse()?.hasError){
    @for(err of afiResponse()?.errorMessages; track $index){
    <div class="bg-red-500 text-black opacity-75 rounded-sm p-1 m-1">
        <div class="flex flex-row">
            <div class="w-10">
                <ng-icon name="ionWarning"></ng-icon>
            </div>
            <p>{{err}}</p>
        </div>
    </div>
    }
    }

    @for (doc of afiDocuments(); track $index) {
    <button class="joii-btn-outline-sml-round" (click)="getDocument(doc)" [disabled]="doc.seen">

        @if(!doc.seen){
        <ng-icon name="ionDownload"></ng-icon>Download Doc {{$index}}
        } @else(){
        <ng-icon name="ionCheckmarkCircle"></ng-icon>
        }</button>
    }
</div>
} @else(){
<button class="joii-btn-outline-sml-round mb-2" (click)="getHistory()">Request Clinical History
    <span class="w-5 h-5 ml-1 inline-block">
        <img src="https://firebasestorage.googleapis.com/v0/b/vetai-e3e47.appspot.com/o/joii%2FAFI_icon.png?alt=media&token=87f113af-06d5-48c2-bc2c-7a98bf925afd"
            class="w-full h-auto" title="AFI" />
    </span>
</button>
}