
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, forwardRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, FormGroup, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ImageService } from 'src/app/services/image.service/image.service';
import { ModalPositionsEnum } from 'src/shared/models/interfaces/modal-positions.enum';
import { JoiiModalComponent } from '../design/joii-modal/joii-modal.component';
import { NgClass, NgIf } from '@angular/common';
import { FileUploadComponent } from '../file-upload/file-upload.component';
import { NgIconsModule } from '@ng-icons/core';

@Component({
  selector: 'app-external-resource-manager',
  templateUrl: './external-resource-manager.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ExternalResourceManagerComponent),
    },
  ],
  standalone: true,
  imports: [JoiiModalComponent, FormsModule, ReactiveFormsModule, NgIf, NgClass, FileUploadComponent, NgIconsModule]
})
export class ExternalResourceManagerComponent
  implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() resources!: string[];
  @Input() bucketfolder: string = '';
  @Input() removalIndex!: number;
  @Output() injectLink = new EventEmitter<{
    url: string;
    displayText: string;
  }>();
  @Output() displayResources = new EventEmitter<
    string[]
  >();

  modalPosEnum: ModalPositionsEnum = ModalPositionsEnum.bottomLeft;
  linkForm!: FormGroup;
  isLinkModalOpen: boolean = false;
  uploadedFileName: string = '';
  uploadProgress: number = 0;
  value: any;
  disabled!: boolean;
  onChange: any = (value: any) => { };
  onTouched: any = () => { };

  private linkValidationPattern = /^[A-Za-z][A-Za-z\d.+-]*:\/*(?:\w+(?::\w+)?@)?[^\s/]+(?::\d+)?(?:\/[\w#!:.?+=&%@\-/]*)?$/;
  private progressSub?: Subscription;
  private urlSub?: Subscription;
  private errorSub?: Subscription;

  constructor(
    private imageService: ImageService,
    private cdr: ChangeDetectorRef,
    private toastrService: ToastrService
  ) {
    this.progressSub = this.imageService.uploadProgress$.subscribe(
      (progress) => (this.uploadProgress = progress)
    );

    this.urlSub = this.imageService.downloadUrl$.subscribe((fileMetadata) => {
      this.addResource(fileMetadata.url);
      this.cdr.detectChanges();
    });

    this.errorSub = this.imageService.uploadError$.subscribe((error) => {
      this.toastrService.error(
        'Failed to upload image: ' + error.message,
        'Upload Error'
      );

      this.progressSub?.unsubscribe();
      this.urlSub?.unsubscribe();
    });

    this.linkForm = new FormGroup({
      linkUrl: new FormControl('', [Validators.required, Validators.pattern(this.linkValidationPattern)]),
      displayText: new FormControl('', [Validators.required]),
    });
  }

  writeValue(value: any): void {
    this.resources = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  ngOnDestroy(): void {
    this.urlSub?.unsubscribe();
    this.progressSub?.unsubscribe();
    this.errorSub?.unsubscribe();
  }
  ngOnInit(): void {
    if (!this.resources) {
      this.resources = [];
    }
  }

  addLink() {
    this.isLinkModalOpen = true;
  }

  closeLinkModal() {
    this.isLinkModalOpen = false;
  }

  onFileSelected(data: { event: Event; additionalParam?: any }) {
    if (this.bucketfolder === '') {
      this.toastrService.error('Bucket folder is missing on resource manager component.');
      return;
    }
    const fileList = (data.event.currentTarget as HTMLInputElement).files;
    const file = fileList?.item(0);
    if (file) {
      this.uploadedFileName = file.name;
      this.imageService.uploadImage(this.bucketfolder, file);
    }
  }

  removeResource() {
    this.resources.splice(this.removalIndex, 1);
    this.cdr.detectChanges();
    this.onChange(this.resources);
    this.onTouched();
  }

  submitLink() {
    if (this.linkForm.valid) {
      const linkData = this.linkForm.value;
      this.injectLink.emit({
        url: linkData.linkUrl,
        displayText: linkData.displayText
      })
      this.isLinkModalOpen = false;
      this.linkForm.reset({
        linkUrl: '',
        displayText: ''
      });
    }
  }

  private addResource(resource: string) {
    if (!this.resources) {
      this.resources = [];
    }
    this.resources.push(resource);
    this.onChange(this.resources);
    this.onTouched();
    this.displayResources.emit(this.resources);
  }
}
