<!-- pagination.component.html -->
<div class="wrapper">
  <div class="menu">
    <button (click)="goToPage(1)" [disabled]="currentPage === 1" data-automationid="pagination-first-page-button">
      <ng-icon name="ionCaretBackOutline"></ng-icon>
    </button>
    <button (click)="goToPage(currentPage - 1)" [disabled]="currentPage === 1" data-automationid="pagination-previous-page-button"> 
      <ng-icon name="ionChevronBackOutline"></ng-icon>
    </button>

    <div class="list" data-automationid="pagination-pages-list-container">
      @for(page of pagesArray.slice(this.startIndexPageRange, this.endIndexPageRange); track page)
      {
      <button (click)="goToPage(page)" [class.active]="currentPage === page" data-automationid="pagination-page-button">{{ page }}</button>
      }
    </div>

    <button (click)="goToPage(currentPage + 1)" [disabled]="currentPage === totalPagesCount" data-automationid="pagination-next-page-button">
      <ng-icon name="ionChevronForwardOutline"></ng-icon>
    </button>
    <button (click)="goToPage(totalPagesCount)" [disabled]="currentPage === totalPagesCount" data-automationid="pagination-last-page-button">
      <ng-icon name="ionCaretForwardOutline"></ng-icon>
    </button>
    <div class="sizer">
      <select [(ngModel)]="pageSize" (change)="changePageSize(pageSize)" data-automationid="pagination-page-size-input">
        @for(option of pageSizeOptions; track option)
        {
        <option data-automationid="pagination-page-size-option" [value]="option">{{ option }}</option>
        }
      </select>
    </div>
  </div>
</div>