import { CommonModule, NgFor, NgIf, SlicePipe } from '@angular/common';
import { Component, Input, OnInit, inject } from '@angular/core';
import { DocumentData, Firestore, QueryDocumentSnapshot, Timestamp, collection, getDocs, onSnapshot, query, where } from '@angular/fire/firestore';
import { Subscription, interval, switchMap, Observable, startWith } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NgxFloatUiModule, NgxFloatUiPlacements, NgxFloatUiTriggers } from 'ngx-float-ui';


interface StaffOnlineData {
  id: string;
  name: string;
  description: string;
  last_online: Timestamp;
}

@Component({
  selector: 'app-staffonlineview',
  templateUrl: './staffonlineview.component.html',
  standalone: true,
  imports: [NgxFloatUiModule, NgIf, NgFor, SlicePipe]
})

export class StaffOnlineViewComponent implements OnInit {

  firestore: Firestore = inject(Firestore);

  staffOnline: any[] = [];
  private fetchSubscription!: Subscription;
  collection = 'staffonline';
  intervalToFetchOtherStaffOnline = 5000;
  howRecentlyTheyHaveBeenOnline = 20000; // in the last 20 seconds
  @Input() view = 'list';
  production = environment.production;
  staffToShow: number = 4;
  overflowNames: string = "";

  ngOnInit() {
    this.fetchStaffOnline();
  }

  ngOnDestroy() {
    if (this.fetchSubscription) {
      this.fetchSubscription.unsubscribe();
    }
  }
  tooltipTriggerEnum = NgxFloatUiTriggers;
  tooltipPlacementEnum = NgxFloatUiPlacements;

  fetchStaffOnline() {
    this.fetchSubscription = interval(this.intervalToFetchOtherStaffOnline).pipe(
      startWith(0) // Start immediately
    ).pipe(
      switchMap(async () => {
        const staffQuery = query(
          collection(this.firestore, this.collection),
          where('last_online', '>', Timestamp.fromDate(new Date(Date.now() - this.howRecentlyTheyHaveBeenOnline)))
        );

        try {
          const snapshot = await getDocs(staffQuery);
          if (!snapshot.empty) {
            this.staffOnline = this.transformData(snapshot.docs).sort((a, b) => a.name.localeCompare(b.name))
            this.overflowNames = this.staffOnline.splice(this.staffToShow).map(x => x.name).join("</br>")
          } else {
            this.staffOnline = [];
          }
        } catch (error) {
          console.error('Error fetching staff online:', error);
        }
      })
    ).subscribe();
  }


  transformData(querySnapshot: QueryDocumentSnapshot<DocumentData>[]): StaffOnlineData[] {
    return querySnapshot.map((doc) => {
      return { id: doc.id, ...doc.data() } as StaffOnlineData;
    });
  }

}

