import { importProvidersFrom, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { PMSRoutingModule } from './pms-routing.module';
import { PMSComponent } from './pms.component';
import { SharedModule } from 'src/shared/shared.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { AccountModule } from './account/account.module';
import { AppointmentsModule } from './appointments/appointments.module';
import { ConsultationsModule } from './consultations/consultations.module';
import { OnboardingModule } from './onboarding/onboarding.module';
import { ToastrModule } from 'ngx-toastr';
import { LucideAngularModule } from 'lucide-angular';
import { ICONS, NGICONS } from 'src/icons';
import { HttpClient } from '@angular/common/http';
import { NgIconsModule } from '@ng-icons/core';
import { CustomTranslateLoader, HttpLoaderFactory } from 'src/shared/translate/custom.translate.loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

@NgModule({
  declarations: [
    PMSComponent,
  ],
  imports: [
    BrowserModule,
    DashboardModule,
    AccountModule,
    AppointmentsModule,
    ConsultationsModule,
    SharedModule,
    OnboardingModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
    }),
    // Keep PMSRoutingModule at bottom
    PMSRoutingModule,
    LucideAngularModule,
    LucideAngularModule.pick(ICONS),
    NgIconsModule.withIcons(NGICONS),
  ],
  providers: [
    importProvidersFrom(TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }))
  ],
  bootstrap: [PMSComponent]
})
export class PMSModule { }
