import { EventEmitter, Injectable, computed, signal } from "@angular/core";
import { BehaviorSubject, Observable, Subject, combineLatest, forkJoin, merge, mergeAll, skipWhile, take, tap } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ConsultationFormsDataService {
    suggestedVenomCodes$ = signal<string[]>([]);
    formsRequired$ = signal<FormDetails[]>([]);
    submit: EventEmitter<boolean> = new EventEmitter<boolean>()

    allSubmitted = computed<boolean>(() => this.formsRequired$().every((value) => value.submitted))

    submitAll() {
        let forms = this.formsRequired$();
        let allSaved: Observable<boolean>[] = forms.map(form => {
            form.submit.emit()
            return form.submitComplete
        })
        return combineLatest(allSaved);

    }
}

export interface FormDetails {
    formName: string;
    submitted: boolean;
    submitComplete: BehaviorSubject<boolean>;
    submit: EventEmitter<boolean>
}