import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'venomCodeExtractor',
  standalone: true
})
export class VenomCodeExtractorPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    const processDiagnosisGroups = (reason: { [x: string]: any[] }) => {
      // Extract the names from each non-empty diagnosis group and concatenate them
      return [
        'likely_diagnosis_codes',
        'likely_diagnosis_groups',
        'other_diagnosis_codes',
        'other_diagnosis_groups',
      ]
        .map((key) =>
          reason[key] && reason[key].length
            ? reason[key].map((d) => d.display_name).join(', ')
            : ''
        )
        .filter((names) => names)
        .join(', ');
    };
    if (!value.reasons) {
      return 'No reasons found';
    }
    return value.reasons.map(processDiagnosisGroups).join(', ');
  }

}
