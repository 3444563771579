<div class="flex flex-col content-between w-full">
    @if(appointmentDetails.notes){
    <div class="w-full text-gray-500 text-base font-medium font-['Inter'] leading-normal tracking-tight">
        {{appointmentDetails.notes}}
    </div>
    }
    @if(appointmentDetails.appointment_photos_urls.length > 0)
    {
    <div class="flex w-full flex-wrap flex-row">
        @for (photo of appointmentDetails.appointment_photos_urls; track $index) {
        @if ((photo | fileExtension: 'type') === 'image') {
        <img [src]="photo" class="max-w-1/4 cursor-pointer p-1 border-blue-600 rounded-lg overflow-hidden"
            (click)="showModal()">
        }
        @if ((photo | fileExtension: 'type') === 'video') {
        <video class="max-w-1/4 cursor-pointer border-blue-600 rounded-lg overflow-hidden" (click)="showModal()">
            <source [src]="photo" type="video/{{photo | fileExtension: 'extension'}}" />
        </video>
        }
        }
    </div>
    }
    <ng-container *ngIf="symptomCheckerResponse$| async as symptomCheckerResponse">
        <p *ngIf="symptomCheckerResponse.session_outcome.urgency_level">Urgency:
            {{symptomCheckerResponse.session_outcome.urgency_level}}</p>
        @for (question of symptomCheckerResponse.session_responses; track $index) {
        <div class="flex flex-row space-x-1 flex-wrap">
            <a><strong>{{question.title}}:</strong> </a>
            @if (question.answer_options.length > 0) {
            @for (answerOption of question.answer_options; track $index) {
            <div class="p-1.5 border border-blue rounded-md flex items-center justify-start text-primary text-sm font-medium cursor-default"
                [ngClass]="{'bg-primary text-white': answerOption.was_selected}">{{answerOption.display_value}}</div>
            }
            } @else {
            <div
                class="p-1.5 border border-blue rounded-md flex items-center justify-start text-primary text-sm font-medium cursor-default">
                {{question.response}}</div>
            }
        </div>
        }
        @if (symptomCheckerResponse.session_outcome.venom_code.length > 0) {
        <p><strong>Associated Venom Codes:</strong></p>
        <p>
            @for (venomCode of symptomCheckerResponse.session_outcome.venom_code; track $index) {
            <span
                class="p-1.5 mr-2 mb-2 inline-block w-auto border border-blue rounded-md text-primary text-sm font-medium cursor-default">{{venomCode.term_name}}</span>
            }
        </p>
        }
        @if (symptomCheckerResponse.session_outcome.recommended_products.length > 0) {
        <p><strong>Recommended Products:</strong></p>
        <p>
            @for (productRec of symptomCheckerResponse.session_outcome.recommended_products; track $index) {
            <span
                class="p-1.5 mr-2 mb-2 inline-block w-auto border border-blue rounded-md text-primary text-sm font-medium cursor-default"
                *ngIf="productRec.name">{{productRec.name}}</span>
            <span
                class="p-1.5 mr-2 mb-2 inline-block w-auto border border-blue rounded-md text-primary text-sm font-medium cursor-default"
                *ngIf="!productRec.name">Product Name Missing</span>
            }
        </p>
        }
        <div class="flex w-full flex-wrap flex-row">
            @for (photo of symptomCheckerResponse.media; track $index) {
            <img [src]="photo" class="max-w-1/4 cursor-pointer p-1 border-blue-600 rounded-lg overflow-hidden"
                (click)="showModal()">
            }
        </div>
    </ng-container>

    <ng-container *ngIf="userSubmissionsResponse$| async as userSubmissionsResponse">
        @if(userSubmissionsResponse.pet_forms.length == 1) 
        {
            <p *ngIf="userSubmissionsResponse.pet_forms.length == 1">{{userSubmissionsResponse.pet_forms[0].form_title}}</p>
            @for (question of userSubmissionsResponse.pet_forms[0].form_responses; track $index) {
                <div class="flex flex-row space-x-1 flex-wrap">
                    <a><strong>{{question.title}}:</strong> </a>
                    @if (question.answer_options.length > 0) {
                    @for (answerOption of question.answer_options; track $index) {
                        <div *ngIf="question.answer_type == 'text'" class="p-1.5 border border-blue rounded-md flex items-center justify-start text-primary text-sm font-medium cursor-default"
                            [ngClass]="{'bg-primary text-white': answerOption.was_selected}">{{answerOption.display_value}}</div>
                        <img *ngIf="question.answer_type == 'image'" [src]="answerOption.display_value" class="max-w-1/4 cursor-pointer p-1 border-blue-600 rounded-lg overflow-hidden"
                            (click)="showModal()">
                        <video *ngIf="question.answer_type == 'video'" class="max-w-1/4 cursor-pointer border-blue-600 rounded-lg overflow-hidden" controls>
                            <source  [src]="videourl"/>
                        </video>
                    }
                    } @else {
                    <div *ngIf="question.answer_type == 'text'"
                        class="p-1.5 border border-blue rounded-md flex items-center justify-start text-primary text-sm font-medium cursor-default">
                        {{question.response}}</div>
                    <img *ngIf="question.answer_type == 'image'" [src]="question.response" class="max-w-1/4 cursor-pointer p-1 border-blue-600 rounded-lg overflow-hidden"
                        (click)="showModal()">
                     <video *ngIf="question.answer_type == 'video'" class="max-w-1/4 cursor-pointer border-blue-600 rounded-lg overflow-hidden" controls>
                        <source  [src]="question.response"/>
                    </video>
                    }
                </div>
            }
        }
    </ng-container>
</div>
@if(imageCarouselDataService.images().length == 0 && !appointmentDetails.notes &&
!appointmentDetails.symptom_checker_session_doc_id && !appointmentDetails.typeform_event_id){
<div class="flex-col justify-start items-start inline-flex h-full w-full">
    <div class="self-stretch  p-12 rounded-xl border border-stone-200 flex-col justify-start items-center gap-5 flex">
        <div class="flex-col justify-start items-center gap-1 flex">
            <div class="justify-center items-start gap-[5px] inline-flex">
                <div
                    class="text-neutral-800 dark:text-white text-base font-medium font-['Inter'] leading-normal tracking-tight">
                    No
                    observation added</div>
            </div>
            <div class="justify-start items-start inline-flex">
                <div class="justify-start items-center gap-2.5 flex">
                    <div class="flex-col justify-start items-start gap-0.5 inline-flex">
                        <div class="text-center text-blue-900 text-base font-semibold font-['Inter'] leading-normal tracking-tight cursor-pointer"
                            (click)="reloadAppointment()">Refresh</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
}