<app-title title="Create Your Staff Profile" description="Let's get you set up"></app-title>
@if(error){
  <app-banner title="Account Creation Error" [description]="error" (dismissclick)="clearError()"></app-banner>
}
<div class="max-w-sm">
  <form class="form" [formGroup]="form" (ngSubmit)="onSubmit(form)">
    <div class="input-group">
      <app-input name="name" label="Name" formControlName="name" [span]="6"></app-input>
    </div>
    <div class="input-group">
      <app-input name="description" label="Description" formControlName="description" [span]="6"></app-input>
    </div>
    <app-form-submission-button [loading]="loading" buttonText="Create Account"></app-form-submission-button>
  </form>
</div>