<app-dialog-simple [isOpen]="imageCarouselDataService.showCarousel()" (onClose)="imageCarouselDataService.hideModal()">
  @if(imageCarouselDataService.showCarousel()){
  <div class="modal fixed w-screen h-screen top-0 left-0 flex items-center justify-center z-50 bg-black  bg-opacity-25"
    (click)="close()">

    <div id="carouselExampleCaptions" class="relative w-2/3 h-4/5 bg-white rounded" (click)="$event.stopPropagation()">

      <!--Carousel indicators-->
      <div class="absolute bottom-0 left-0 right-0 z-[2] mx-[15%] mb-4 flex list-none justify-center p-0">
        @for (image of imageCarouselDataService.images(); track $index) {
        <button type="button"
          class="mx-[3px] box-content h-[3px] w-[30px] flex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-white bg-clip-padding p-0 -indent-[999px] transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none"
          [ngClass]="{'opacity-50': $index != selectedItem, 'opacity-100': $index == selectedItem}"
          (click)="selectItem($index)"></button>
        }
      </div>

      <!--Carousel items-->
      <div class="relative w-full h-full overflow-hidden after:clear-both after:block after:content-['']">

        @for (image of imageCarouselDataService.images(); track $index) {

        <div
          class="relative float-left w-full h-full -mr-[100%] pt-4 pb-4 transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none text-center"
          style="backface-visibility: hidden" [ngClass]="{'hidden':$index != selectedItem}">
          @if ((image | fileExtension: 'type') === 'image') {
          <img src="{{image}}" class="block mx-auto w-auto h-full max-h-full" alt="..." />
          }
          @if ((image | fileExtension: 'type') === 'video') {
          <video controls class="w-2/3 mx-auto my-auto">
            <source [src]="image" type="video/{{image | fileExtension: 'extension'}}" />
          </video>
          }
        </div>
        }


      </div>

      <!--Carousel controls - prev item-->
      <button
        class="absolute bottom-0 left-0 top-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-white opacity-70 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
        type="button" (click)="previous()">
        <span class="inline-block h-8 w-8 bg-slate-600 bg-opacity-45">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="h-6 w-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
          </svg>
        </span>
        <span
          class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Previous</span>
      </button>
      <button
        class="mt-4 absolute right-0 top-0 z-[2] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
        type="button" (click)="close()">
        <span class="inline-block h-8 w-8 bg-slate-600 bg-opacity-45">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="h-6 w-6">
            <line x1="5" y1="16" x2="16" y2="5" />
            <line x1="5" y1="5" x2="16" y2="16" />
          </svg>
        </span>
      </button>
      <button
        class="absolute bottom-0 right-0 top-2 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
        type="button" (click)="next()">
        <span class="inline-block h-8 w-8 bg-slate-600 bg-opacity-45">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="h-6 w-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
          </svg>
          <!-- <?xml version="1.0" ?><!DOCTYPE svg  PUBLIC '-//W3C//DTD SVG 1.1//EN'  'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'><svg height="512px" id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" width="512px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"></svg> -->
        </span>
        <span
          class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Next</span>
      </button>
    </div>
  </div>
  }
</app-dialog-simple>