<ul role="list" class="divide-y divide-gray-100" data-automationid="stacked-list-container">
  <li (click)="rowclicked(item, $event); $event.stopPropagation();" *ngFor="let item of config.items" data-automationid="stacked-list-row"
    class="relative flex justify-between gap-x-6 py-5 cursor-pointer hover:bg-gray-50">
    <div class="flex min-w-0 gap-x-4">
      <img data-automationid="stacked-list-row-image" *ngIf="item.image"
        class="h-12 w-12 flex-none rounded-full bg-gray-50" [src]="item.image" alt="">
      <div class="min-w-0 flex-auto">
        <p class="text-sm font-semibold leading-6 text-gray-900" data-automationid="stacked-list-row-title">
          <span class="absolute inset-x-0 -top-px bottom-0"></span>
          {{item.title}}
        </p>
        <p class="mt-1 flex text-xs leading-5 text-gray-500" data-automationid="stacked-list-row-description">
          <a class="relative truncate" data-automationid="stacked-list-row-description-link">{{item.description}}</a>
        </p>
        <div class="flex space-x-2 mt-3" *ngIf="item.badges">
          <span *ngFor="let badge of item.badges"
            class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium border-dotted border-1 "
            [ngStyle]="{'border-color': badge.color}"
            [ngClass]="'bg-' + badge.color + '-50 text-' + badge.color + '-700'">{{badge.name}}</span>
        </div>
      </div>
    </div>
    <div class="flex shrink-0 items-center gap-x-4 z-1">
      @for(action of item.actions; track action) {
        <lucide-angular (click)="action.handler(item, $event)" [name]="action.icon" [ngClass]="action?.classes?.length || 0 > 0 ? action.classes : actionClasses"></lucide-angular>
      }
      <svg class="h-5 w-5 flex-none text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"
        data-automationid="stacked-list-row-arrow">
        <path fill-rule="evenodd"
          d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
          clip-rule="evenodd" />
      </svg>
    </div>
  </li>
</ul>