import { trigger, transition, query, stagger, animateChild, animate, style } from "@angular/animations";


export const petSelectionListAnimation = trigger('list', [
    transition('* => *', [
        query(':enter',
            stagger(150, animateChild()),
            { optional: true }
        )
    ])
]);

export const animatePetItem = trigger('animate', [
    transition(':enter', [
      style({ opacity: 0, transform: 'translateY(10px)', scale: 0 }),
      animate('250ms ease-in-out', style({ opacity: 1, transform: 'translateY(0px)', scale: 1}))
    ]),
    transition(':leave', [
      style({ opacity: 1, transform: 'translateY(0px)', scale: 1 }),
      animate('250ms', style({ opacity: 0, transform: 'translateY(10px)', scale: 0}))
    ])
  ])