import { Component, Input } from '@angular/core';
import { NgIf, NgFor } from '@angular/common';
import { FormioSubmissionComponent } from '../formio_submission/formio_submission.component';

@Component({
  selector: 'app-assessment-details',
  standalone: true,
  imports: [NgIf, NgFor, FormioSubmissionComponent],
  templateUrl: './assessment-details.component.html',
  styleUrl: './assessment-details.component.scss'
})
export class AssessmentDetailsComponent {
  @Input() consultation: any;
}
