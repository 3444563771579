import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minutesToHours',
  standalone: true
})
export class MinutesToHoursPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    let finalOutput;
    const dayCheck = Math.floor(value / (24 * 60));
    if (dayCheck > 1) {
      const days = Math.floor(value / (24 * 60));
      const remainingMinutesAfterDays = value % (24 * 60);
      const hours = Math.floor(remainingMinutesAfterDays / 60);
      const remainingMinutes = remainingMinutesAfterDays % 60;
      finalOutput = `${days}days ${hours}h:${remainingMinutes}min`;
    } else {
      const hours = Math.floor(value / 60);
      const remainingMinutes = value - hours * 60;
      if (hours > 0) {
        finalOutput = `${hours}h:${remainingMinutes}min`;
      } else {
        finalOutput = `${value}min`;
      }
    }
    return finalOutput;
  }

}
