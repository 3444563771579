import { Component, Input, Output, EventEmitter, OnInit, inject } from '@angular/core';
import { bookinginternal } from '../../services/client/client';
import { NgIf, NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LucideAngularModule } from 'lucide-angular';
import { SelectComponent } from '../forms/select/select.component';
import { StaffNamePipe } from 'src/shared/pipes/staff-name/staff-name.pipe';
import { MinutesToHoursPipe } from 'src/shared/pipes/minutes-to-hours/minutes-to-hours.pipe';
import { doc, Firestore, getDoc } from '@angular/fire/firestore';
import { from, Observable } from 'rxjs';
import { PrescribingEligibilityPillComponent } from "../prescribing-eligibility-pill/prescribing-eligibility-pill.component";

@Component({
  selector: 'app-appointment-card',
  templateUrl: './appointment-card.component.html',
  styleUrl: './appointment-card.component.scss',
  standalone: true,
  imports: [NgIf, NgFor, FormsModule, LucideAngularModule, SelectComponent, StaffNamePipe, MinutesToHoursPipe, PrescribingEligibilityPillComponent]
})
export class AppointmentCardComponent implements OnInit {
  @Input() appointmentDetails: bookinginternal.GetAppointmentResponse | null = null;
  @Input() expanded: boolean = false;
  @Input() staffProfile: any;
  @Input() availableStaff: Array<any> = [];
  @Input() loadingCreateConsult: boolean = false;
  isHealthCheck: boolean = false;
  @Output() startConsultation: EventEmitter<{ appointment_id: string, owner_id: string, pet_id: string }> =
    new EventEmitter<{ appointment_id: string, owner_id: string, pet_id: string }>();
  @Output() continueConsultation: EventEmitter<string> = new EventEmitter<string>();
  @Output() assignStaff: EventEmitter<any> = new EventEmitter<any>();
  @Output() moreDetailsClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() onToggleExpanded: EventEmitter<any> = new EventEmitter<any>()
  selectedStaff: any;
  private firestore: Firestore = inject(Firestore);
  collection = 'health-checks';

  constructor() { }

  ngOnInit(): void {
    var found = false
    this.availableStaff.forEach((staff, _) => {
      if (staff.id == this.appointmentDetails?.staff_profile_id) {
        found = true
      }
    })
    if (!found && this.appointmentDetails?.staff_profile_id) {
      this.availableStaff.push({
        name: this.appointmentDetails?.staff_profile_name,
        id: this.appointmentDetails?.staff_profile_id
      })
    }
    this.selectedStaff = this.appointmentDetails?.staff_profile_id;
    if (this.appointmentDetails?.doc_id) {
      this.getAppointmentType(this.appointmentDetails.doc_id);
    }
  }

  toggleExpanded() {
    this.onToggleExpanded.emit()
  }

  handleStartConsultation(petID: string, ownerID: string, appointmentID: string) {
    this.startConsultation.emit({ pet_id: petID, owner_id: ownerID, appointment_id: appointmentID });
  }

  handleContinueConsultation(appointmentID: string) {
    this.continueConsultation.emit(appointmentID);
  }

  handleStaffSelect(event: any) {
    this.assignStaff.emit(event.target.value);
  }
  moreDetails() {
    this.moreDetailsClicked.emit(true);
  }

  getAppointmentType(appointmentId: string): void {
    const docRef = doc(this.firestore, `${this.collection}/${appointmentId}`);
    getDoc(docRef).then((docSnap) => {
      this.isHealthCheck = !!docSnap.exists();
    }).catch((error) => {
      console.error('Error fetching document:', error);
    });
  }
}
