<app-title title="Appointment" description="" backBtn="true" (backclick)="back()"></app-title>
<ng-container *ngIf="appointment$ | async as appointment; else loading">
    <app-error *ngIf="appointment | isClientErrorMessage; else loaded" [error]="appointment"
        (retry)="load()"></app-error>
    <ng-template #loaded>
        {{appointment.consult_status}}
        @if (appointment.consult_status) {
        <button class="joii-btn-primary mt-4 disabled:opacity-50" (click)="continue(appointment.doc_id)">Continue
            Consultation</button>
        }@else{
        <button class="joii-btn-primary mt-4 disabled:opacity-50"
            (click)="start(appointment.pet_id, appointment.user_id)">Start
            Consultation</button>
        }
        <app-detailslist [config]="getConfig(appointment)"></app-detailslist>
    </ng-template>
</ng-container>
<ng-template #loading>
    <app-loader></app-loader>
</ng-template>
