import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/shared/shared.module';
import { AppointmentsComponent } from './pages/appointments/appointments.component';
import { AppointmentDetailsComponent } from './pages/appointmentdetails/appointmentdetails.component';
import { AppointmentsRoutingModule } from './appointments-routing.module';


@NgModule({
  declarations: [
    AppointmentsComponent,
    AppointmentDetailsComponent
  ],
  imports: [
    CommonModule,
    AppointmentsRoutingModule,
    SharedModule
  ]
})
export class AppointmentsModule { }
